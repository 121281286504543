import { MutableRefObject, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

import Breadcrumbs from '../../components/Breadcrumbs';
import PostifyHtmlContent from '../../components/PostifyHtmlContent';

import useScrollToTop from '../../hooks/useScrollToTop';

import { getPagina } from '../../services/api';

import '../../styles/page.css';

type ParamsProps = {
  id: string;
};

type Pagina = {
  author: string;
  title: string;
  slug: string;
  text: string;
  created_at: string;
  updated_at: string;
  imagem_capa: {
    src?: string;
  };
  cards: [
    {
      id: string;
      title: string;
      link: string;
    },
  ];
  documentos: [
    {
      id: string;
      title: string;
      short_description: string;
      link: string;
    },
  ];
};

export default function Pagina(): JSX.Element {
  const { id } = useParams<ParamsProps>();
  const [pagina, setPagina] = useState<Pagina>();
  const [loading, setLoading] = useState(false);

  useScrollToTop();

  useEffect(() => {
    const fetchPagina = async () => {
      setLoading(true);
      if (typeof id === 'string' && id.trim() !== '') {
        const response = await getPagina<Pagina>(id);
        setPagina(response.data);
      }
      setLoading(false);
    };

    fetchPagina();
  }, [id]);

  return (
    <Box>
      <Breadcrumbs>
        <Typography color="secondary" fontWeight="bold">
          {pagina?.title}
        </Typography>
      </Breadcrumbs>

      {loading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      ) : (
        <Box px={{ xs: 1, md: 3 }}>
          <Box component="header" mb={3}>
            <Typography component="h2" fontWeight="bold" fontSize="1.5rem">
              {pagina?.title}
            </Typography>

            <p>
              Publicado em <span>{pagina?.created_at}</span>
            </p>
          </Box>

          {pagina?.imagem_capa && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                float: {
                  md: 'right',
                },
                padding: {
                  md: '0 0 1rem 1rem',
                },
              }}
            >
              <Gallery>
                <Item
                  content={
                    <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& img': {
                          maxWidth: { xs: '100%', md: 800, lg: 1200 },
                        },
                      }}
                    >
                      <Box
                        component="img"
                        src={pagina?.imagem_capa.src}
                        alt={pagina?.title}
                        sx={{
                          objectFit: 'cover',
                          aspectRatio: '15 / 10',
                        }}
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          p: 1,
                        }}
                      >
                        {/* <Typograps */}
                      </Box>
                    </Box>
                  }
                >
                  {({ open, ref }) => (
                    <Card
                      ref={ref as MutableRefObject<HTMLDivElement>}
                      onClick={open}
                      sx={{
                        maxWidth: { xs: 600, md: 350 },
                      }}
                    >
                      <CardMedia
                        component="img"
                        src={pagina?.imagem_capa.src}
                        alt={pagina?.title}
                        sx={{
                          objectFit: 'cover',
                          width: '100%',
                          aspectRatio: '15 / 10',
                          cursor: 'pointer',
                        }}
                      />

                      {/* <CardContent>
                      <Typography variant="body2" color="text.secondary" mb={1}>
                        {noticia?.imagem_capa?.short_description}.
                      </Typography>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontStyle="italic"
                      >
                        {noticia?.imagem_capa?.author}
                      </Typography>
                    </CardContent> */}
                    </Card>
                  )}
                </Item>
              </Gallery>
            </Box>
          )}

          <PostifyHtmlContent content={pagina?.text || ''} />

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              gridAutoRows: '1fr',
              gap: 1.5,
            }}
          >
            {pagina?.cards.map(card => (
              <Box key={card.id}>
                <Button
                  variant="contained"
                  component="a"
                  href={card.link}
                  key={card.id}
                  sx={{
                    textTransform: 'none',
                    width: '100%',
                    height: '100%',
                    py: 1,
                    color: 'white',
                    ':hover': {
                      bgcolor: theme => theme.palette.primary.dark,
                      color: 'white',
                    },
                  }}
                >
                  <Typography fontWeight="bold" textAlign="center">
                    {card.title}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Box>

          <Box>
            <List>
              {pagina?.documentos.map(documento => (
                <ListItem key={documento.id} sx={{ pl: 0, display: 'flex' }}>
                  <ListItemText secondary={documento.short_description}>
                    <Link
                      href={documento.link}
                      underline="hover"
                      sx={{
                        color: 'secondary.main',
                        textDecoration: 'none',
                        ':hover': {
                          color: 'secondary.dark',
                        },
                      }}
                    >
                      {documento.title}
                    </Link>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}
    </Box>
  );
}
