import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { updateFolderName } from '../../../services/portal';

type FormNameUpdateDialogProps = {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  folderId: number | string | null;
};

type Inputs = {
  name: string;
};

export default function FormNameUpdateDialog({
  open = false,
  handleClose,
  onSuccess,
  folderId,
}: FormNameUpdateDialogProps) {
  const { handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async ({ name }) => {
    if (folderId != null && name.trim() !== '') {
      const { status, data } = await updateFolderName(folderId, name);

      if (status === 200 && data.toLowerCase() === 'ok') {
        onSuccess();
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Renomear diretório</DialogTitle>

      <DialogContent>
        <DialogContentText>Digite o novo nome do diretório</DialogContentText>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="new-name"
                label="Novo nome"
                type="text"
                fullWidth
                variant="standard"
                onChange={onChange}
                value={value}
              />
            )}
          />

          <Button type="submit">Atualizar</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
