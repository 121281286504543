import { Box, BoxProps, Stack } from '@mui/material';

import Agenda from './Agenda';
import PostIt from './PostIt';
import Servicos from './Servicos';
import Sistemas from './Sistemas';

export default function Aside(props: BoxProps): JSX.Element {
  return (
    <Box component="aside" {...props} boxShadow={1} p={2} maxWidth="20rem">
      <Stack direction="column" spacing={3}>
        <PostIt />

        <Sistemas />

        <Servicos />

        <Agenda />
      </Stack>
    </Box>
  );
}
