export const justificativasMap: Record<string, string> = {
  alteracao_dados: 'Alteração de dados',
  mau_estado_conservacao: 'Mau estado de conservação',
  desgaste_natural: 'Desgaste natural',
  perda_extravio: 'Perda ou extravio',
  extravio: 'Extravio',
  roubo_furto: 'Roubo ou furto',
};

export const solicitacoesMap: Record<string, string> = {
  primeira_via: 'Primeira Via',
  segunda_via: 'Segunda Via',
  cordao: 'Cordão',
};

export const statusMap: Record<string, string> = {
  analisar: 'Analisar',
  analisado: 'Analisado',
  impresso: 'Impresso',
  recebido: 'Recebido',
  recusado: 'Recusado',
  cancelado: 'Cancelado',
};
