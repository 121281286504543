import {
  faCancel,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import PagesHeader from '../../../../components/HeaderPages';
import LoadingState from '../../../../components/LoadingState';
import { Servidor } from '../../../../context/AuthProvider';
import { useConfirmDialog } from '../../../../context/ConfirmDialogProvider';

import {
  deleteServidor,
  getServidor,
  getUsuarios,
  updateServidor,
} from '../../../../services/portal';

type FormInputs = Servidor & {
  aniversariante_str: string;
};

export default function ServidoresEdit(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: servidor,
    isLoading,
    isError,
  } = useQuery(['servidores', id], async () => {
    if (id != null) {
      const { data } = await getServidor(id);
      return data;
    }

    return null;
  });

  const { data: usuarios = [] } = useQuery(['usuarios', 1000], async () => {
    const { data } = await getUsuarios({ limit: 5000 });
    return data.results;
  });

  const { showConfirmDialog } = useConfirmDialog();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleDeleteServidor = () => {
    showConfirmDialog({
      title: 'Excluir servidor',
      message: 'Tem certeza que deseja excluir o servidor?',
      async onConfirm() {
        if (servidor != null) {
          await deleteServidor(servidor.id);
          queryClient.invalidateQueries(['servidores', servidor.id]);
          navigate('/admin/servidores');
        }
      },
    });
  };

  const { register, control, handleSubmit, reset } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const payload = {
      ...data,
      aniversariante: Boolean(parseInt(data.aniversariante_str, 10)),
    };

    if (id != null) {
      try {
        await updateServidor(id, payload);
        enqueueSnackbar('Servidor atualizado com sucesso', {
          variant: 'success',
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          enqueueSnackbar(`Erro ao atualizar servidor: ${err.message}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Erro ao atualizar servidor', {
            variant: 'error',
          });
        }
      }
    }
  };

  return (
    <LoadingState
      isLoading={isLoading}
      isError={isError}
      title={
        <Box>
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Administração
            </Link>

            <Link
              component={RouterLink}
              to="/admin/servidores"
              color="inherit"
              underline="hover"
            >
              Servidores
            </Link>

            <Typography color="secondary" fontWeight="bold">
              Editar Servidor
            </Typography>
          </Breadcrumbs>

          <PagesHeader title="Editar Servidor" />
        </Box>
      }
    >
      <Paper sx={{ p: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography color="primary" fontWeight="bold" fontSize={25} mb={3}>
            Servidor
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 3,
              mb: 3,
            }}
          >
            <FormControl>
              <TextField
                id="text-field-nome"
                label="Nome"
                variant="outlined"
                defaultValue={servidor?.nome}
                {...register('nome')}
              />
            </FormControl>

            <FormControl>
              <TextField
                id="text-field-nome-social"
                label="Nome social"
                variant="outlined"
                defaultValue={servidor?.nome_social}
                {...register('nome_social')}
              />
            </FormControl>

            <FormControl>
              <TextField
                id="text-field-email"
                label="E-mail"
                variant="outlined"
                defaultValue={servidor?.email}
                {...register('email')}
              />
            </FormControl>

            <FormControl>
              <TextField
                id="text-field-matricula"
                label="Matrícula"
                variant="outlined"
                defaultValue={servidor?.matricula}
                {...register('matricula')}
              />
            </FormControl>

            <FormControl>
              <TextField
                id="text-field-cpf"
                label="CPF"
                variant="outlined"
                defaultValue={servidor?.cpf}
                {...register('cpf')}
              />
            </FormControl>

            <FormControl>
              <InputLabel id="select-vinculo-servidor-label">
                Vínculo
              </InputLabel>

              <Select
                labelId="select-vinculo-servidor-label"
                id="select-vinculo-servidor"
                label="Tipo"
                defaultValue={servidor?.vinculo}
                {...register('vinculo')}
              >
                <MenuItem value="efetivo">Efetivo</MenuItem>
                <MenuItem value="comissionado">Comissionado</MenuItem>
                <MenuItem value="estagiario">Estagiário</MenuItem>
                <MenuItem value="requisitado">Requisitado</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-tipo-servidor-label">Tipo</InputLabel>

              <Select
                labelId="select-tipo-servidor-label"
                id="select-tipo-servidor"
                label="Tipo"
                defaultValue={servidor?.tipo}
                {...register('tipo')}
              >
                <MenuItem value="servidor">Servidor</MenuItem>
                <MenuItem value="defensor">Defensor</MenuItem>
                <MenuItem value="estagiario">Estagiário</MenuItem>
                <MenuItem value="voluntario">Voluntário</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <TextField
                id="text-field-lotacao-atual"
                label="Lotação atual"
                variant="outlined"
                defaultValue={servidor?.lotacao_atual}
                {...register('lotacao_atual')}
              />
            </FormControl>

            <FormControl>
              <Select
                labelId="select-usuario-label"
                id="select-usuario"
                label="Usuário"
                defaultValue={servidor?.usuario_id || ''}
                {...register('usuario_id')}
              >
                <MenuItem value="">Nenhum</MenuItem>

                {usuarios.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Controller
            control={control}
            name="aniversariante_str"
            defaultValue={servidor?.aniversariante ? '1' : '0'}
            render={({ field }) => (
              <FormControl>
                <FormLabel id="radio-aniversariante-label">
                  Aniversariante
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="radio-aniversariante-label"
                  name="radio-aniversariante"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Sim" />
                  <FormControlLabel value="0" control={<Radio />} label="Não" />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              endIcon={<FontAwesomeIcon icon={faCancel} />}
              sx={{
                textTransform: 'none',
                color: 'white',
              }}
              onClick={() => reset()}
            >
              Cancelar
            </Button>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faTrash} />}
                color="error"
                onClick={handleDeleteServidor}
              >
                Excluir
              </Button>

              <Button
                type="submit"
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                sx={{
                  textTransform: 'none',
                  color: 'white',
                }}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </LoadingState>
  );
}
