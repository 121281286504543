import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton } from '@mui/material';
import FileSaver from 'file-saver';
import { useState } from 'react';

import { downloadFile, getDocumento } from '../../services/portal';

import theme from '../../theme';
import { Document } from '../../types';

type FileDownloadButtonProps = {
  fileId: number | string;
  title?: string;
  icon?: IconProp;
};

export default function FileDownloadButton({
  fileId,
  title = 'Baixar arquivo',
  icon = 'file-word',
}: FileDownloadButtonProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDownload = async () => {
    const { data } = await getDocumento<Document>(fileId);

    if (data) {
      try {
        setLoading(true);
        setError(null);

        const blob = await downloadFile(data.url);
        FileSaver.saveAs(new Blob([blob]), data.filename);
      } catch {
        setError('Erro ao fazer download');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <IconButton title={title} onClick={handleDownload}>
      {loading ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        <FontAwesomeIcon
          icon={icon}
          color={error != null ? theme.palette.error.main : undefined}
        />
      )}
    </IconButton>
  );
}
