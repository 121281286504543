import { Box, Button, Slider, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import CrachaPreview from '../Preview';

export default function CrachaTest() {
  const [nome, setNome] = useState('ANA MARIA ALVES SILVA');

  const [imageUrl, setImageUrl] = useState(
    'https://randomuser.me/api/portraits/women/45.jpg',
  );
  const [cargo, setCargo] = useState(
    'CHEFE DA ASSESSORIA JURÍDICA DO DEFENSOR PÚBLICO GERAL',
  );
  const [matricula, setMatricula] = useState('91900673');

  const [crachaStyles, setCrachaStyles] = useState({
    fontSizeNome: 15,
    fontSizeCargo: 9,
    lineHeightNomeCompletoMatricula: 1.2,
    fontSizeNomeCompletoMatricula: 10,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 3,
        p: 5,
        '@media print': { display: 'initial', p: 0, gap: 0 },
      }}
    >
      <Box
        sx={{
          maxWidth: 220,
          '@media print': { maxWidth: 'none', mx: 0 },
        }}
      >
        <CrachaPreview
          cargo={cargo}
          matricula={matricula}
          nome={nome}
          imgSrc={imageUrl}
          styles={{
            photoWidth: 80,
            nomeFontSize: crachaStyles.fontSizeNome,
            nomeCompletoMatriculaFontSize:
              crachaStyles.fontSizeNomeCompletoMatricula,
            nomeCompletoMatriculaLineHeight:
              crachaStyles.lineHeightNomeCompletoMatricula,
            cargoFontSize: crachaStyles.fontSizeCargo,
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          displayPrint: 'none',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography variant="h5" textAlign="center">
          Modificações
        </Typography>

        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="URL da imagem"
              value={imageUrl}
              onChange={({ target: { value } }) => setImageUrl(value)}
            />

            <TextField
              label="Nome"
              value={nome}
              onChange={({ target: { value } }) => setNome(value)}
            />

            <TextField
              label="Cargo"
              value={cargo}
              onChange={({ target: { value } }) => setCargo(value)}
            />

            <TextField
              label="Matrícula"
              value={matricula}
              onChange={({ target: { value } }) => setMatricula(value)}
            />
          </Box>

          <Box>
            <Box>
              <Typography fontWeight="bold">
                Tamanho da fonte do nome:
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ minWidth: 40 }} component="span">
                  {crachaStyles.fontSizeNome}
                </Box>

                <Slider
                  aria-label="Font do nome"
                  valueLabelDisplay="auto"
                  step={0.5}
                  marks
                  min={10}
                  max={30}
                  value={crachaStyles.fontSizeNome}
                  onChange={(event, value) =>
                    setCrachaStyles(prev => ({
                      ...prev,
                      fontSizeNome: value as number,
                    }))
                  }
                />
              </Box>
            </Box>

            <Box>
              <Typography fontWeight="bold">
                Tamanho da fonte do cargo:
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ minWidth: 40 }} component="span">
                  {crachaStyles.fontSizeCargo}
                </Box>

                <Slider
                  aria-label="Font do nome"
                  valueLabelDisplay="auto"
                  step={0.5}
                  marks
                  min={5}
                  max={30}
                  value={crachaStyles.fontSizeCargo}
                  onChange={(event, value) =>
                    setCrachaStyles(prev => ({
                      ...prev,
                      fontSizeCargo: value as number,
                    }))
                  }
                />
              </Box>
            </Box>

            <Box>
              <Typography fontWeight="bold">
                Tamanho da fonte do nome completo e matrícula:
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ minWidth: 40 }} component="span">
                  {crachaStyles.fontSizeNomeCompletoMatricula}
                </Box>

                <Slider
                  aria-label="Font do nome"
                  valueLabelDisplay="auto"
                  step={0.5}
                  marks
                  min={5}
                  max={30}
                  value={crachaStyles.fontSizeNomeCompletoMatricula}
                  onChange={(event, value) =>
                    setCrachaStyles(prev => ({
                      ...prev,
                      fontSizeNomeCompletoMatricula: value as number,
                    }))
                  }
                />
              </Box>
            </Box>

            <Box>
              <Typography fontWeight="bold">
                Altura da linha do nome completo e matrícula:
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ minWidth: 40 }} component="span">
                  {crachaStyles.lineHeightNomeCompletoMatricula}
                </Box>

                <Slider
                  aria-label="Font do nome"
                  valueLabelDisplay="auto"
                  step={0.05}
                  marks
                  min={1}
                  max={2}
                  value={crachaStyles.lineHeightNomeCompletoMatricula}
                  onChange={(event, value) =>
                    setCrachaStyles(prev => ({
                      ...prev,
                      lineHeightNomeCompletoMatricula: value as number,
                    }))
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button onClick={() => window.print()}>Imprimir</Button>
        </Box>
      </Box>
    </Box>
  );
}
