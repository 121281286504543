import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';

import DateTimeFormatter from '../../../components/DateTimeFormatter';
import LoadingState from '../../../components/LoadingState';
import { getCracha, updateCracha } from '../../../services/portal';
import CrachaPreview from '../components/Preview';

import { justificativasMap, statusMap } from '../Crachas.constants';

export default function CrachasAnalise() {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const {
    data: cracha,
    isFetching,
    isError,
  } = useQuery(
    ['cracha', id],
    async () => {
      if (id != null) {
        return getCracha(id);
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  const [isCrachaUpdatedSuccess, setIsCrachaUpdatedSuccess] = useState(false);

  const handleMarcarComoImpresso = async () => {
    if (id != null) {
      await updateCracha(id, { status: 'impresso' });
      queryClient.invalidateQueries(['cracha', id]);
      setIsCrachaUpdatedSuccess(true);
    }
  };

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/admin"
          color="inherit"
          underline="hover"
        >
          Administração
        </Link>

        <Link
          component={RouterLink}
          to="/admin/crachas/analisar"
          color="inherit"
          underline="hover"
        >
          Crachás
        </Link>
      </Breadcrumbs>

      <LoadingState isLoading={isFetching} isError={isError}>
        <Box>
          <Typography variant="h5"> Detalhes da solicitação</Typography>

          {isCrachaUpdatedSuccess && (
            <Alert severity="success">Crachá foi atualizado com sucesso</Alert>
          )}

          <Table sx={{ mb: 3 }}>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Nome</TableCell>
                <TableCell>{cracha?.nome}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">Status</TableCell>
                <TableCell>{statusMap[cracha?.status || '']}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">Data da solicitação</TableCell>
                <TableCell>
                  <DateTimeFormatter datetime={cracha?.created_at || ''}>
                    {({ dateFormatted, timeFormatted }) => (
                      <Typography>
                        {dateFormatted} {timeFormatted}
                      </Typography>
                    )}
                  </DateTimeFormatter>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">Última atualização</TableCell>
                <TableCell>
                  <DateTimeFormatter datetime={cracha?.updated_at || ''}>
                    {({ dateFormatted, timeFormatted }) => (
                      <Typography>
                        {dateFormatted} {timeFormatted}
                      </Typography>
                    )}
                  </DateTimeFormatter>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">Cordão?</TableCell>
                <TableCell>{cracha?.cordao ? 'Sim' : 'Não'}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head">Solicitação</TableCell>
                <TableCell>
                  {cracha?.solicitacao === 'primeira_via'
                    ? 'Primeira Via'
                    : 'Segunda Via'}
                </TableCell>
              </TableRow>

              {cracha?.solicitacao === 'segunda_via' && (
                <TableRow>
                  <TableCell variant="head">Justificativa</TableCell>
                  <TableCell>
                    {justificativasMap[cracha.observacao_requerente || '']}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Box sx={{ mb: 3 }}>
            <Typography fontWeight="bold">Documentos anexados:</Typography>

            {cracha?.documento_file == null ? (
              <Typography>Nenhum documento anexado</Typography>
            ) : (
              <Link
                key={cracha.documento_file.filename}
                href={cracha.documento_file.url}
                target="_blank"
                sx={{
                  textDecoration: 'none',
                  ':hover': { color: 'primary.dark' },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 1, color: red[500] }}>
                    <FontAwesomeIcon icon={faFilePdf} />
                  </Box>

                  <Typography>{cracha.documento_file.filename}</Typography>
                </Box>
              </Link>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
            <Button
              component={RouterLink}
              to={`/admin/crachas/analisar/${cracha?.id}/edit`}
              variant="contained"
            >
              Editar
            </Button>

            <Button
              variant="contained"
              component={RouterLink}
              to={`/admin/crachas/${cracha?.id}/imprimir`}
            >
              Imprimir
            </Button>

            <Button
              variant="contained"
              onClick={handleMarcarComoImpresso}
              disabled={cracha?.status === 'impresso'}
            >
              Marcar como impresso
            </Button>

            <Button component={RouterLink} to="/admin/crachas/analisar">
              Voltar
            </Button>
          </Box>

          <Box
            sx={{
              mx: 'auto',
              maxWidth: 350,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography mb={1} fontWeight="bold" textAlign="center">
              Pré-visualização do crachá
            </Typography>

            <CrachaPreview
              cargo={cracha?.cargo || ''}
              matricula={cracha?.matricula || ''}
              nome={cracha?.nome || ''}
              imgSrc={cracha?.url || ''}
              styles={{
                photoWidth: 80,
                nomeFontSize: '0.75rem',
                nomeCompletoMatriculaFontSize: '0.6rem',
                nomeCompletoMatriculaLineHeight: 1.2,
                cargoFontSize: '0.6rem',
              }}
            />
          </Box>
        </Box>
      </LoadingState>
    </>
  );
}
