import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { getServidores } from '../../../services/portal';

type FormInputs = {
  semCrachas: string;
  filtro: string;
};

export default function CrachasRelatorios() {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [query, setQuery] = useState<FormInputs>({
    semCrachas: '1',
    filtro: '',
  });

  const { register, handleSubmit, control } = useForm<FormInputs>({
    defaultValues: query,
  });

  const onSubmit: SubmitHandler<FormInputs> = data => {
    setPage(1);
    setQuery(data);
  };

  const { data: servidores = [] } = useQuery(
    ['servidores', page, query.semCrachas, query.filtro],
    async () => {
      const { data } = await getServidores({
        page,
        'q[crachas_matricula_blank]': query.semCrachas,
        'q[nome_or_nome_social_or_matricula_or_cpf_cont]': query.filtro,
        'q[tipo_not_in]': ['defensor', 'voluntario', 'estagiario'],
      });

      setTotalPages(data.pagy.last);

      return data.results;
    },
  );

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/admin"
          color="inherit"
          underline="hover"
        >
          Administração
        </Link>

        <Link
          component={RouterLink}
          to="/admin/crachas/analisar"
          color="inherit"
          underline="hover"
        >
          Crachás
        </Link>

        <Typography color="secondary" fontWeight="bold">
          Outros filtros
        </Typography>
      </Breadcrumbs>

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <TextField
              placeholder="Nome, matrícula ou CPF"
              variant="standard"
              sx={{ minWidth: 300 }}
              {...register('filtro')}
            />

            <FormControl>
              <FormLabel id="radio-solicitacao-label">
                Com solicitação de crachás?
              </FormLabel>

              <Controller
                control={control}
                name="semCrachas"
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="radio-solicitacao-label"
                    row
                    defaultValue="1"
                    onChange={field.onChange}
                    value={field.value}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="0"
                      label="Sim"
                    />

                    <FormControlLabel
                      control={<Radio />}
                      value="1"
                      label="Não"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faSearch} />}
              sx={{ ml: 'auto' }}
            >
              Pesquisar
            </Button>
          </Box>
        </form>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Matrícula</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {servidores.map(servidor => (
                <TableRow key={servidor.id}>
                  <TableCell>
                    <Typography>{servidor.nome}</Typography>
                    <Chip label={servidor.tipo} />
                  </TableCell>

                  <TableCell>{servidor.matricula}</TableCell>
                  <TableCell>{servidor.cpf}</TableCell>

                  <TableCell>
                    <IconButton
                      component={RouterLink}
                      to={`/admin/servidores/${servidor.id}`}
                      sx={{ width: 40, height: 40 }}
                      color="info"
                      edge="end"
                      aria-label="Exibir servidor"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          page={page}
          count={totalPages}
          onChange={(event: React.ChangeEvent<unknown>, pageNumber: number) =>
            setPage(pageNumber)
          }
        />
      </Box>
    </>
  );
}
