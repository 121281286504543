import { Data } from '../types';

const folgasList: Data[] = [
  {
    id: 1,
    title: 'Folga Aniversário',
    detail:
      '<p>Concessão de ponto facultativo de 1(um) dia para membros e servidores em razão de seu aniversário.<p>',
    general:
      '<p>O benefício deverá ser acordado com a chefia imediata do membro ou servidor e poderá ser usufruído ' +
      'a partir da data do aniversário até a data do próximo aniversário.</p>' +
      '<p>Para solicitar o membro ou servidor deverá encaminhar com no mínimo 3 (três) dias de antecedência da ' +
      'data de fruição o Formulário de Requerimentos Diversos (RD), devidamente preenchido e assinado pelo servidor ' +
      'e chefia imediata. No campo “outros” do formulário, deverá ser informado o ano do aniversário que se refere ' +
      'a folga e a data de usufruto.</p> <p>Obs: Essa folga é anual e não cumulativa.</p>',
    docs_download: [
      {
        id: 1,
        title: 'Formulário Requerimento Diversos - RD',
        idFile: '410',
      },
    ],
  },
  {
    id: 2,
    title: 'Folga Plantão',
    detail:
      '<p>Folga compensatória de 1(um) dia, por dia trabalhado, ao membro ou servidor que tenha trabalhado em regime ' +
      ' de plantão.</p>',
    general:
      '<p>As folgas dos plantões devem ser fruídas em até 3 (três) anos contados a partir da data fim do plantão ' +
      'trabalhado. Conforme Resolução do Conselho nº 194 de 14/08/2020.</p>' +
      '<p>Para servidores da área meio, deverá encaminhar com no mínimo 3 (três) dias de antecedência da data de fruição ' +
      'o Formulário de Folga de Plantão – Servidores Administrativos, devidamente preenchido e assinado ' +
      'pelo servidor e chefia imediata, informando a(s) data(s) de usufruto(s) das folgas e o plantão a que se refere.</p>' +
      '<p>Para servidores da área fim e defensores públicos, deverá encaminhar com no mínimo 3 (três) dias de ' +
      'antecedência da data de fruição o Formulário de Folga de Plantão – Diretorias Regionais, ' +
      'devidamente preenchido e assinado pelo servidor, chefia imediata e diretoria regional, informando a(s) data(s) ' +
      'de usufruto(s) das folgas e o plantão a que se refere.</p>',
    docs_download: [
      {
        id: 1,
        title: 'Formulário de Folga de Plantão – Servidores Administrativos',
        idFile: '8',
      },
      {
        id: 2,
        title: 'Formulário de Folga de Plantão – Diretorias Regionais',
        idFile: '401',
      },
    ],
  },
  {
    id: 3,
    title: 'Folga Eleitoral',
    detail:
      '<p>Benefício concedido pela Justiça Eleitoral ao membro ou servidor convocado para trabalhar durante as ' +
      'eleições, que dá direito à folga pelo período correspondente ao dobro dos dias em que tenha ficado à ' +
      'disposição da Justiça Eleitoral.</p>',
    general:
      '<p>O membro ou servidor deverá possuir vínculo ativo com a Defensoria ao tempo da aquisição do ' +
      'benefício, limitando-se à vigência do vínculo laboral, conforme Resolução 22.747/2008 do TSE.</p>' +
      '<p>Para solicitar a folga, o membro ou servidor deverá encaminhar com no mínimo 3 (três) dias de ' +
      'antecedência da data de fruição o Formulário de Requerimentos Diversos (RD), devidamente preenchido ' +
      'e assinado pelo servidor e chefia imediata, informando a(s) data(s) de usufruto(s) das folgas, juntamente ' +
      'com o comprovante fornecido pelo Cartório Eleitoral que comprova sua participação nas eleições.</p>' +
      '<p>Obs. Para servidores requisitados ou com acordo de cooperação técnica, o servidor deverá apresentar ' +
      'Declaração emitida pelo órgão de origem acerca das folgas que o servidor ainda têm direito.</p>',
    docs_download: [
      {
        id: 1,
        title: 'Formulário Requerimento Diversos - RD',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Declaração do Tribunal Regional Eleitoral',
      },
    ],
  },
  {
    id: 4,
    title: 'Folgas Diversas',
    detail:
      '<p>Concessão de ponto facultativo a membros ou servidores da Defensoria engajados em ações ou campanhas ' +
      'realizadas pela Instituição.</p>',
    general:
      '<p>O benefício da Folga para cuidar da própria saúde é válido para mulheres com idade a partir de 30 ' +
      'anos, e para homens a partir de 45 anos de idade, que terão um dia de folga anual para realização de exames ' +
      'preventivos de controle do câncer de mama, colo de útero e próstata.</p>' +
      '<p>Para usufruir do benefício, o membro ou servidor deverá informar à chefia a data escolhida para a folga ' +
      'com antecedência mínima de 10(dez) dias, via e-mail institucional.</p>' +
      '<p>O servidor deverá efetuar a justificativa diretamente no sistema de ponto eletrônico - SISREF juntamente ' +
      'com ocomprovante do exame feito no referido dia. </p>',
  },
];

export default folgasList;
