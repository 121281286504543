import { Box, Popover } from '@mui/material';

type AgendaDayPopoverProps = {
  id: string;
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
  children: JSX.Element;
};

export default function AgendaDayPopover({
  id,
  open,
  anchorEl,
  onClose,
  children,
}: AgendaDayPopoverProps) {
  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </Box>
  );
}
