import { createContext, useContext, useMemo, useState } from 'react';

type AsideDrawerContext = {
  openAside: boolean;
  toggleAside: () => void;
};

const DEFAULT_STATE: AsideDrawerContext = {
  openAside: false,
  toggleAside: () => null,
};

const AsideDrawerContext = createContext<AsideDrawerContext>(DEFAULT_STATE);

type AsideDrawerProviderProps = {
  children: JSX.Element;
};

export function AsideDrawerProvider({ children }: AsideDrawerProviderProps) {
  const [openAside, setOpenAside] = useState(false);

  const toggleAside = () => {
    setOpenAside(prevOpenAside => !prevOpenAside);
  };

  const value = useMemo(() => ({ openAside, toggleAside }), [openAside]);

  return (
    <AsideDrawerContext.Provider value={value}>
      {children}
    </AsideDrawerContext.Provider>
  );
}

export function useAsideDrawer() {
  return useContext(AsideDrawerContext);
}
