import { useRef, useState } from 'react';

import { Box } from '@mui/material';

import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';

import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import canvasPreview from '../../../../utils/canvasPreview';

type PhotoPreviewProps = {
  cropState: [
    Crop | undefined,
    React.Dispatch<React.SetStateAction<Crop | undefined>>,
  ];
  imgSrc: string;
  onCompletedCrop: (canvasElement: HTMLCanvasElement) => void;
};

export default function PhotoPreview({
  cropState,
  imgSrc,
  onCompletedCrop,
}: PhotoPreviewProps) {
  const [crop, setCrop] = cropState;

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [scale] = useState(1);
  const [rotate] = useState(0);
  const [aspect] = useState<number | undefined>(3 / 4);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspectNumber: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspectNumber,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = event.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );

        onCompletedCrop(previewCanvasRef.current);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <>
      {Boolean(imgSrc) && (
        <Box sx={{ width: 'min(100%, 450px)' }}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Foto do crachá"
              src={imgSrc}
              onLoad={onImageLoad}
              crossOrigin="anonymous"
            />
          </ReactCrop>
        </Box>
      )}

      {Boolean(completedCrop) && (
        <canvas
          ref={previewCanvasRef}
          style={{
            border: '1px solid #777',
            objectFit: 'contain',
            width: completedCrop?.width,
            height: completedCrop?.height,
          }}
        />
      )}
    </>
  );
}
