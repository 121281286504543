import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Data = {
  id: number;
  title: string;
  description: string;
  icon: IconProp;
  href: string;
};

const servicos: Data[] = [
  {
    id: 1,
    title: 'Contracheques',
    description: '',
    icon: 'money-check-alt',
    href: '/servicos/contracheque',
  },
  {
    id: 2,
    title: 'Fichas Financeiras',
    description: '',
    icon: 'money-check-alt',
    href: '/servicos/ficha-financeira',
  },
  {
    id: 3,
    title: 'Comprovantes de Rendimentos',
    description: '',
    icon: 'money-check-alt',
    href: '/servicos/comprovante-rendimento',
  },
  {
    id: 4,
    title: 'Férias',
    description: '',
    icon: 'umbrella-beach',
    href: '/pagina/ferias',
  },
  {
    id: 5,
    title: 'Afastamentos',
    description: '',
    icon: 'edit',
    href: '/servicos/afastamento',
  },
  {
    id: 6,
    title: 'Auxílios',
    description: '',
    icon: 'edit',
    href: '/servicos/auxilio',
  },
  {
    id: 7,
    title: 'Concessões',
    description: '',
    icon: 'edit',
    href: '/servicos/concessao',
  },
  {
    id: 8,
    title: 'Folgas',
    description: '',
    icon: 'edit',
    href: '/servicos/folga',
  },
  {
    id: 9,
    title: 'Licenças',
    description: '',
    icon: 'edit',
    href: '/servicos/licenca',
  },

  {
    id: 10,
    title: 'Plano de Saúde',
    description: '',
    icon: 'edit',
    href: '/pagina/plano-de-saude-servir',
  },
  {
    id: 11,
    title: 'Remoção/Permuta',
    description: '',
    icon: 'edit',
    href: '/pagina/remocao-permuta',
  },

  {
    id: 13,
    title: 'Frequência',
    description: '',
    icon: 'calendar-day',
    href: '/pagina/frequencia',
  },
  {
    id: 14,
    title: 'Evolução Funcional',
    description: '',
    icon: 'signal',
    href: '/servicos/evolucao-funcional',
  },
  {
    id: 15,
    title: 'Crachás',
    description: '',
    icon: 'id-card-clip',
    href: '/crachas/meus-crachas',
  },
];

export default servicos;
