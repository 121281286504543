import {
  TablePagination as MuiTablePagination,
  TablePaginationProps,
} from '@mui/material';

export default function TablePagination({
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: TablePaginationProps): JSX.Element {
  return (
    <MuiTablePagination
      count={count}
      page={page}
      rowsPerPageOptions={[10]}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      labelDisplayedRows={({ from, to, count: countPagination }) =>
        `${from}-${to} de ${
          countPagination !== -1 ? countPagination : `mais de ${to}`
        }`
      }
      getItemAriaLabel={props => {
        if (props === 'first') return 'Primeira Página';
        if (props === 'previous') return 'Página Anterior';
        if (props === 'next') return 'Próxima Página';
        if (props === 'last') return 'Última Página';
        return '';
      }}
      showFirstButton
      showLastButton
    />
  );
}
