import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import gruposContatosMap from './GrupoContatos.constants';

export default function GrupoContatos(): JSX.Element {
  return (
    <Box>
      <Box component="header" mb={2} bgcolor="#f1f2f2" py={2} px={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/" color="inherit" underline="hover">
            Início
          </Link>
          <Typography color="secondary" fontWeight="bold">
            Contatos
          </Typography>
        </Breadcrumbs>
      </Box>
      <List>
        {gruposContatosMap.map(grupoContatos => (
          <ListItem
            key={grupoContatos.id}
            alignItems="flex-start"
            sx={{
              border: 1,
              borderColor: 'grey.200',
              borderRadius: 1,
              py: 2,
              mb: 1,
            }}
          >
            <ListItemIcon>{grupoContatos.icone}</ListItemIcon>
            <ListItemText sx={{ ml: 2 }}>
              <Link
                component={RouterLink}
                to={`/contatos/${grupoContatos.id}`}
                underline="hover"
              >
                {grupoContatos.nome}
              </Link>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {grupoContatos.descricao}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
