import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';

import { Link as RouterLink } from 'react-router-dom';
import DateTimeFormatter from '../../../components/DateTimeFormatter';
import { useAuth } from '../../../context/AuthProvider';

import { getMeusCrachas } from '../../../services/portal';
import { solicitacoesMap, statusMap } from '../Crachas.constants';

export default function CrachasMeusCrachas() {
  const { servidor } = useAuth();

  const { data: crachas = [] } = useQuery(
    ['crachas', servidor?.matricula],
    async () => {
      if (servidor != null) {
        const {
          data: { results },
        } = await getMeusCrachas();

        return results;
      }

      return [];
    },
    {
      enabled: servidor != null,
    },
  );

  return (
    <Box>
      <Typography variant="h5" mb={3}>
        Meus Crachás
      </Typography>

      {['estagiario', 'voluntario'].includes(servidor?.tipo || '') ? (
        <p>
          A solicitação do crachá deve ser feita diretamente na Diretoria
          Regional ou Núcleo Especializado.
        </p>
      ) : (
        <>
          <Table sx={{ mb: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell>Solicitação</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {crachas.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    Nenhum crachá para exibir
                  </TableCell>
                </TableRow>
              )}

              {crachas.map(cracha => (
                <TableRow key={cracha.id}>
                  <TableCell>
                    {solicitacoesMap[cracha.solicitacao || '']}
                  </TableCell>

                  <TableCell>
                    <DateTimeFormatter datetime={cracha.created_at}>
                      {({ dateFormatted, timeFormatted }) => (
                        <Typography>
                          {dateFormatted} {timeFormatted}
                        </Typography>
                      )}
                    </DateTimeFormatter>
                  </TableCell>

                  <TableCell>{statusMap[cracha.status || '']}</TableCell>

                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        component={RouterLink}
                        to={`/crachas/meus-crachas/${cracha.id}`}
                      >
                        Exibir
                      </Button>

                      {cracha.status === 'impresso' && (
                        <Button>Marcar como recebido</Button>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box>
            <Button
              variant="contained"
              component={RouterLink}
              to="/crachas/nova-solicitacao"
            >
              Nova solicitação
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
