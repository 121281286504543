import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import PagesHeader from '../../../../components/HeaderPages';
import LoadingState from '../../../../components/LoadingState';
import { getServidor } from '../../../../services/portal';

export default function ServidoresShow() {
  const { id } = useParams<{ id: string }>();

  const {
    data: servidor,
    isLoading,
    isError,
  } = useQuery(['servidores', id], async () => {
    if (id != null) {
      const { data } = await getServidor(id);
      return data;
    }

    return null;
  });

  return (
    <LoadingState
      isLoading={isLoading}
      isError={isError}
      title={
        <Box>
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Administração
            </Link>

            <Link
              component={RouterLink}
              to="/admin/servidores"
              color="inherit"
              underline="hover"
            >
              Servidores
            </Link>

            <Typography color="secondary" fontWeight="bold">
              {servidor?.id}
            </Typography>
          </Breadcrumbs>

          <PagesHeader title="Servidor" />
        </Box>
      }
    >
      <Table sx={{ mb: 3 }}>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Id</TableCell>
            <TableCell>{servidor?.id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Nome</TableCell>
            <TableCell>{servidor?.nome}</TableCell>
          </TableRow>

          {typeof servidor?.nome_social === 'string' &&
            servidor.nome_social !== '' && (
              <TableRow>
                <TableCell variant="head">Nome social</TableCell>
                <TableCell>{servidor?.nome_social}</TableCell>
              </TableRow>
            )}

          <TableRow>
            <TableCell variant="head">Email</TableCell>
            <TableCell>{servidor?.email}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Matrícula</TableCell>
            <TableCell>{servidor?.matricula}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">CPF</TableCell>
            <TableCell>{servidor?.cpf}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Vínculo</TableCell>
            <TableCell>{servidor?.vinculo}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Tipo</TableCell>
            <TableCell>{servidor?.tipo}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Lotação atual</TableCell>
            <TableCell>{servidor?.lotacao_atual}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Data de nascimento</TableCell>
            <TableCell>{servidor?.data_nascimento}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Aniversariante</TableCell>
            <TableCell>{servidor?.aniversariante ? 'Sim' : 'Não'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Cargo</TableCell>
            <TableCell>{servidor?.cargo}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Comarca</TableCell>
            <TableCell>{servidor?.comarca}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Usuário</TableCell>
            <TableCell>
              {servidor?.usuario_id != null ? (
                <Link
                  component={RouterLink}
                  to={`/admin/usuarios/${servidor.usuario_id}`}
                  underline="hover"
                >
                  {servidor.usuario_id}
                </Link>
              ) : (
                <Typography>Servidor não possui usuário vinculado</Typography>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/admin/servidores/${servidor?.id}/edit`}
        >
          Editar
        </Button>

        <Button variant="text" component={RouterLink} to="/admin/servidores">
          Voltar
        </Button>
      </Box>
    </LoadingState>
  );
}
