import { SyntheticEvent, useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import Accordion from '../../../components/Accordion';
import Breadcrumbs from '../../../components/Breadcrumbs';
import PostifyHtmlContent from '../../../components/PostifyHtmlContent';

import { getPagina } from '../../../services/api';

type Pagina = {
  author: string;
  title: string;
  text: string;
  created_at: string;
  updated_at: string;
  cards: [
    {
      id: string;
      title: string;
      link: string;
    },
  ];
};

type AccordionPageProps = {
  pageId: string;
  expanded: string | false;
  handleChange: (
    panel: string,
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
};

function AccordionPage({ pageId, expanded, handleChange }: AccordionPageProps) {
  const [pagina, setPagina] = useState<Pagina>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPagina = async () => {
      setLoading(true);

      const response = await getPagina<Pagina>(pageId);
      setPagina(response.data);

      setLoading(false);
    };

    fetchPagina();
  }, [pageId]);

  return (
    <Box>
      {loading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      ) : (
        <Accordion
          title={pagina?.title || ''}
          expanded={expanded === pageId}
          onChange={handleChange(pageId)}
        >
          <Box color="grey.700">
            <PostifyHtmlContent content={pagina?.text || ''} />
          </Box>
        </Accordion>
      )}
    </Box>
  );
}

export default function EnvolucaoFuncional() {
  const [pagina, setPagina] = useState<Pagina>();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchPagina = async () => {
      const response = await getPagina<Pagina>('35894');
      setPagina(response.data);
    };

    fetchPagina();
  }, []);

  return (
    <Box>
      <Breadcrumbs>
        <Typography color="secondary" fontWeight="bold">
          {pagina?.title}
        </Typography>
      </Breadcrumbs>
      <Box px={{ xs: 1, md: 3 }}>
        <Box component="header" mb={3}>
          <Typography component="h2" fontWeight="bold" fontSize="1.5rem">
            {pagina?.title}
          </Typography>

          <p>
            Publicado em <span>{pagina?.created_at}</span>
          </p>
        </Box>

        <PostifyHtmlContent content={pagina?.text || ''} />

        {pagina?.cards.map(page => (
          <AccordionPage
            key={page.link}
            pageId={page.link}
            expanded={expanded}
            handleChange={handleChange}
          />
        ))}
      </Box>
    </Box>
  );
}
