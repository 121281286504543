import { Box, Button, Typography } from '@mui/material';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQuery } from '@tanstack/react-query';

import Header from '../../Header';

import getIconeSistema from '../../../utils/iconesSistemas';

import { useAsideDrawer } from '../../../context/AsideDrawerProvider';
import { getSistemas } from '../../../services/portal';
import { useAuth } from '../../../context/AuthProvider';

export default function Sistemas(): JSX.Element {
  const { usuario } = useAuth();

  const { data: sistemas = [] } = useQuery(
    ['sistemas', usuario?.username],
    async () => {
      const { data } = await getSistemas(usuario?.username);
      return data;
    },
    {
      select: data => data.slice(0, 6),
    },
  );

  const { openAside, toggleAside } = useAsideDrawer();

  const handleAsideDrawerClose = () => {
    if (openAside) {
      toggleAside();
    }
  };
  return (
    <Box
      sx={{
        bgcolor: '#f1f2f2',
        p: 2,
        borderRadius: 1,
      }}
    >
      <Header
        title="Sistemas"
        to="/sistemas"
        titleButton="Ver Todos"
        onClick={handleAsideDrawerClose}
      />

      <Box display="grid" gridTemplateColumns="repeat(2, 2fr)" gap="0.5rem">
        {sistemas.map(sistema => (
          <Button
            key={sistema.name}
            variant="text"
            href={sistema.url}
            target="_blank"
            sx={{
              textTransform: 'none',
              '&:hover': {
                color: theme => theme.palette.secondary.main,
                backgroundColor: 'none',
              },
            }}
          >
            <Box textAlign="center">
              <FontAwesomeIcon
                icon={getIconeSistema(sistema.name) as IconProp}
                size="lg"
              />

              <Typography>{sistema.name}</Typography>
            </Box>
          </Button>
        ))}
      </Box>
    </Box>
  );
}
