const cores = [
  '#014c40',
  '#0199aa',
  '#0488d2',
  '#34691e',
  '#5d423b',
  '#5e6bc1',
  '#689f38',
  '#77909a',
  '#7b1fa4',
  '#8e6d66',
  '#ac47bb',
  '#be3609',
  '#be360b',
  '#c1195b',
  '#ec417a',
  '#ec417b',
  '#ee6e03',
  '#ef6e03',
];

export default cores;
