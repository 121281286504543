import {
  faFilePdf,
  faFileUpload,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { red } from '@mui/material/colors';

import { SubmitHandler, useForm } from 'react-hook-form';
import { createCracha } from '../../../../services/portal';

type UserFields = {
  nome: string;
  cargo: string;
  matricula: string;
};

type FormInputs = UserFields & {
  motivo: string;
  documentos: FileList;
};

export default function FormularioSomenteCordao({
  nome,
  cargo,
  matricula,
}: UserFields) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      nome,
      cargo,
      matricula,
      motivo: '',
    },
  });

  const watchDocumentos = watch('documentos');
  const watchMotivo = watch('motivo');

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const formData = new FormData();

    formData.append('cracha[nome]', data.nome);
    formData.append('cracha[cargo]', data.cargo);
    formData.append('cracha[matricula]', data.matricula);
    formData.append('cracha[observacao_requerente]', data.motivo);
    formData.append('cracha[solicitacao]', 'cordao');
    formData.append('cracha[cordao]', '1');

    for (let i = 0; i < data.documentos.length; i += 1) {
      const file = data.documentos.item(i);

      if (file != null) {
        formData.append('cracha[documentos][]', file, file.name);
      }
    }

    const { data: cordao } = await createCracha(formData);
    navigate(`/crachas/meus-crachas/${cordao.id}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {watchMotivo !== '' && watchMotivo !== 'desgaste_natural' && (
          <>
            <Typography>
              <strong>Observação:</strong>
              Informa-se que em razão de desgaste por mau uso do cordão, o mesmo
              deverá ser devolvido ao RH, sendo cobrado o valor de{' '}
              <strong>R$ 8,10 (oito reais e dez centavos)</strong> para novo
              cordão, que deverá ser depositado na Conta da Defensoria Pública -
              Banco do Brasil - Agência: 3615-3, Conta : 82075-X, CNPJ
              07.248.660/0001-35 ou na chave PIX:{' '}
              <strong>contabilidade@defensoria.to.def.br</strong>.
            </Typography>

            <Typography>
              <strong>Orientação do setor financeiro:</strong> Remenda-se aos
              servidores que a transferência e/ou depósito seja efetuado até o
              dia 25 de cada mês, em razão dos procedimentos de conciliação
              bancária.
            </Typography>
          </>
        )}

        <FormControl fullWidth error={!!errors.motivo}>
          <InputLabel id="select-motivo-label">Motivo</InputLabel>

          <Select
            labelId="select-motivo-label"
            id="select-motivo"
            defaultValue=""
            label="Motivo"
            sx={{ mb: 1 }}
            {...register('motivo', {
              required: true,
            })}
          >
            <MenuItem value="">Selecione uma opção</MenuItem>
            <MenuItem value="perda">Perda</MenuItem>

            <MenuItem value="mau_estado_conservacao">
              Mau estado de conservação
            </MenuItem>

            <MenuItem value="extravio">Extravio</MenuItem>
            <MenuItem value="desgaste_natural">Desgaste natural</MenuItem>
          </Select>

          {watchMotivo === 'desgaste_natural' && (
            <Typography>
              <strong>Observação:</strong> Informa-se que conforme Ato n°
              118/2018, publicado no Diário Oficial n° 5.113 de 13 de maio de
              2018, em razão de desgaste natural o cordão deverá ser devolvido
              ao RH para avaliação e envio do novo.
            </Typography>
          )}
        </FormControl>

        {watchMotivo !== 'desgaste_natural' && (
          <Box>
            <Box sx={{ mb: 1 }}>
              <Button
                component="label"
                startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                variant="contained"
              >
                Comprovante de pagamento
                <input
                  hidden
                  type="file"
                  accept="application/pdf"
                  multiple
                  {...register('documentos', {
                    required: true,
                  })}
                />
              </Button>

              {!!errors.documentos && (
                <Typography variant="subtitle2" sx={{ color: red[500] }}>
                  Comprovante de pagamento obrigatório
                </Typography>
              )}
            </Box>

            <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>
              Aceita somente a extensão PDF
            </Typography>

            {typeof watchDocumentos !== 'undefined' && (
              <Box sx={{ mt: 2 }}>
                {Array.from(watchDocumentos).map(documento => (
                  <Box
                    key={`${documento.name}-${new Date().valueOf()}`}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <Box color={red[500]}>
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Box>
                    <Typography>{documento.name}</Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            type="submit"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSave} />}
          >
            Enviar
          </Button>

          <Button component={RouterLink} to="/crachas/meus-crachas">
            Voltar
          </Button>
        </Box>
      </Box>
    </form>
  );
}
