import { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  getAllComprovantesRendimentos,
  getComprovantesRendimentos,
} from '../../../services/api';

import Breadcrumbs from '../../../components/Breadcrumbs';
import ButtonReport from '../../../components/ButtonReport';
import HeaderPages from '../../../components/HeaderPages';
import TablePagination from '../../../components/TablePagination';
import { useAuth } from '../../../context/AuthProvider';
import { ComprovanteRendimento } from '../../../types';

export default function ComprovantesRendimentos(): JSX.Element {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [rowsPerPage] = useState(10);

  const [comprovantesRendimentos, setComprovantesRendimentos] = useState<
    ComprovanteRendimento[]
  >([]);

  const { usuario, servidor } = useAuth();
  const cpf = servidor?.cpf;

  useEffect(() => {
    const fetchContracheques = async () => {
      if (cpf != null) {
        setLoading(true);
        const { results, count: comprovantesCount } =
          await getAllComprovantesRendimentos(cpf);

        setComprovantesRendimentos(results.slice(0, 10));
        setCount(comprovantesCount);

        setLoading(false);
      }
    };

    fetchContracheques();
  }, [cpf]);

  const fetchComprovantesRendimentos = async (pageNumber: number) => {
    if (cpf != null) {
      setLoading(true);
      const { results, count: countComprovantes } =
        await getComprovantesRendimentos(cpf, pageNumber);
      setComprovantesRendimentos(results);
      setCount(countComprovantes);
      setLoading(false);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    fetchComprovantesRendimentos(newPage * 10);
    setPage(newPage);
  };

  return (
    <Box>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/servicos"
          color="inherit"
          underline="hover"
        >
          Serviços
        </Link>
        <Typography color="secondary" fontWeight="bold">
          Comprovantes de Rendimentos
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Comprovantes de Rendimentos" />

      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Demostrativo</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress color="secondary" disableShrink />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              comprovantesRendimentos.map(comprovante => (
                <TableRow key={comprovante.declaracao.id}>
                  <TableCell>
                    <Typography
                      component="span"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {comprovante.declaracao.nome}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <ButtonReport
                      app="athenas"
                      name={
                        comprovante.declaracao.ano_base > 2021
                          ? 'comprovante_rendimentos_2023'
                          : 'comprovante_rendimentos'
                      }
                      user={usuario?.username || 'portal do servidor'}
                      resource="athenas/gfp"
                      fileName="comprovante_rendimento"
                      params={{
                        pessoa_fisica: comprovante.pessoa_fisica,
                        declaracao: comprovante.declaracao.id,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>

          {count > 10 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
