import { Avatar, Box, Paper, SxProps, Typography } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { Aniversariante } from '../../types';
import stringAvatar from './CardAniversariante.helpers';
import { exibirNome } from '../../utils/exibirNome';

type CardAniversarianteProps = {
  aniversariante: Aniversariante;
  stylesBirthdaysRemaining?: SxProps;
};

const CardAniversariante = forwardRef<HTMLDivElement, CardAniversarianteProps>(
  ({ aniversariante, stylesBirthdaysRemaining }, ref) => {
    const today = useMemo(() => new Date(), []);

    const applyStylesBirthdaysRemaining =
      aniversariante.dia_aniversario >= today.getDate();

    return (
      <Box
        ref={ref}
        sx={
          applyStylesBirthdaysRemaining ? stylesBirthdaysRemaining : undefined
        }
      >
        <Paper sx={{ p: 1.5 }} elevation={3}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box>
              {aniversariante.avatar ? (
                <Avatar
                  sx={{ width: '70px', height: '70px' }}
                  src={aniversariante.avatar}
                />
              ) : (
                <Avatar {...stringAvatar(aniversariante.letra_avatar)} />
              )}
            </Box>

            <Box>
              <Typography
                component="span"
                variant="body2"
                noWrap
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                <Box component="span" className="aniversariante-dia">
                  {aniversariante.dia_aniversario}
                </Box>

                {' - '}

                <Box component="span" className="aniversariante-nome">
                  {exibirNome(aniversariante).toLowerCase()}
                </Box>
              </Typography>

              <Typography
                variant="caption"
                className="aniversariante-comarca"
                sx={{ textTransform: 'capitalize', display: 'block' }}
              >
                {aniversariante.comarca?.toLowerCase()}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  },
);

CardAniversariante.displayName = 'CardAniversariante';

export default CardAniversariante;
