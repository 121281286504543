import { Box, Container, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';

import Aside from '../../components/Aside';
import AsideDrawer from '../../components/AsideDrawer';
import MainContent from '../../components/MainContent';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import SidebarDrawer from '../../components/SidebarDrawer';

import theme from '../../theme';

type AuthenticatedLayoutProps = {
  children: React.ReactNode;
};

export default function AuthenticatedLayout({
  children,
}: AuthenticatedLayoutProps) {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />

      <Box component="main" bgcolor={grey[50]}>
        <Container
          component="section"
          sx={{ bgcolor: 'white', display: 'flex' }}
          disableGutters
        >
          <Sidebar sx={{ display: { xs: 'none', lg: 'flex' } }} />

          <SidebarDrawer sx={{ display: { sm: 'flex', lg: 'none' } }} />

          <MainContent>{children}</MainContent>

          <Aside sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} />

          <AsideDrawer sx={{ display: { sm: 'flex', md: 'none' } }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
