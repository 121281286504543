import { Box, IconButton } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { grey } from '@mui/material/colors';

import { useAsideDrawer } from '../../context/AsideDrawerProvider';

type MainContentProps = {
  children: React.ReactNode;
};

export default function MainContent({
  children,
}: MainContentProps): JSX.Element {
  const { toggleAside } = useAsideDrawer();

  return (
    <Box component="section" flexGrow={1} p={2} color={grey[700]}>
      <IconButton
        title="Mostrar painel lateral"
        color="inherit"
        size="medium"
        sx={{
          width: 35,
          height: 35,
          float: 'right',
          color: 'white',
          bgcolor: theme => theme.palette.primary.main,
          displayPrint: 'none',
          transition: 'filter 0.2s',
          '&:hover': {
            bgcolor: theme => theme.palette.primary.main,
            color: 'white',
            filter: 'brightness(0.9)',
          },
          display: { xs: 'flex', md: 'none' },
        }}
        onClick={toggleAside}
      >
        <FontAwesomeIcon icon="angle-left" />
      </IconButton>

      {children}
    </Box>
  );
}
