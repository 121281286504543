import { faFileLines as fileIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import getDocumentosOdin from '../../services/odin';

import pluralTitles from './Documentos.helpers';

type ParamsProps = {
  origem: string;
  tipo: string | undefined;
};

type Documento = {
  id: number;
  nome: string;
  arquivo: string;
  categoria: string | null;
  observacoes: string | null;
};

type DocumentosResponse = {
  documentos: Documento[];
  total_pages: number;
};

type SearchForm = {
  numero: number | '';
  ano: number | '';
  conteudo: string | '';
};

export default function Documentos(): JSX.Element {
  const { control, handleSubmit } = useForm<SearchForm>();
  const { origem, tipo } = useParams<ParamsProps>();
  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [searchParams, setSearchParams] = useState<SearchForm>();

  const onSubmit = (data: SearchForm) => setSearchParams(data);

  useEffect(() => {
    const fetchDocumentos = async () => {
      setLoading(true);
      if (
        typeof tipo === 'string' &&
        tipo.trim() !== '' &&
        typeof origem === 'string' &&
        origem.trim() !== ''
      ) {
        const { data } = await getDocumentosOdin<DocumentosResponse>(
          origem,
          tipo,
          page,
          searchParams,
        );

        setDocumentos(data.documentos);
        setNumberOfPages(data.total_pages);
        setTitle(pluralTitles(tipo));
        setLoading(false);
      }
    };

    fetchDocumentos();
  }, [page, origem, tipo, numberOfPages, searchParams]);

  return (
    <Box color={grey[700]}>
      <Box component="header" mb={3}>
        <Breadcrumbs>
          <Typography color="secondary" fontWeight="bold">
            Documentos
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ flexGrow: 1, color: grey[700], px: { xs: 1, md: 3 } }}>
        <header>
          <h2>{title}</h2>
        </header>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      ) : (
        <Box px={{ xs: 1, md: 3 }}>
          <Box
            component="form"
            sx={{
              bgcolor: '#F1F2F2',
              p: 1,
              '& > :not(style)': { m: 1 },
              '& >': { backgroundColor: '#3f3f8a' },
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              defaultValue=""
              name="numero"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  label="Número"
                  variant="outlined"
                  size="small"
                  onChange={onChange}
                  sx={{ p: 0, width: { xs: '8em', sm: '6em' } }}
                />
              )}
            />
            <Controller
              defaultValue=""
              name="ano"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  label="Ano"
                  variant="outlined"
                  size="small"
                  onChange={onChange}
                  sx={{ p: 0, width: { xs: '9em', sm: '6em' } }}
                />
              )}
            />
            <Controller
              defaultValue=""
              name="conteudo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  label="Conteúdo"
                  variant="outlined"
                  size="small"
                  onChange={onChange}
                  sx={{ p: 0, width: { xs: '18em', sm: '20em' } }}
                />
              )}
            />
            <Button variant="contained" type="submit">
              Pesquisar
            </Button>
          </Box>
          <List>
            {documentos.map(documento => (
              <ListItem key={documento.id} sx={{ pl: 0 }}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={fileIcon}
                    size="2x"
                    color="rgb(99, 114, 130)"
                  />
                </ListItemIcon>
                <ListItemText
                  secondary={
                    documento?.observacoes
                      ? `${documento?.observacoes}`
                      : `${documento?.categoria} - Nenhuma observação`
                  }
                  sx={{ mb: 0, color: 'primary.main' }}
                >
                  <Button
                    variant="text"
                    href={documento?.arquivo}
                    target="_blank"
                    sx={{
                      textTransform: 'none',
                      px: 0,
                      '&:hover': {
                        color: theme => theme.palette.secondary.main,
                        backgroundColor: 'none',
                      },
                    }}
                  >
                    {documento?.nome}
                  </Button>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {numberOfPages > 0 && (
        <Box
          pt={3}
          px={{ xs: 1, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack spacing={2}>
            <Pagination
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              count={numberOfPages}
              onChange={(event, value) => {
                setPage(value);

                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
