import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  faEdit,
  faEnvelope,
  faEye,
  faIdCard,
  faIdCardAlt,
  faSearch,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQuery } from '@tanstack/react-query';

import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Breadcrumbs from '../../../components/Breadcrumbs';
import PagesHeader from '../../../components/HeaderPages';
import LoadingState from '../../../components/LoadingState';
import { Servidor } from '../../../context/AuthProvider';
import { getServidores } from '../../../services/portal';

type FilterInputs = Partial<
  Pick<
    Servidor,
    | 'nome'
    | 'email'
    | 'matricula'
    | 'cpf'
    | 'aniversariante'
    | 'ativo'
    | 'cargo'
    | 'comarca'
    | 'lotacao_atual'
    | 'tipo'
    | 'vinculo'
  >
>;

export default function Servidores(): JSX.Element {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [filter, setFilter] = useState<FilterInputs>();

  const filterMemoized = useMemo<FilterInputs>(
    () => ({
      nome: filter?.nome,
      email: filter?.email,
      matricula: filter?.matricula,
      cpf: filter?.cpf,
      aniversariante: filter?.aniversariante,
      ativo: filter?.ativo,
      cargo: filter?.cargo,
      comarca: filter?.comarca,
      lotacao_atual: filter?.lotacao_atual,
      tipo: filter?.tipo,
      vinculo: filter?.vinculo,
    }),
    [
      filter?.aniversariante,
      filter?.ativo,
      filter?.cargo,
      filter?.comarca,
      filter?.cpf,
      filter?.email,
      filter?.lotacao_atual,
      filter?.matricula,
      filter?.nome,
      filter?.tipo,
      filter?.vinculo,
    ],
  );

  const { register, handleSubmit } = useForm<FilterInputs>();

  const {
    data: servidores = [],
    isLoading,
    isError,
  } = useQuery(['servidores', page, filterMemoized], async () => {
    const { data } = await getServidores({
      page,
      'q[nome_or_nome_social_or_matricula_or_cpf_cont]': filterMemoized.nome,
      'q[cargo_cont]': filterMemoized.cargo,
      'q[comarca_cont]': filterMemoized.comarca,
      'q[cpf_eq]': filterMemoized.cpf,
      'q[email_cont]': filterMemoized.email,
      'q[lotacao_atual_cont]': filterMemoized.lotacao_atual,
      'q[matricula_eq]': filterMemoized.matricula,
      'q[tipo_eq]': filterMemoized.tipo,
      'q[vinculo]': filterMemoized.vinculo,
    });

    setTotalPages(data.pagy.last);

    return data.results;
  });

  const onSubmit: SubmitHandler<FilterInputs> = data => {
    setPage(1);
    setFilter(data);
  };

  return (
    <LoadingState
      isLoading={isLoading}
      isError={isError}
      title={
        <Box>
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Administração
            </Link>

            <Typography color="secondary" fontWeight="bold">
              Servidores
            </Typography>
          </Breadcrumbs>

          <PagesHeader title="Servidores" />
        </Box>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
            alignItems: 'end',
          }}
        >
          <Box>
            <Typography
              component="h2"
              fontWeight="bold"
              color="grey"
              sx={{ mb: 1 }}
            >
              Buscar por nome, matrícula ou CPF:
            </Typography>

            <Paper
              elevation={3}
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 50,
                height: '40px',
              }}
            >
              <InputBase
                fullWidth
                placeholder="Nome, matrícula ou CPF"
                sx={{ p: 2 }}
                {...register('nome')}
              />
            </Paper>
          </Box>

          <Box>
            <Typography
              component="h2"
              fontWeight="bold"
              color="grey"
              sx={{ mb: 1 }}
            >
              Buscar por Tipo:
            </Typography>

            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 50,
                height: '40px',
              }}
            >
              <Select
                fullWidth
                labelId="select-tipos-usuario"
                id="select-tipos-usuario"
                sx={{
                  borderRadius: 50,
                  height: 40,
                }}
                defaultValue=""
                {...register('tipo')}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="defensor">Defensor</MenuItem>
                <MenuItem value="estagiario">Estagiário</MenuItem>
                <MenuItem value="servidor">Servidor</MenuItem>
                <MenuItem value="voluntario">Voluntário</MenuItem>
                <MenuItem value="jovem_trabalhador">Jovem Trabalhador</MenuItem>
              </Select>
            </Paper>
          </Box>

          <Box>
            <Typography
              component="h2"
              fontWeight="bold"
              color="grey"
              sx={{ mb: 1 }}
            >
              Lotação:
            </Typography>

            <Paper
              elevation={3}
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 50,
                height: '40px',
              }}
            >
              <InputBase
                fullWidth
                placeholder="Ex.: Recursos Humanos"
                sx={{ p: 2 }}
                {...register('lotacao_atual')}
              />
            </Paper>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{ justifySelf: 'flex-end' }}
          >
            <FontAwesomeIcon icon={faSearch} />
            Buscar
          </Button>
        </Box>
      </form>

      <TableContainer component={Paper} sx={{ my: 3 }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Matrícula</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {servidores.map(servidor => (
              <TableRow key={servidor.id}>
                <TableCell>
                  <Typography
                    key="nome"
                    noWrap
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box
                      component="span"
                      mr={1}
                      display="inline-block"
                      width={25}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </Box>
                    {servidor.nome}
                  </Typography>

                  {typeof servidor.nome_social === 'string' &&
                    servidor.nome_social !== '' && (
                      <Typography
                        key="nome-social"
                        noWrap
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Box
                          component="span"
                          mr={1}
                          display="inline-block"
                          width={25}
                        >
                          <FontAwesomeIcon icon={faIdCardAlt} />
                        </Box>

                        {servidor.nome_social}
                      </Typography>
                    )}

                  {typeof servidor.email === 'string' &&
                    servidor.email !== '' && (
                      <Typography
                        key="email"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Box
                          component="span"
                          mr={1}
                          display="inline-block"
                          width={25}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </Box>

                        {servidor.email}
                      </Typography>
                    )}

                  {typeof servidor.vinculo === 'string' &&
                    servidor.vinculo !== '' && (
                      <Typography key="vinculo">
                        <Box
                          component="span"
                          mr={1}
                          display="inline-block"
                          width={25}
                        >
                          <FontAwesomeIcon icon={faIdCard} />
                        </Box>

                        {servidor.vinculo}
                      </Typography>
                    )}
                </TableCell>

                <TableCell>
                  <Typography>{servidor.matricula}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{servidor.cpf}</Typography>
                </TableCell>

                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      component={RouterLink}
                      to={`/admin/servidores/${servidor.id}`}
                      sx={{ width: 40, height: 40 }}
                      color="info"
                      edge="end"
                      aria-label="Exibir servidor"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </IconButton>

                    <IconButton
                      component={RouterLink}
                      to={`/admin/servidores/${servidor.id}/edit`}
                      sx={{ width: 40, height: 40 }}
                      color="success"
                      edge="end"
                      aria-label="Editar servidor"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        page={page}
        count={totalPages}
        onChange={(event: React.ChangeEvent<unknown>, pageNumber: number) =>
          setPage(pageNumber)
        }
      />
    </LoadingState>
  );
}
