import { Box, Button, CircularProgress, Typography } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import UpdatingIcon from '@mui/icons-material/Refresh';

import React, { useRef, useState } from 'react';
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderPages from '../../../components/HeaderPages';
import { VisuallyHiddenInput } from '../../../components/VisuallyHiddenInput';
import { useAuth } from '../../../context/AuthProvider';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import useDebounceEffect from '../../../hooks/useDebounceEffect';
import { updateProfilePhoto } from '../../../services/portal';
import canvasPreview from '../../../utils/canvasPreview';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight,
  );
}

const scale = 1;
const rotate = 0;
const aspect = 1;

export default function UsuarioEditarFoto() {
  const { servidor, updateProfilePhoto: atualizarFoto } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [isUpdating, setIsUpdating] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [imgSrc, setImgSrc] = useState('');
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function convertCanvasToBlob() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;

    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    const ctx = offscreen.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    return offscreen.convertToBlob({
      type: 'image/png',
    });
  }

  async function postImage() {
    const blob = await convertCanvasToBlob();

    const formData = new FormData();
    formData.append('servidor[foto]', blob, 'profile_photo.png');

    try {
      setIsUpdating(true);
      const { data } = await updateProfilePhoto(formData);

      console.log(data);

      if (data.foto != null && data.foto.length > 0) {
        atualizarFoto(data.foto);
      }

      enqueueSnackbar('Foto atualizada com sucesso', {
        variant: 'success',
      });
    } catch (err) {
      const errorMessage = axios.isAxiosError(err)
        ? `Erro ao atualizar foto de perfil: ${err.message}`
        : 'Erro ao atualizar foto de perfil';

      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsUpdating(false);
    }
  }

  return (
    <Box>
      <Box component="header" mb={3}>
        <Breadcrumbs>
          <Typography color="secondary" fontWeight="bold">
            Editar foto de perfil
          </Typography>
        </Breadcrumbs>
      </Box>

      <HeaderPages title="Editar foto de perfil" />

      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: 3,
            mb: 3,
          }}
        >
          {servidor?.foto && (
            <Box
              component="img"
              src={servidor.foto || ''}
              alt="Foto de perfil"
              width={200}
              height={200}
              sx={{
                objectFit: 'cover',
                borderWidth: 4,
                borderStyle: 'solid',
                borderColor: 'primary.dark',
                borderRadius: '50%',
              }}
            />
          )}

          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Carregar nova foto
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: 3,
          }}
        >
          {!!imgSrc && (
            <Box>
              <Typography
                fontWeight="bold"
                fontSize="h5.fontSize"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                Edite a foto
              </Typography>

              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                aspect={aspect}
                circularCrop
                minWidth={300}
                minHeight={300}
              >
                <Box
                  component="img"
                  ref={imgRef}
                  alt="Imagem do para ser ajustada"
                  src={imgSrc}
                  width={300}
                  height={300}
                  sx={{ objectFit: 'cover' }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </Box>
          )}

          {!!completedCrop && (
            <Box>
              <Typography
                fontWeight="bold"
                fontSize="h5.fontSize"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                Pré-visualização
              </Typography>

              <Box
                component="canvas"
                ref={previewCanvasRef}
                sx={{
                  border: '4px solid #545456',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </Box>
          )}
        </Box>

        {!!completedCrop && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'normal' },
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Button
                type="button"
                onClick={postImage}
                variant="contained"
                startIcon={isUpdating ? <UpdatingIcon /> : <SaveIcon />}
                sx={{ mt: 2 }}
                disabled={isUpdating}
              >
                {isUpdating ? 'Salvando...' : 'Salvar alterações'}
              </Button>

              {isUpdating && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    mt: '-4px',
                    ml: '-10px',
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
