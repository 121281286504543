import { Box, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ModalCartaoAniversariante from '../../components/ModalCartaoAniversariante';
import { getNoticiasDestaques } from '../../services/postify';

import Aniversariantes from './Aniversariantes';
import HomeBanners from './HomeBanners';
import HomeNoticias from './HomeNoticias';

import useGetAniversariantesPorPeriodo from './Aniversariantes/Aniversariantes.hooks';
import ModalCartao10AnosDefensoria from '../../components/ModalCartao10AnosDefensoria';

export default function Home(): JSX.Element {
  const { data: noticiasDestaque = [], isLoading: isNoticiasDestaqueLoading } =
    useQuery(['noticias-destaque'], async () => {
      const { noticias } = await getNoticiasDestaques(
        1,
        'portal_conhecimento_noticia_destaque',
      );
      return noticias;
    });

  const {
    aniversariantes: aniversariantesDia,
    isLoading: isAniversariantesDiaLoading,
  } = useGetAniversariantesPorPeriodo('hoje');

  const {
    aniversariantes: aniversariantesMes,
    isLoading: isAniversariantesMesLoading,
  } = useGetAniversariantesPorPeriodo('mes');

  const isLoading =
    isNoticiasDestaqueLoading ||
    isAniversariantesDiaLoading ||
    isAniversariantesMesLoading;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 10 }}>
        <CircularProgress color="inherit" size={45} />
      </Box>
    );
  }

  return (
    <Box>
      <ModalCartaoAniversariante />
      <ModalCartao10AnosDefensoria />

      <HomeNoticias
        noticiasDestaque={noticiasDestaque}
        component="section"
        mb={3}
      />

      <Aniversariantes
        aniversariantesDia={aniversariantesDia}
        aniversariantesMes={aniversariantesMes}
        component="section"
        mb={3}
      />

      <HomeBanners />
    </Box>
  );
}
