import { Data } from '../types';

const auxiliosList: Data[] = [
  {
    id: 1,
    title: 'Auxílio Funeral',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de óbito',
      },
      {
        id: 3,
        title: 'Conta bancaria',
      },
      {
        id: 4,
        title:
          'Documentos pessoais do familiar ou terceiro, caso servidor falecido',
      },
      {
        id: 5,
        title:
          'Nota fiscal referente ao pagamento das despesas do funeral no nome do servidor(a) ou familiar do servidor(a)',
      },
    ],
  },
  {
    id: 2,
    title: 'Auxílio Natalidade',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de nascimento ou registro geral (RG) da criança',
      },
      {
        id: 3,
        title: 'Certidão de óbito, no caso de natimorto',
      },
      {
        id: 4,
        title: 'Cópia da sentença de concessão da adoção, se aplicável',
      },
      {
        id: 5,
        title:
          'Declaração firmada pelo servidor requerente informando que o(a) ' +
          'outro(a) genitor(a) não é servidor(a) público(a) estadual, ' +
          'regido(a) pela Lei nº 1818/2007, ou de que no órgão de exercício ' +
          'não recebe benefício igual ou semelhante, tampouco o solicitou',
        idFile: '6',
      },
    ],
  },
  {
    id: 3,
    title: 'Auxílio Reclusão',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 4,
    title: 'Auxílio Salário Família',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de nascimento ou registro geral (RG) da criança',
      },
    ],
  },
  {
    id: 5,
    title: 'Auxílio Transporte',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento de Auxílio Transporte',
        idFile: '2483',
      },
    ],
    docs_info: [
      {
        id: 1,
        title: 'Ato - Auxílio Transporte',
        idFile: '2486',
      },
    ],
  },
];

export default auxiliosList;
