import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Data = {
  id: number;
  title: string;
  description: string;
  icon: IconProp;
  href: string;
};

const servicos: Data[] = [
  {
    id: 1,
    title: 'Férias',
    description: '',
    icon: 'umbrella-beach',
    href: '/pagina/ferias',
  },

  {
    id: 2,
    title: 'Auxílios',
    description: '',
    icon: 'edit',
    href: '/servicos/auxilio',
  },

  {
    id: 3,
    title: 'Contracheques',
    description: '',
    icon: 'money-check-alt',
    href: '/servicos/contracheque',
  },
  {
    id: 4,
    title: 'Licenças',
    description: '',
    icon: 'edit',
    href: '/servicos/licenca',
  },

  {
    id: 5,
    title: 'Fichas Financeiras',
    description: '',
    icon: 'money-check-alt',
    href: '/servicos/ficha-financeira',
  },
  {
    id: 6,
    title: 'Afastamentos',
    description: '',
    icon: 'edit',
    href: '/servicos/afastamento',
  },
];

export default servicos;
