import { Box } from '@mui/material';

import CardAniversariante from '../../../../components/CardAniversariante';
import { Aniversariante } from '../../../../types';

type AniversarianteMesProps = {
  aniversariantes: Aniversariante[];
};

export default function AniversariantesMes({
  aniversariantes,
}: AniversarianteMesProps) {
  return (
    <Box>
      {aniversariantes.length > 0 ? (
        <Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
              columnGap: 1,
              rowGap: 2,
            }}
          >
            {aniversariantes.map(aniversariante => (
              <CardAniversariante
                key={aniversariante.id}
                aniversariante={aniversariante}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          Nenhum aniversariante para exibir
        </Box>
      )}
    </Box>
  );
}
