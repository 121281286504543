import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MenuIconsMap = {
  [id: number | string]: JSX.Element;
};

const menuIconsMap: MenuIconsMap = {
  335: <FontAwesomeIcon icon="home-user" />, // "Institucional"
  324: <FontAwesomeIcon icon="play" />, // "A Defensoria"
  325: <FontAwesomeIcon icon="play" />, // "Organograma"
  326: <FontAwesomeIcon icon="play" />, // "Legislação"
  350: <FontAwesomeIcon icon="play" />, // "Comunicação"

  336: <FontAwesomeIcon icon="user-tie" />, // "Conselho Superior"
  345: <FontAwesomeIcon icon="play" />, // "Apresentação"
  346: <FontAwesomeIcon icon="play" />, // "Composição"
  348: <FontAwesomeIcon icon="play" />, // "Resoluções"
  349: <FontAwesomeIcon icon="play" />, // "Portarias"

  334: <FontAwesomeIcon icon="circle-check" />, // "Corregedoria"
  340: <FontAwesomeIcon icon="play" />, // "Apresentação"
  327: <FontAwesomeIcon icon="play" />, // "Probatório Defensores"
  328: <FontAwesomeIcon icon="play" />, // "Probatório Servidores"
  329: <FontAwesomeIcon icon="play" />, // "Atos"
  341: <FontAwesomeIcon icon="play" />, // "Recomendações"
  342: <FontAwesomeIcon icon="play" />, // "Resoluções"
  344: <FontAwesomeIcon icon="play" />, // "Portarias
  416: <FontAwesomeIcon icon="play" />, // "Estatística

  337: <FontAwesomeIcon icon="chalkboard-user" />, // "Esdep"

  338: <FontAwesomeIcon icon="lightbulb" />, // "Projetos"
  331: <FontAwesomeIcon icon="play" />, // "Banco de Projetos"
  332: <FontAwesomeIcon icon="play" />, // "Documentos"

  333: <FontAwesomeIcon icon="handshake" />, // "Parcerias"

  339: <FontAwesomeIcon icon="diagram-project" />, // "Fluxogramas"

  351: <FontAwesomeIcon icon="message" />, // "Publicações"
  352: <FontAwesomeIcon icon="play" />, // "Manuais PDF"
  353: <FontAwesomeIcon icon="play" />, // "Tutoriais em Vídeo"
  354: <FontAwesomeIcon icon="play" />, // "Jurisprudência"
  355: <FontAwesomeIcon icon="play" />, // "Banco de Petições"
  383: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Central de Honorários"
  384: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Civil"
  385: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Família"
  386: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Agrária"
  387: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Penal"
  388: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Direitos Humanos"
  389: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Infância e Juventude"
  390: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Consumidor"
  391: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Mulher"
  392: <FontAwesomeIcon icon="play" />, // "Banco de Petições > Saúde"
  380: <FontAwesomeIcon icon="play" />, // "Banco de Peças"
  393: <FontAwesomeIcon icon="play" />, // "Banco de Peças > DPagra"
  394: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nadep"
  395: <FontAwesomeIcon icon="play" />, // "Banco de Peças > NDDH"
  396: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nuamac's"
  397: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nucora"
  398: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nudeca"
  399: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nudecon"
  400: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nudem"
  401: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nujuri"
  402: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Numecon"
  403: <FontAwesomeIcon icon="play" />, // "Banco de Peças > Nusa"

  381: <FontAwesomeIcon icon="play" />, // "Banco de Relatórios"
  382: <FontAwesomeIcon icon="play" />, // "Banco de Núcleos"
  415: <FontAwesomeIcon icon="play" />, // "Racionalize"

  363: <FontAwesomeIcon icon="copy" />, // "Documentos"
  370: <FontAwesomeIcon icon="play" />, // "Organograma"
  371: <FontAwesomeIcon icon="play" />, // "Fluxogramas"
  372: <FontAwesomeIcon icon="play" />, // "Formulários"
  373: <FontAwesomeIcon icon="play" />, // "Cronograma Folha"
  374: <FontAwesomeIcon icon="play" />, // "Enunciados Institucionais"
  375: <FontAwesomeIcon icon="play" />, // "Papel Timbrado"
  376: <FontAwesomeIcon icon="play" />, // "Modelo de Apresentação"
  377: <FontAwesomeIcon icon="play" />, // "Marcas"

  364: <FontAwesomeIcon icon="mobile-screen-button" />, // "Contatos"

  365: <FontAwesomeIcon icon="heart" />, // "Hospital do Amor"
  366: <FontAwesomeIcon icon="play" />, // "Como doar"
  367: <FontAwesomeIcon icon="play" />, // "Cancelamento de Doação"
  368: <FontAwesomeIcon icon="play" />, // "Embaixadores"
  369: <FontAwesomeIcon icon="play" />, // "Unidade Palmas"
};

export default menuIconsMap;
