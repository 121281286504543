import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { Contato } from '../../../types';

type ContatoProps = {
  contato: Contato;
  sub_contato: boolean;
};

export default function SubContato(props: ContatoProps): JSX.Element {
  const { contato, sub_contato: subContato } = props;

  return (
    <ListItem
      key={contato?.id}
      alignItems="flex-start"
      sx={{
        border: 1,
        borderColor: 'grey.200',
        borderRadius: 1,
        py: 2,
        mb: 1,
      }}
    >
      <ListItemIcon>
        <FontAwesomeIcon icon={faLocationDot} size="2x" />
      </ListItemIcon>
      <ListItemText sx={{ color: 'text.secondary' }}>
        {contato?.has_sub_contatos && subContato ? (
          <Link
            component={RouterLink}
            to={`/contatos/${contato?.id}`}
            underline="hover"
          >
            {contato?.name}
          </Link>
        ) : (
          <Typography color="text.primary">{contato?.name}</Typography>
        )}
        <Box sx={{ display: 'flex', mt: 1 }}>
          {contato?.responsavel && (
            <>
              <FontAwesomeIcon icon={faUser} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {contato?.responsavel}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', mt: 1 }}>
          {contato?.phone && (
            <>
              <FontAwesomeIcon icon={faPhone} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {`Telefone: ${contato?.phone}`}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', mt: 1 }}>
          {contato?.email && (
            <>
              <FontAwesomeIcon icon={faEnvelope} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {`Email: ${contato?.email}`}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', mt: 1 }}>
          {contato?.address && (
            <>
              <FontAwesomeIcon icon={faLocationDot} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {`Endereço: ${contato?.address}`}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', mt: 1 }}>
          {contato?.site && (
            <>
              <FontAwesomeIcon icon={faLocationDot} />
              <Typography variant="body2" sx={{ mx: 1 }}>
                Site:
              </Typography>
              <Link
                component="a"
                variant="body2"
                href={contato?.site}
                underline="hover"
                target="_blank"
              >
                {contato?.site}
              </Link>
            </>
          )}
        </Box>
      </ListItemText>
    </ListItem>
  );
}
