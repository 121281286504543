import { createContext, useContext, useMemo, useState } from 'react';

type SidebarDrawerContext = {
  openSidebar: boolean;
  toggleSidebar: () => void;
};

const DEFAULT_STATE: SidebarDrawerContext = {
  openSidebar: false,
  toggleSidebar: () => null,
};

const SidebarDrawerContext = createContext<SidebarDrawerContext>(DEFAULT_STATE);

type SidebarDrawerProviderProps = {
  children: JSX.Element;
};

export function SidebarDrawerProvider({
  children,
}: SidebarDrawerProviderProps) {
  const [openSidebar, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(prevOpenSidebar => !prevOpenSidebar);
  };

  const value = useMemo(() => ({ openSidebar, toggleSidebar }), [openSidebar]);

  return (
    <SidebarDrawerContext.Provider value={value}>
      {children}
    </SidebarDrawerContext.Provider>
  );
}

export function useSidebarDrawer() {
  return useContext(SidebarDrawerContext);
}
