import { MouseEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../../context/AuthProvider';

import { exibirNome } from '../../../utils/exibirNome';
import { stringAvatar } from './PopoverUserAccount.helpers';
import { SERVIDOR_TIPOS_MAP } from './PopoverUserAccount.constants';

export default function PopoverUserAccount(): JSX.Element {
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    auth.signout();
  };

  const menuId = 'primary-search-account-popover';

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <Stack spacing={0.5} py={1} px={2} color="grey.700">
        <Typography sx={{ textTransform: 'capitalize' }}>
          {auth.servidor != null ? exibirNome(auth.servidor).toLowerCase() : ''}
        </Typography>

        <Typography>
          <Typography component="span" fontWeight="bold">
            Matrícula:
          </Typography>{' '}
          {auth.servidor?.matricula}
        </Typography>

        <Chip
          label={auth.servidor && SERVIDOR_TIPOS_MAP[auth.servidor.tipo]}
          variant="filled"
          color="primary"
          sx={{ alignSelf: 'start' }}
        />
      </Stack>

      <Divider sx={{ mb: 1 }} />

      <Box sx={{ p: 1 }}>
        <Link
          component={RouterLink}
          to="/usuarios/editar-foto"
          underline="hover"
        >
          Editar foto de perfil
        </Link>
      </Box>

      <Divider sx={{ mb: 1 }} />

      <MenuItem onClick={handleLogout} sx={{ minWidth: 200 }}>
        <Box sx={{ color: theme => theme.palette.grey[500] }}>
          <FontAwesomeIcon icon="sign-out" />
        </Box>

        <Typography component="span" sx={{ marginLeft: 1, color: 'grey.700' }}>
          Sair
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box color={theme => theme.palette.grey[500]}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Button
          type="button"
          startIcon={
            auth.servidor?.foto ? (
              <Avatar src={auth.servidor.foto} sx={{ width: 50, height: 50 }} />
            ) : (
              <FontAwesomeIcon
                icon="circle-user"
                style={{ width: 50, height: 50 }}
              />
            )
          }
          endIcon={<KeyboardArrowDownIcon />}
          size="large"
          aria-label="Conta do usuário atual"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          sx={{
            mr: 1,
            textTransform: 'lowercase',
            fontSize: '100%',
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          {auth.usuario?.username}
        </Button>
      </Box>
      <Box>
        <Button
          type="button"
          endIcon={<KeyboardArrowDownIcon />}
          size="large"
          aria-label="Conta do usuário atual"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          sx={{
            ml: -1,
            display: { xs: 'flex', sm: 'none' },
          }}
        >
          <Avatar {...stringAvatar(auth.servidor?.nome || '')} />
        </Button>
      </Box>
      {renderProfileMenu}
    </Box>
  );
}
