import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1400,
      xl: 1650,
    },
  },
  palette: {
    primary: {
      main: '#1BBC9B',
    },
    secondary: {
      main: '#0086CC',
      dark: '#065986',
    },
  },
});

export default theme;
