import axios from 'axios';

import {
  Ano,
  ComprovanteRendimento,
  Contracheque,
  FichaFinanceira,
  Folha,
  Mes,
  PessoaFisica,
} from '../types';

const athenasAPI = axios.create({
  baseURL: process.env.REACT_APP_ATHENAS_API_URL,
  headers: {
    Authorization: `token ${process.env.REACT_APP_ATHENAS_TOKEN}`,
  },
});

type ContrachequesResponse = {
  count: number;
  results: Contracheque[];
  anos?: Ano[];
  meses?: Mes[];
  folhas?: Folha[];
};

type FichasFinanceirasResponse = {
  count: number;
  results: FichaFinanceira[];
  anos?: Ano[];
  folhas?: Folha[];
};

type ComprovantesRendimentosResponse = {
  count: number;
  results: ComprovanteRendimento[];
};

type PessoaFisicaResponse = {
  count: number;
  results: PessoaFisica[];
};

const FolhaStatus = {
  FECHADA: 3,
};

export const getContracheques = async (
  cpf: string,
  offset: number,
  mes: string,
  ano: string,
  folha: string,
): Promise<ContrachequesResponse> => {
  const params = {
    cpf,
    status: FolhaStatus.FECHADA,
    dt_pagamento: new Date().toISOString().slice(0, 10),
    ano,
    mes,
    tipo_folha: folha,
    offset,
  };

  const response = await athenasAPI.get('contracheques', { params });

  const { results, count } = response.data as ContrachequesResponse;

  const data: Contracheque[] = results.map(item => {
    return {
      ...item,
      folha: {
        tipo_folha: {
          ...item.folha.tipo_folha,
          titulo: item.folha.tipo_folha.titulo.toLowerCase(),
        },
        periodo: {
          ...item.folha.periodo,
          mesTexto:
            item.folha.periodo.mes === 13
              ? 'décimo teceiro'
              : new Date(
                  Number(item.folha.periodo.ano),
                  Number(item.folha.periodo.mes - 1),
                  1,
                ).toLocaleDateString('pt-br', {
                  month: 'long',
                }),
        },
      },
    };
  });

  return { results: data, count };
};

export const getAllContracheques = async (
  cpf: string,
): Promise<ContrachequesResponse> => {
  const params = {
    cpf,
    status: FolhaStatus.FECHADA,
    dt_pagamento: new Date().toISOString().slice(0, 10),
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  };

  const response = await athenasAPI.get('contracheques', { params });

  const { results, count } = response.data as ContrachequesResponse;

  const data: Contracheque[] = results.map(item => {
    return {
      ...item,
      folha: {
        tipo_folha: {
          ...item.folha.tipo_folha,
          titulo: item.folha.tipo_folha.titulo.toLowerCase(),
        },
        periodo: {
          ...item.folha.periodo,
          mesTexto:
            item.folha.periodo.mes === 13
              ? 'décimo teceiro'
              : new Date(
                  Number(item.folha.periodo.ano),
                  Number(item.folha.periodo.mes - 1),
                  1,
                ).toLocaleDateString('pt-br', {
                  month: 'long',
                }),
        },
      },
    };
  });

  const meses = data
    .reduce((acc: Mes[], item) => {
      if (
        acc.find(mes => mes.id === item.folha.periodo.mes)?.id !==
        item.folha.periodo.mes
      ) {
        acc.push({
          id: item.folha.periodo.mes,
          label:
            item.folha.periodo.mes === 13
              ? 'décimo terceiro'
              : new Date(
                  Number(item.folha.periodo.ano),
                  Number(item.folha.periodo.mes - 1),
                  1,
                ).toLocaleDateString('pt-br', {
                  month: 'long',
                }),
        });
      }
      return acc;
    }, [])
    .sort((a, b) => +(a.id > b.id) || +(a.id === b.id) - 1);

  const anos = data.reduce((acc: Ano[], item) => {
    if (
      acc.find(ano => ano.id === item.folha.periodo.ano)?.id !==
      item.folha.periodo.ano
    ) {
      acc.push({
        id: item.folha.periodo.ano,
        label: String(item.folha.periodo.ano),
      });
    }
    return acc;
  }, []);

  const folhas = data.reduce((acc: Folha[], item) => {
    if (
      acc.find(folha => folha.id === item.folha.tipo_folha.id)?.id !==
      item.folha.tipo_folha.id
    ) {
      acc.push({
        id: item.folha.tipo_folha.id,
        label: item.folha.tipo_folha.titulo,
      });
    }
    return acc;
  }, []);

  return { results: data, count, meses, anos, folhas };
};

export const getAllFichasFinanceiras = async (
  cpf: string,
): Promise<FichasFinanceirasResponse> => {
  const params = {
    cpf,
    status: FolhaStatus.FECHADA,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  };

  const response = await athenasAPI.get('fichas-financeiras', { params });

  const { results, count } = response.data as FichasFinanceirasResponse;

  const data: FichaFinanceira[] = results.map(item => {
    return {
      ...item,
      folha: {
        ...item.folha,
        tipo_folha: {
          ...item.folha.tipo_folha,
          titulo: item.folha.tipo_folha.titulo.toLowerCase(),
        },
      },
    };
  });

  const anos = data.reduce((acc: Ano[], item) => {
    if (
      acc.find(ano => ano.id === item.folha.periodo.ano)?.id !==
      item.folha.periodo.ano
    ) {
      acc.push({
        id: item.folha.periodo.ano,
        label: String(item.folha.periodo.ano),
      });
    }
    return acc;
  }, []);

  const folhas = data.reduce((acc: Folha[], item) => {
    if (
      acc.find(folha => folha.id === item.folha.tipo_folha.id)?.id !==
      item.folha.tipo_folha.id
    ) {
      acc.push({
        id: item.folha.tipo_folha.id,
        label: item.folha.tipo_folha.titulo,
      });
    }
    return acc;
  }, []);

  return { results: data, count, anos, folhas };
};

export const getFichasFinanceiras = async (
  cpf: string,
  offset: number,
  ano: string,
  folha: string,
): Promise<FichasFinanceirasResponse> => {
  const params = {
    cpf,
    status: FolhaStatus.FECHADA,
    ano,
    tipo_folha: folha,
    offset,
  };

  const response = await athenasAPI.get('fichas-financeiras', { params });

  const { results, count } = response.data as FichasFinanceirasResponse;

  const data: FichaFinanceira[] = results.map(item => {
    return {
      ...item,
      folha: {
        ...item.folha,
        tipo_folha: {
          ...item.folha.tipo_folha,
          titulo: item.folha.tipo_folha.titulo.toLowerCase(),
        },
      },
    };
  });

  return { results: data, count };
};

export const getAllComprovantesRendimentos = async (
  cpf: string,
): Promise<ComprovantesRendimentosResponse> => {
  const pessoaFisica = await athenasAPI.get('pessoasfisicas/', {
    params: { cpf },
  });
  const responsePessoaFisica = pessoaFisica.data as PessoaFisicaResponse;
  const dataNascimento = responsePessoaFisica.results[0].data_nascimento;

  const params = {
    cpf,
    data_nascimento: dataNascimento,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  };

  const response = await athenasAPI.get('declaracoes-pessoafisica', { params });

  const { results, count } = response.data as ComprovantesRendimentosResponse;

  return { results, count };
};

export const getComprovantesRendimentos = async (
  cpf: string,
  offset: number,
): Promise<ComprovantesRendimentosResponse> => {
  const pessoaFisica = await athenasAPI.get('pessoasfisicas/', {
    params: { cpf },
  });
  const responsePessoaFisica = pessoaFisica.data as PessoaFisicaResponse;
  const dataNascimento = responsePessoaFisica.results[0].data_nascimento;

  const params = {
    cpf,
    data_nascimento: dataNascimento,
    offset,
  };

  const response = await athenasAPI.get('declaracoes-pessoafisica', { params });

  const { results, count } = response.data as ComprovantesRendimentosResponse;

  return { results, count };
};
