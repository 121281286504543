import { SxProps, Theme } from '@mui/material';

type StringAvatarType = {
  sx: SxProps<Theme>;
  children: string;
};

export function stringAvatar(name: string): StringAvatarType {
  return {
    sx: {
      bgcolor: theme => theme.palette.primary.main,
      width: 24,
      height: 24,
      fontSize: '1rem',
      mr: -0.5,
    },
    children: name
      .split(' ')
      .filter(item => item.length > 3)
      .map(item => item[0])
      .slice(0, 1)
      .join(''),
  };
}
