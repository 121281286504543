import { SyntheticEvent, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderPages from '../../../components/HeaderPages';

import AccordionServicos from '../AccordionServicos';
import auxiliosList from './Auxilios.constants';

export default function Auxilios(): JSX.Element {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/servicos"
          color="inherit"
          underline="hover"
        >
          Serviços
        </Link>

        <Typography color="secondary" fontWeight="bold">
          Auxílios Pecuniários
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Auxílios Pecuniários" />

      <Box component="section">
        <Typography component="p" mb={3}>
          Os auxílios são concedidos ao servidor titular de cargo de provimento
          efetivo ou estabilizado e à sua família, conforme a Lei nº 1818/2007 e
          Atos:
        </Typography>

        {auxiliosList.map(auxilio => (
          <AccordionServicos
            key={auxilio.id}
            data={auxilio}
            expanded={expanded}
            handleChange={handleChange}
          />
        ))}
      </Box>
    </Box>
  );
}
