import { Data } from '../types';

const licencasList: Data[] = [
  {
    id: 1,
    title: 'Maternidade',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Atestado Médico',
        idFile: '10',
      },
      {
        id: 3,
        title: 'Certidão de nascimento ou registro geral (RG) da criança',
      },
    ],
  },
  {
    id: 2,
    title: 'Maternidade (Antecipação a partir da 32ª semana)',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Atestado Médico',
        idFile: '10',
      },
      {
        id: 3,
        title: 'Ultrassonografia recente.',
      },
    ],
  },
  {
    id: 3,
    title: 'Maternidade (Prorrogação)',
    docs_download: [
      {
        id: 1,
        title: 'Certidão de nascimento ou registro geral (RG) da criança',
      },
      {
        id: 2,
        title:
          'Requerimento de Prorrogação de Licença Maternidade (Defensoras)',
        idFile: '333',
      },
      {
        id: 3,
        title:
          'Requerimento de Prorrogação de Licença Maternidade (Servidoras)',
        idFile: '332',
      },
    ],
  },
  {
    id: 4,
    title: 'Por tutoria ou adoção',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de nascimento ou registro geral (RG) da criança',
      },
      {
        id: 3,
        title: 'Documentos comprobatórios referente a adoção',
      },
    ],
  },
  {
    id: 5,
    title: 'Para tratamento da própria saúde',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Atestado Médico',
        idFile: '10',
      },
      {
        id: 3,
        title: 'Receituário de Medicação prescrita',
      },
      {
        id: 4,
        title: 'Resultado dos Exames Laboratoriais realizados',
      },
      {
        id: 5,
        title: 'Relatórios e Laudos de Exames que comprove a doença',
      },

      {
        id: 6,
        title:
          'Declaração Hospitalar com data de internação e alta, quando ocorrer',
      },
    ],
  },
  {
    id: 6,
    title: 'Por motivo de doença em pessoa da família',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Atestado Médico',
        idFile: '10',
      },
      {
        id: 3,
        title: 'Declaração de Acompanhante',
        idFile: '11',
      },
      {
        id: 4,
        title: 'Resultado dos Exames Laboratoriais realizados',
      },
      {
        id: 5,
        title: 'Relatórios e Laudos de Exames que comprove a doença',
      },
      {
        id: 6,
        title:
          'Comprovante de Parentesco conforme o vínculo familiar existente',
      },
      {
        id: 7,
        title:
          'Declaração Hospitalar com data de internação e alta, quando ocorrer',
      },
    ],
  },
  {
    id: 7,
    title: 'Por motivo de afastamento do cônjuge ou companheiro',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 8,
    title: 'Para atividade política',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 9,
    title: 'Para o serviço militar',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 10,
    title: 'Para capacitação ou especialização',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 11,
    title: 'Para tratar de interesses particulares',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
    ],
  },
  {
    id: 12,
    title: 'Para o Desempenho de Mandato Classista',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
];

export default licencasList;
