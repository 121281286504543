import { Box, Button, Typography } from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { getCracha, updateCracha } from '../../../services/portal';
import getFileFormat from '../../../utils/getFileFormat';
import FormularioEdicao from '../components/FormularioEdicao';

type FormInputs = {
  nome: string;
  cargo: string;
  foto: FileList | undefined;
  fotoBlob: Blob;
  cordao: boolean;
  justificativa: string;
  observacoes: string;
  status: string;
  solicitacao: string;
};

export default function CrachasEdit() {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data: cracha = null } = useQuery(['cracha', id], async () => {
    if (id != null) {
      return getCracha(id);
    }

    return null;
  });

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    if (id == null) {
      return;
    }

    const formData = new FormData();

    const foto = data.foto?.item(0);

    if (foto != null) {
      const fileName = data.nome
        .replace(/\s+/g, '_')
        .replace(/(\W)\1+/g, '$1')
        .toLowerCase();

      const fileFormat = getFileFormat(foto?.type || '');

      formData.append(
        'cracha[foto]',
        data.fotoBlob,
        `${fileName}_${new Date().valueOf()}.${fileFormat}`,
      );
    } else if (cracha != null && typeof cracha.filename === 'string') {
      const [fileName, fileFormat] = cracha.filename.split('.');

      const fileNameFormatted = `${fileName}_${new Date().valueOf()}.${fileFormat}`;

      formData.append('cracha[foto]', data.fotoBlob, `${fileNameFormatted}`);
    }

    formData.append('cracha[solicitacao]', cracha?.solicitacao || '');
    formData.append('cracha[nome]', data.nome);

    if (typeof data.cargo === 'string' && data.cargo !== '') {
      formData.append('cracha[cargo]', data.cargo);
    }

    formData.append('cracha[cordao]', data.cordao ? '1' : '0');

    const { data: crachaUpdated } = await updateCracha(id, formData);
    navigate(`/crachas/meus-crachas/${crachaUpdated.id}`);
  };

  if (cracha == null) {
    return <Box>Carregando...</Box>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h5">Editar crachá</Typography>

        <Button component={RouterLink} to="/crachas/meus-crachas">
          Ir para meus crachás
        </Button>
      </Box>

      <FormularioEdicao cracha={cracha} onSubmit={onSubmit} />
    </Box>
  );
}
