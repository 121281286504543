import { Box, Button, Grid, Typography } from '@mui/material';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { grey } from '@mui/material/colors';

import { useQuery } from '@tanstack/react-query';

import getIconeSistema from '../../utils/iconesSistemas';

import Breadcrumbs from '../../components/Breadcrumbs';
import HeaderPages from '../../components/HeaderPages';
import { getSistemas } from '../../services/portal';

export default function Sistemas(): JSX.Element {
  const { data: sistemas = [] } = useQuery(['sistemas'], async () => {
    const { data } = await getSistemas();
    return data;
  });

  return (
    <Box>
      <Box component="header" mb={3}>
        <Breadcrumbs>
          <Typography color="secondary" fontWeight="bold">
            Sistemas
          </Typography>
        </Breadcrumbs>
      </Box>

      <HeaderPages title="Sistemas" />

      <Box component="section" sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          {sistemas.map(sistema => (
            <Grid item xs={2} sm={4} md={4} key={sistema.name}>
              <Button
                variant="outlined"
                href={sistema.url}
                target="_blank"
                sx={{
                  textTransform: 'none',
                  borderRadius: '0',
                  borderColor: grey[400],
                  width: '180px',
                  height: '120px',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  '&:hover': {
                    bgcolor: theme => theme.palette.primary.main,
                    boxShadow: 'none',
                    borderRadius: '0',
                    color: 'white',
                  },
                }}
              >
                <Box textAlign="center">
                  <FontAwesomeIcon
                    icon={getIconeSistema(sistema.name) as IconProp}
                    size="lg"
                  />

                  <Typography>{sistema.name}</Typography>
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
