import { Box, Breadcrumbs, Link, List, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

import useScrollToTop from '../../hooks/useScrollToTop';
import { getContato } from '../../services/api';
import { Contato } from '../../types';
import SubContato from './SubContato';

type ParamsProps = {
  id: string | undefined;
};

export default function Contatos(): JSX.Element {
  const { id } = useParams<ParamsProps>();

  useScrollToTop();

  const { data: contato } = useQuery(['contato', id], async () => {
    if (typeof id === 'string' && id.trim() !== '') {
      const { data } = await getContato<Contato>(id);

      return data;
    }

    return null;
  });

  return (
    <Box>
      <Box component="header" mb={2} bgcolor="#f1f2f2" py={2} px={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/" color="inherit" underline="hover">
            Início
          </Link>
          <Link
            component={RouterLink}
            to="/contatos"
            color="inherit"
            underline="hover"
          >
            Contatos
          </Link>
          <Typography color="secondary" fontWeight="bold">
            {contato?.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box px={3} py={1}>
        <Typography fontStyle="italic" variant="body2">
          Conteúdo da página atualizado em junho/2023
        </Typography>
      </Box>
      <List>
        {contato && (
          <SubContato
            key={contato.id}
            contato={{ ...contato }}
            sub_contato={false}
          />
        )}
        {contato?.sub_contatos.map(subContato => (
          <SubContato key={subContato.id} contato={subContato} sub_contato />
        ))}
      </List>
    </Box>
  );
}
