import { Box, Typography } from '@mui/material';

type HeaderNoticiaProps = {
  title: string;
  createdAt?: string;
  author?: string;
};

export default function HeaderNoticia({
  title,
  createdAt,
  author,
}: HeaderNoticiaProps) {
  return (
    <Box component="header" mb={3} sx={{ bgcolor: '' }}>
      <Typography component="h2" fontWeight="bold" fontSize="1.5rem">
        {title}
      </Typography>

      {createdAt && (
        <Typography component="p" variant="body2" sx={{ mt: 2 }}>
          Publicado em {createdAt}
        </Typography>
      )}

      {author && (
        <Typography component="p" variant="body2">
          <Typography
            component="span"
            variant="body2"
            fontWeight="bold"
            mr={0.5}
          >
            Autor(a):
          </Typography>
          {author}
        </Typography>
      )}
    </Box>
  );
}
