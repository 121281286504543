import { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Backdrop, Box, Typography } from '@mui/material';

import { useAuth } from '../../context/AuthProvider';
import { exibirNome } from '../../utils/exibirNome';

export default function ModalCartao10AnosDefensoria() {
  const auth = useAuth();
  const navigate = useNavigate();

  const today = useMemo(() => new Date(), []);

  const keyInLocalStorage = `@portaldoconhecimento.displayModal10AnosDefensoria.${today.getFullYear()}`;

  const [showModal, setShowModal] = useState(false);

  const [displayModal, setDisplayModal] = useState(() => {
    const displayModalInLocalStorage = localStorage.getItem(keyInLocalStorage);

    return (
      displayModalInLocalStorage == null ||
      displayModalInLocalStorage === 'true'
    );
  });

  const handleClose = () => {
    setShowModal(false);
    setDisplayModal(false);

    localStorage.setItem(keyInLocalStorage, 'false');
  };

  const cartaoSrc = '/cartao_agradecimento_equipe_multidisciplinar.png';

  const [showNome, setShowNome] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    imageRef.current?.addEventListener('load', () => {
      setShowNome(true);
    });
  }, []);

  useEffect(() => {
    if (auth.servidor) {
      try {
        const lotacaoAtual = auth.servidor.lotacao_atual;

        if (lotacaoAtual.toLowerCase().includes('multidisciplinar')) {
          setShowModal(displayModal);
        }
      } catch {
        localStorage.removeItem(keyInLocalStorage);
        navigate('/');
      }
    }
  }, [auth.servidor, displayModal, keyInLocalStorage, navigate]);

  return (
    <Backdrop
      open={showModal}
      onClick={handleClose}
      sx={{
        color: 'white',
        bgcolor: 'rgba(0, 0, 0, .8)',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Box position="relative">
        <Box
          ref={imageRef}
          component="img"
          src={cartaoSrc}
          sx={{
            maxWidth: { xs: 350, sm: 550, md: 800 },
            height: 'auto',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            top: { xs: 25, sm: 30, md: 55 },
            left: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color="#04BFBF"
            component="p"
            textTransform="capitalize"
            lineHeight={1.15}
            sx={{
              maxWidth: '80%',
              fontFamily: 'Fredoka One',
              fontSize: {
                xs: 10,
                sm: 20,
                md: 27,
              },
              textAlign: 'center',
              display: showNome ? 'block' : 'none',
            }}
          >
            {auth.servidor != null ? exibirNome(auth.servidor) : ''}
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
}
