import {
  faEdit,
  faFileDownload,
  faFileLines as fileIcon,
  faFileUpload,
  faFolder as folderIcon,
  faFolderPlus,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useAuth } from '../../context/AuthProvider';
import { useConfirmDialog } from '../../context/ConfirmDialogProvider';
import useScrollToTop from '../../hooks/useScrollToTop';

import {
  deleteDocumento,
  deleteFolder,
  downloadFile,
  getFolder,
} from '../../services/portal';
import { DocumentosResponse } from '../../types';

import sortByName from '../../utils/sortByName';
import FormNameUpdateDialog from './FormNameUpdateDialog';

export default function Pasta() {
  const queryClient = useQueryClient();
  useScrollToTop();

  const confirmDialog = useConfirmDialog();

  const { id } = useParams<{ id: string }>();

  const { usuario } = useAuth();

  const { data: pasta } = useQuery(
    ['pasta', id],
    async () => {
      if (typeof id === 'string' && id.trim() !== '') {
        const { data } = await getFolder<DocumentosResponse>(id);
        return data;
      }

      return null;
    },
    {
      enabled: id != null && id !== '',
    },
  );

  const parentFolders = pasta?.folder.all_parents;

  const [errorDownloadingFile, setErrorDownloadingFile] = useState(false);

  const handleDeleteDocument = async (documentoId: number | string) => {
    confirmDialog.showConfirmDialog({
      title: 'Confirmação',
      message: 'Confirmar exclusão do documento?',
      onConfirm: async () => {
        await deleteDocumento(documentoId);
        queryClient.invalidateQueries(['pasta', id]);
      },
    });
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorDownloadingFile(false);
  };

  const [deleteDocumentoSuccess, setDeleteDocumentoSuccess] = useState(false);
  const handleSnackbarDeleteDocumentoClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleteDocumentoSuccess(false);
  };

  const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);

  const handleFormDialogClose = () => {
    setFormDialogIsOpen(false);
  };

  const [currentFolderForUpdate, setCurrentFolderForUpdate] = useState<
    number | string | null
  >(null);

  const [deleteFolderSuccess, setDeleteFolderSuccess] = useState(false);
  const handleSnackbarDeleteFolderClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleteFolderSuccess(false);
  };

  const handleDeleteFolder = async (folderId: number | string) => {
    confirmDialog.showConfirmDialog({
      title: 'Confirmação',
      message: 'Confirmar exclusão da pasta?',
      onConfirm: async () => {
        await deleteFolder(folderId);
        queryClient.invalidateQueries(['pasta', id]);
        setDeleteFolderSuccess(true);
      },
    });
  };

  return (
    <Box>
      <Snackbar
        open={errorDownloadingFile}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={handleSnackbarClose}>
          Erro ao baixar arquivo
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteDocumentoSuccess}
        autoHideDuration={6000}
        onClose={handleSnackbarDeleteDocumentoClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleSnackbarDeleteDocumentoClose}>
          Arquivo deletado com sucesso
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteFolderSuccess}
        autoHideDuration={6000}
        onClose={handleSnackbarDeleteFolderClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleSnackbarDeleteFolderClose}>
          Pasta deletada com sucesso
        </Alert>
      </Snackbar>

      <FormNameUpdateDialog
        key={currentFolderForUpdate}
        open={formDialogIsOpen}
        handleClose={handleFormDialogClose}
        onSuccess={() => {
          handleFormDialogClose();
          queryClient.invalidateQueries(['pasta', id]);
        }}
        folderId={currentFolderForUpdate}
      />

      <Breadcrumbs showHomeLink={false}>
        {parentFolders?.map(folder => (
          <Link
            key={folder.id}
            color="inherit"
            underline="hover"
            component={RouterLink}
            to={`/documents/folder/${folder.id}`}
          >
            {folder.name}
          </Link>
        ))}

        <Typography color="secondary" fontWeight="bold">
          {pasta?.folder.name}
        </Typography>
      </Breadcrumbs>

      {['admin', 'rh', 'editor'].includes(usuario?.role || '') && (
        <Box sx={{ px: { xs: 1, md: 3 } }}>
          <Button
            startIcon={<FontAwesomeIcon icon={faFileUpload} />}
            component={RouterLink}
            to={`/documents/folder/${pasta?.folder.id}/upload`}
          >
            Carregar um novo documento
          </Button>

          <Button
            startIcon={<FontAwesomeIcon icon={faFolderPlus} />}
            component={RouterLink}
            to={`/documents/folder/${pasta?.folder.id}/new`}
          >
            Criar uma nova pasta
          </Button>
        </Box>
      )}

      <List>
        {pasta?.pastas.sort(sortByName).map(diretorio => (
          <ListItem key={diretorio.id}>
            <ListItemButton
              component={RouterLink}
              to={`/documents/folder/${diretorio.id}`}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={folderIcon} size="2x" color="#189ce7" />
              </ListItemIcon>

              <ListItemText
                primary={diretorio.name}
                sx={{ mb: 0, color: 'primary.main' }}
              />
            </ListItemButton>

            {['admin', 'rh', 'editor'].includes(usuario?.role || '') && (
              <Box>
                <IconButton
                  onClick={() => {
                    setCurrentFolderForUpdate(diretorio.id);
                    setFormDialogIsOpen(true);
                  }}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="xs" />
                </IconButton>

                <IconButton onClick={() => handleDeleteFolder(diretorio.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                </IconButton>
              </Box>
            )}
          </ListItem>
        ))}
      </List>

      <List>
        {pasta?.documentos.sort(sortByName).map(documento => (
          <ListItem key={documento.id}>
            <ListItemButton
              onClick={async () => {
                try {
                  const blob = await downloadFile(documento.url);
                  FileSaver.saveAs(new Blob([blob]), documento.filename);
                } catch {
                  setErrorDownloadingFile(true);
                }
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={fileIcon}
                  size="2x"
                  color="rgb(99, 114, 130)"
                />
              </ListItemIcon>

              <ListItemText
                primary={documento.name}
                sx={{ mb: 0, color: 'primary.main' }}
              />
            </ListItemButton>

            <Stack direction="row">
              <IconButton
                title="Baixar arquivo"
                onClick={async () => {
                  try {
                    const blob = await downloadFile(documento.url);
                    FileSaver.saveAs(new Blob([blob]), documento.filename);
                  } catch {
                    setErrorDownloadingFile(true);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faFileDownload}
                  size="xs"
                  color="rgba(122, 122, 130)"
                />
              </IconButton>

              {['admin', 'rh', 'editor'].includes(usuario?.role || '') && (
                <>
                  <IconButton
                    component={RouterLink}
                    to={`/documents/${documento.id}/edit`}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      size="xs"
                      color="rgba(122, 122, 130)"
                    />
                  </IconButton>

                  <IconButton
                    onClick={() => handleDeleteDocument(documento.id)}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      size="xs"
                      color="rgba(122, 122, 130)"
                    />
                  </IconButton>
                </>
              )}
            </Stack>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
