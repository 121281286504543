import {
  AppBar,
  Box,
  Container,
  IconButton,
  Modal,
  Toolbar,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { grey } from '@mui/material/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSearchModal } from '../../context/SearchModalProvider';
import { useSidebarDrawer } from '../../context/SidebarDrawerProvider';

import Logo from '../Logo';

import PopoverEmail from './PopoverEmail';
import PopoverUserAccount from './PopoverUserAccount';
import SearchBar from './Search';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Navbar(): JSX.Element {
  const { toggleSidebar } = useSidebarDrawer();

  const { openModal, toggleModal } = useSearchModal();

  return (
    <Box component="header" displayPrint="none" sx={{ bgcolor: grey[50] }}>
      <Container disableGutters>
        <Box
          sx={{
            borderBottom: '1px solid #d4e4e4',
            borderRight: '1px solid #d4e4e4',
            px: { xs: 1, sm: 1, md: 0 },
          }}
        >
          <Container disableGutters>
            <AppBar position="static" color="inherit" elevation={0}>
              <Toolbar
                disableGutters
                sx={{ minHeight: 'auto', p: { xs: 1, md: 0 } }}
              >
                {/* logo */}
                <Logo
                  filepng="/logo.png"
                  filesvg="/logo.svg"
                  maxWidth="18rem"
                  height={72}
                  sx={{ display: { xs: 'none', lg: 'flex' } }}
                />
                {/* button SidebarDrawer */}
                <IconButton
                  title="Menu Principal"
                  color="inherit"
                  size="medium"
                  onClick={toggleSidebar}
                  sx={{
                    width: 35,
                    height: 35,
                    color: theme => theme.palette.grey[500],
                    display: { sm: 'flex', lg: 'none' },
                  }}
                >
                  <FontAwesomeIcon icon="bars" />
                </IconButton>
                {/* icon para mobile */}
                <Logo
                  filepng="/favicon.png"
                  filesvg="/favicon.svg"
                  maxWidth="2rem"
                  pt={1}
                  ml={1}
                  sx={{ display: { xs: 'flex', sm: 'none' } }}
                />
                {/* input de pesquisa */}
                <Box
                  display="flex"
                  sx={{
                    flexGrow: 1,
                    mx: { xs: 2, md: 0 },
                    px: 1,
                    display: { xs: 'none', sm: 'flex' },
                  }}
                >
                  <SearchBar />
                </Box>

                <Box
                  display="flex"
                  color="grey.500"
                  justifyContent="end"
                  sx={{
                    flexGrow: 1,
                    mx: { xs: -1, md: 0 },
                    px: 1,
                    display: { xs: 'flex', sm: 'none' },
                  }}
                >
                  <IconButton
                    color="inherit"
                    size="medium"
                    edge="end"
                    onClick={toggleModal}
                    sx={{ mr: 1, width: 35, height: 35 }}
                  >
                    <FontAwesomeIcon icon="search" fontSize="1.25rem" />
                  </IconButton>
                  <Modal
                    open={openModal}
                    onClose={toggleModal}
                    aria-labelledby="Barra de Pesquisa"
                    aria-describedby="Pesquisar no portal"
                    sx={{ display: { xs: 'flex', sm: 'none' } }}
                  >
                    <Box sx={style}>
                      <Box
                        color="grey.600"
                        display="flex"
                        justifyContent="end"
                        mt={-3}
                        mb={2}
                      >
                        <IconButton
                          color="inherit"
                          size="medium"
                          edge="end"
                          onClick={toggleModal}
                          sx={{ width: 35, height: 35 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <SearchBar />
                    </Box>
                  </Modal>
                </Box>
                {/* Popover e-mail e user */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  sx={{
                    width: { md: '100%' },
                    maxWidth: { md: '19rem' },
                  }}
                >
                  <PopoverEmail />
                  <PopoverUserAccount />
                </Box>
              </Toolbar>
            </AppBar>
          </Container>
        </Box>
      </Container>
    </Box>
  );
}
