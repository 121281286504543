import { Box } from '@mui/material';

import CardAniversariante from '../../../../components/CardAniversariante';
import { Aniversariante } from '../../../../types';
import { useEffect, useRef, useState } from 'react';

type AniversarianteDiaProps = {
  aniversariantes: Aniversariante[];
};

const CONTAINER_LINES = 3;
const CONTAINER_HEIGHT_OFFSET = 30;

export default function AniversariantesDia({
  aniversariantes,
}: AniversarianteDiaProps) {
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (cardContainerRef.current != null) {
      setContainerHeight(
        cardContainerRef.current.offsetHeight * CONTAINER_LINES +
          CONTAINER_HEIGHT_OFFSET,
      );
    }
  }, []);

  return (
    <Box>
      {aniversariantes.length > 0 ? (
        <Box
          sx={{ maxHeight: `${containerHeight}px`, p: 0.5, overflow: 'auto' }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
              gap: 1,
            }}
          >
            {aniversariantes.map(aniversariante => (
              <CardAniversariante
                key={aniversariante.id}
                ref={cardContainerRef}
                aniversariante={aniversariante}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          Nenhum aniversariante para exibir
        </Box>
      )}
    </Box>
  );
}
