import cores from './CardAniversariante.contants';

export default function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: cores[Math.floor(Math.random() * cores.length)],
      width: '70px',
      height: '70px',
    },
    children: name,
  };
}
