import { Box, Typography } from '@mui/material';

type PagesHeaderProps = {
  title: string;
};

export default function PagesHeader({ title }: PagesHeaderProps) {
  return (
    <Box component="header" mb={3}>
      <Typography component="h2" fontWeight="bold" fontSize="1.5rem">
        {title}
      </Typography>
    </Box>
  );
}
