import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBars,
  faBrazilianRealSign,
  faCalendarCheck,
  faCalendarDay,
  faCertificate,
  faChalkboardUser,
  faCircleCheck,
  faCircleDot,
  faCircleQuestion,
  faCircleUser,
  faCloud,
  faCopy,
  faDesktop,
  faDiagramProject,
  faEdit,
  faEnvelope,
  faEnvelopeCircleCheck,
  faFileExport,
  faFileLines,
  faFilePdf,
  faFileWord,
  faGroupArrowsRotate,
  faHandHoldingHand,
  faHandshake,
  faHeart,
  faHome,
  faHomeUser,
  faIdCard,
  faKeyboard,
  faLandMineOn,
  faLightbulb,
  faMessage,
  faMicrophone,
  faMobileScreenButton,
  faMoneyBill,
  faMoneyCheckAlt,
  faPaste,
  faPeopleRoof,
  faPlay,
  faSearch,
  faSignal,
  faSignOut,
  faSun,
  faUmbrellaBeach,
  faUserGear,
  faUsersRectangle,
  faUserTie,
  faXmark,
  fas,
} from '@fortawesome/free-solid-svg-icons';

import AppRouter from './routes';

library.add(fas);

// Ícones dos sistemas
library.add(
  faBrazilianRealSign, // IRRF
  faCalendarCheck, // Agenda
  faCertificate, // Odin
  faCircleQuestion, // Ícone não definido
  faCloud, // DefCloud
  faDesktop, // SEI
  faEnvelopeCircleCheck, // Webmail
  faFileExport, // Box
  faFileLines, // Diário
  faGroupArrowsRotate, // Serviços
  faHandHoldingHand, // Plantão
  faKeyboard, // CMS
  faLandMineOn, // SISREF
  faMicrophone, // Eventos
  faMobileScreenButton, // Luna
  faMoneyBill, // ECONSIG
  faPaste, // Postify
  faPeopleRoof, // Hermes
  faSun, // Solar
  faUserGear, // Athenas
  faUsersRectangle, // Saed
);

library.add(
  faCalendarDay,
  faSearch,

  // Ícones dos serviços
  faEdit,
  faFilePdf,
  faFileWord,
  faMoneyCheckAlt,
  faSignal,
  faUmbrellaBeach,
  faIdCard,

  // icons navbar
  faBars,
  faCircleDot,
  faCircleUser,
  faEnvelope,
  faSignOut,

  // icons Menu
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faChalkboardUser,
  faCircleCheck,
  faCopy,
  faDiagramProject,
  faHandshake,
  faHeart,
  faHome,
  faHomeUser,
  faLightbulb,
  faMessage,
  faPlay,
  faUserTie,

  // icon formulario
  faXmark,
);

export default function App(): JSX.Element {
  return <AppRouter />;
}
