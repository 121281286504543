import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Breadcrumbs from '../../components/Breadcrumbs';
import HeaderPages from '../../components/HeaderPages';

import servicos from './Servicos.constants';

export default function Servicos(): JSX.Element {
  return (
    <Box>
      <Box component="header" mb={3}>
        <Breadcrumbs>
          <Typography color="secondary" fontWeight="bold">
            Serviços
          </Typography>
        </Breadcrumbs>
      </Box>

      <HeaderPages title="Serviços" />

      <Box component="section" sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          {servicos.map(servico => (
            <Grid item xs={2} sm={4} md={4} key={servico.id}>
              <Button
                variant="outlined"
                component={RouterLink}
                to={servico.href}
                sx={{
                  textTransform: 'none',
                  borderRadius: '0',
                  borderColor: 'grey.400',
                  width: '180px',
                  height: '120px',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    boxShadow: 'none',
                    borderRadius: '0',
                    color: 'white',
                  },
                }}
              >
                <Box textAlign="center">
                  <FontAwesomeIcon icon={servico.icon} size="lg" />
                  <Typography>{servico.title}</Typography>
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
