import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  IconButton,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import FileSaver from 'file-saver';

import { downloadFile, getDocumento } from '../../services/portal';

type Documento = {
  id: number;
  filename: string;
  folder_id: number;
  name: string;
  description: string;
  url: string;
};

type ParamsProps = {
  id: string | undefined;
};

export default function Document(): JSX.Element {
  const { id } = useParams<ParamsProps>();
  const [documento, setDocumento] = useState<Documento>();
  const [errorDownloadingFile, setErrorDownloadingFile] = useState(false);
  const [open, setOpen] = useState(true);

  const handleDialogClose = () => {
    setOpen(false);
    window.close();
  };

  useEffect(() => {
    const fetchDocumento = async () => {
      if (typeof id === 'string' && id.trim() !== '') {
        const { data } = await getDocumento<Documento>(id);
        setDocumento(data);
      }
    };

    fetchDocumento();
  }, [id]);

  useEffect(() => {
    const download = async () => {
      if (documento !== undefined) {
        try {
          const blob = await downloadFile(documento.url);
          FileSaver.saveAs(new Blob([blob]), documento.filename);
        } catch {
          setErrorDownloadingFile(true);
        }
      }
    };

    window.addEventListener('focusout', () => handleDialogClose());
    download();
  }, [documento]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        px: 3,
      }}
    >
      <Box>
        <DialogActions>
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>

        <DialogContentText sx={{ display: 'flex', m: 'auto', mb: 2, mx: 2 }}>
          {errorDownloadingFile ? (
            <Typography component="span" sx={{ mt: 1 }}>
              Documento não existe...
            </Typography>
          ) : (
            <Typography component="span" sx={{ mt: 1 }}>
              Baixando documento...
            </Typography>
          )}
        </DialogContentText>
      </Box>
    </Dialog>
  );
}
