import {
  faIdCard,
  faUserGroup,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Box,
  Button,
  Link as MuiLink,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';

import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import PagesHeader from '../../components/HeaderPages';
import { useAuth } from '../../context/AuthProvider';

const buttonLinkSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  textTransform: 'none',
  borderRadius: '0',
  borderColor: grey[400],
  width: '180px',
  height: '120px',
  maxWidth: '100%',
  maxHeight: '100%',
  '&:hover': {
    backgroundColor: 'primary.main',
    boxShadow: 'none',
    color: 'white',
  },
};

export default function Administracao(): JSX.Element {
  const { usuario } = useAuth();

  return (
    <Box>
      <Breadcrumbs>
        <MuiLink
          component={RouterLink}
          to="/admin"
          color="inherit"
          underline="hover"
        >
          Administração
        </MuiLink>
      </Breadcrumbs>

      <PagesHeader title="Administração" />

      {usuario?.role === 'comum' ? (
        <Alert severity="warning">
          Você não está autorizado a acessar esta área
        </Alert>
      ) : (
        <Box display="flex" sx={{ gap: 1 }}>
          {['admin', 'rh'].includes(usuario?.role || '') && (
            <Button
              key="card-crachas"
              variant="outlined"
              component={RouterLink}
              to="/admin/crachas/analisar"
              sx={buttonLinkSx}
            >
              <FontAwesomeIcon icon={faIdCard} />
              <Typography>Crachás</Typography>
            </Button>
          )}

          {usuario?.role === 'admin' && (
            <>
              <Button
                key="card-usuarios"
                variant="outlined"
                component={RouterLink}
                to="/admin/usuarios"
                sx={buttonLinkSx}
              >
                <FontAwesomeIcon icon={faUsers} />
                <Typography>Usuários</Typography>
              </Button>

              <Button
                key="card-servidores"
                variant="outlined"
                component={RouterLink}
                to="/admin/servidores"
                sx={buttonLinkSx}
              >
                <FontAwesomeIcon icon={faUserGroup} />
                <Typography>Servidores</Typography>
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
