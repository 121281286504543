import { createContext, useContext, useState } from 'react';

type ServidorTipos =
  | 'defensor'
  | 'estagiario'
  | 'jovem_trabalhador'
  | 'servidor'
  | 'terceirizado'
  | 'voluntario';

export type Servidor = {
  id: number;
  nome: string;
  nome_social: string;
  email: string;
  matricula: string;
  cpf: string;
  vinculo: string;
  tipo: ServidorTipos;
  lotacao_atual: string;
  ativo: boolean;
  data_nascimento: string;
  aniversariante: boolean;
  cargo: string;
  comarca: string;
  usuario_id: number | null;
  foto: string | null;
};

export type Usuario = {
  id: number;
  email: string;
  sign_in_count: number;
  username: string;
  cpf: string;
  active: boolean;
  role: 'comum' | 'admin' | 'rh';
  servidor: Servidor | null;
};

type AuthType = {
  token: string;
  usuario: Usuario | null;
  servidor: Servidor | null;
};

type AuthStates = 'idle' | 'authenticated' | 'unauthenticated' | 'expired';

type AuthContextType = AuthType & {
  status: AuthStates;
  signin: (args: {
    token: string;
    usuario: Usuario;
    servidor: Servidor;
    callback?: VoidFunction;
  }) => void;
  signout: (args?: { authStatus: AuthStates; callback?: VoidFunction }) => void;
  updateProfilePhoto: (newSrc: string) => void;
};

const INITIAL_STATE: AuthContextType = {
  token: '',
  usuario: null,
  servidor: null,
  status: 'idle',
  signin: () => null,
  signout: () => null,
  updateProfilePhoto: () => null,
};

const AuthContext = createContext<AuthContextType>(INITIAL_STATE);

type AuthProviderProps = {
  children: React.ReactNode;
};

function useAuthProvider(): AuthContextType {
  const [auth, setAuth] = useState<AuthType>(INITIAL_STATE);
  const [status, setStatus] = useState<AuthStates>('idle');

  const signin = ({
    token,
    usuario,
    servidor,
    callback,
  }: AuthType & { callback?: VoidFunction }) => {
    setAuth({
      token,
      usuario,
      servidor,
    });

    setStatus('authenticated');

    if (typeof callback !== 'undefined') {
      callback();
    }
  };

  const signout = (
    {
      authStatus,
      callback,
    }: {
      authStatus: AuthStates;
      callback?: VoidFunction;
    } = { authStatus: 'unauthenticated' },
  ) => {
    setStatus(authStatus);

    setAuth({
      token: '',
      usuario: null,
      servidor: null,
    });

    if (typeof callback !== 'undefined') {
      callback();
    }
  };

  function updateProfilePhoto(newSrc: string) {
    setAuth(prevAuth => ({
      ...prevAuth,
      servidor:
        prevAuth.servidor != null
          ? { ...prevAuth.servidor, foto: newSrc }
          : null,
    }));
  }

  return {
    ...auth,
    status,
    signin,
    signout,
    updateProfilePhoto,
  };
}

export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useAuthProvider();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
