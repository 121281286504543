import { MutableRefObject } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material';

import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';

export type GalleryImage = {
  id: number | string;
  url: string;
  author?: string;
  description?: string;
  short_description: string;
  imagem_capa: boolean;
};

type ImageGalleryProps = {
  images: GalleryImage[];
};

export default function ImageGallery({ images }: ImageGalleryProps) {
  return (
    <Gallery>
      {images.map(image => (
        <Item
          key={image.id}
          content={
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                  maxWidth: { xs: '100%', md: 800, lg: 1200 },
                },
              }}
            >
              <Box
                component="img"
                src={image.url}
                alt={image.short_description}
                sx={{
                  objectFit: 'contain',
                  aspectRatio: '15 / 10',
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                }}
              >
                <Typography textAlign="center" color="white">
                  {image.short_description}
                </Typography>

                {!image.imagem_capa && image.author && (
                  <Typography textAlign="center" color="white">
                    <strong>Foto:</strong> {image.author}
                  </Typography>
                )}
              </Box>
            </Box>
          }
        >
          {({ open, ref }) => (
            <Card
              ref={ref as MutableRefObject<HTMLDivElement>}
              onClick={open}
              sx={{
                maxWidth: { xs: 600, md: 350 },
                '& .MuiCardContent-root:last-child': { pb: 1 },
              }}
            >
              {image.imagem_capa && image.author && (
                <CardHeader
                  sx={{ p: 1 }}
                  subheader={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontStyle="italic"
                      textAlign="right"
                    >
                      <strong>Foto:</strong> {image.author}
                    </Typography>
                  }
                />
              )}

              <CardMedia
                component="img"
                src={image.url}
                alt={image.short_description}
                sx={{
                  objectFit: 'contain',
                  width: '100%',
                  aspectRatio: '15 / 10',
                  cursor: 'pointer',
                }}
              />

              {image.short_description && (
                <CardContent sx={{ py: 1, px: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {image.short_description}
                  </Typography>
                </CardContent>
              )}

              {!image.imagem_capa && image.author && (
                <CardContent sx={{ py: 1, px: 1 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontSize="10px"
                    mb={1}
                  >
                    <strong>Foto:</strong> {image.author}
                  </Typography>
                </CardContent>
              )}
            </Card>
          )}
        </Item>
      ))}
    </Gallery>
  );
}
