import axios, { AxiosResponse } from 'axios';
import { Servidor, Usuario } from '../context/AuthProvider';
import { Cracha, Sistema } from '../types';

type PortalAPIResponseType = [];

type Webmail = {
  username: string;
  preauth_key: string | undefined;
};

type Pagy = {
  page: number;
  last: number;
  items: number;
};

type PortalPaginatedResponse<T> = {
  pagy: Pagy;
  results: T[];
};

const portalAPI = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export function setAuthorizationToken(token: string) {
  portalAPI.defaults.headers.common.Authorization = `Token ${token}`;
}

type AuthTokenResponse = {
  token: string;
  usuario: Usuario;
  servidor: Servidor;
};

export async function getToken(code: string) {
  try {
    const { data } = await axios.post<AuthTokenResponse>(
      `${process.env.REACT_APP_PORTAL_API_URL}/auth/token`,
      { code },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );

    return data;
  } catch (err) {
    console.error(err);
  }
}

export function getSistemas(
  username?: string,
): Promise<AxiosResponse<Sistema[]>> {
  return portalAPI.get('/applications', {
    params: {
      username,
    },
  });
}

export const getServidores = (params?: Record<string, unknown>) => {
  return portalAPI.get<PortalPaginatedResponse<Servidor>>('/servidores', {
    params,
  });
};

export const getServidor = (id: number | string) => {
  return portalAPI.get<Servidor>(`/servidores/${id}`);
};

export const updateServidor = (
  id: number | string,
  servidor: Omit<Servidor, 'id'>,
) => {
  const data = {
    ...servidor,
    aniversariante: servidor.aniversariante ? '1' : '0',
  };

  return portalAPI.put<Usuario>(`/servidores/${id}`, { servidor: data });
};

export const deleteServidor = <U = PortalAPIResponseType>(
  id: number | string,
): Promise<AxiosResponse<U>> => {
  return portalAPI.delete(`/servidores/${id}`);
};

export const getAniversariantes = <T = PortalAPIResponseType>(params: {
  periodo: string;
}): Promise<AxiosResponse<T>> => {
  return portalAPI.get('/aniversariantes', { params });
};

export const getAuthenticatedUrl = <T = PortalAPIResponseType>(
  webmail: Webmail,
): Promise<AxiosResponse<T>> => {
  return portalAPI.post('/webmail/authenticated_url', webmail);
};

export const getUnreadInbox = <T = PortalAPIResponseType>(
  webmail: Webmail,
): Promise<AxiosResponse<T>> => {
  return portalAPI.post('/webmail/unread_inbox', webmail);
};

export const getRemainingMonthEvents = <T = PortalAPIResponseType>(
  webmail: Webmail,
): Promise<AxiosResponse<T>> => {
  return portalAPI.post('/webmail/remaining_month_events', webmail);
};

export const getTodayEvents = <T = PortalAPIResponseType>(
  webmail: Webmail,
): Promise<AxiosResponse<T>> => {
  return portalAPI.post('/webmail/today_events', webmail);
};

export const getFolders = <T = PortalAPIResponseType>(): Promise<
  AxiosResponse<T>
> => {
  return portalAPI.get('/folders');
};

export const getDocumentos = <T = PortalAPIResponseType>(): Promise<
  AxiosResponse<T>
> => {
  return portalAPI.get('/documents');
};

export const updateDocumento = <T, U = PortalAPIResponseType>(
  id: number | string,
  documento: T,
): Promise<AxiosResponse<U>> => {
  return portalAPI.putForm(`/documents/${id}`, documento);
};

export const uploadDocumento = <T, U = PortalAPIResponseType>(
  documento: T,
): Promise<AxiosResponse<U>> => {
  return portalAPI.postForm('/documents', documento);
};

export const deleteDocumento = <U = PortalAPIResponseType>(
  id: number | string,
): Promise<AxiosResponse<U>> => {
  return portalAPI.delete(`/documents/${id}`);
};

export const getDocumento = <T = PortalAPIResponseType>(
  id: number | string,
): Promise<AxiosResponse<T>> => {
  return portalAPI.get(`/documents/${id}`);
};

export const newFolder = <T, U = PortalAPIResponseType>(
  folder: T,
): Promise<AxiosResponse<U>> => {
  return portalAPI.post('/folders', folder);
};

export const deleteFolder = <U = PortalAPIResponseType>(
  id: number | string,
): Promise<AxiosResponse<U>> => {
  return portalAPI.delete(`/folders/${id}`);
};

export const getFolder = <T = PortalAPIResponseType>(
  id: number | string,
): Promise<AxiosResponse<T>> => {
  return portalAPI.get(`/documents/folder/${id}`);
};

export const updateFolderName = (
  folderId: number | string,
  newName: string,
) => {
  const payload = {
    folder: {
      name: newName,
    },
  };

  return portalAPI.put<string>(`/folders/${folderId}`, payload);
};

export const downloadFile = async (url: string) => {
  const { data } = await axios.get<ArrayBuffer>(url, {
    responseType: 'arraybuffer',
  });

  return data;
};

export const sendEmail = <T, U = PortalAPIResponseType>(
  email: T,
): Promise<AxiosResponse<U>> => {
  return portalAPI.post('/sendmail/communication', email);
};

export const getNovoCracha = async () => {
  const { data } = await portalAPI.get<{ data: Cracha }>('/crachas/new');
  return data;
};

export const getCracha = async (id: number | string) => {
  const { data } = await portalAPI.get<Cracha>(`/crachas/${id}`);
  return data;
};

export const getCrachas = (
  params?: Record<string, string | number | undefined>,
) => {
  return portalAPI.get<{ results: Cracha[]; pagy: Pagy }>('/crachas', {
    params,
  });
};

export const getMeusCrachas = (
  params?: Record<string, string | number | undefined>,
) => {
  return portalAPI.get<{ results: Cracha[]; pagy: Pagy }>(
    '/crachas/meus-crachas',
    {
      params,
    },
  );
};

export const createCracha = (cracha: FormData) => {
  return portalAPI.postForm<Cracha>('/crachas', cracha);
};

export const updateCracha = (
  id: number | string,
  cracha: Partial<Omit<Cracha, 'id'>> | FormData,
) => {
  if (cracha instanceof FormData) {
    return portalAPI.putForm<Cracha>(`/crachas/${id}`, cracha);
  } else {
    return portalAPI.put<Cracha>(`/crachas/${id}`, cracha);
  }
};

export const getUsuarios = (
  params?: Record<string, string | number | undefined>,
) => {
  return portalAPI.get<PortalPaginatedResponse<Usuario>>('/usuarios', {
    params,
  });
};

export const getUsuario = (id: number | string) => {
  return portalAPI.get<Usuario>(`/usuarios/${id}`);
};

export const updateUsuario = (
  id: number | string,
  usuario: Omit<Usuario, 'id' | 'active'> & { active: string },
) => {
  return portalAPI.put<Usuario>(`/usuarios/${id}`, { usuario });
};

export const deleteUsuario = <U = PortalAPIResponseType>(
  id: number | string,
): Promise<AxiosResponse<U>> => {
  return portalAPI.delete(`/usuarios/${id}`);
};

export const updateProfilePhoto = (data: FormData) => {
  return portalAPI.putForm<Servidor>('/profile/update_photo', data);
};

export interface QuantitativosCrachas {
  analisar: number;
  analisado: number;
  impresso: number;
  recebido: number;
  recusado: number;
  cancelado: number;
}

export async function getQuantitativos(
  dataInicial: string,
  dataFinal: string,
): Promise<QuantitativosCrachas> {
  const response = await portalAPI.get(
    'relatorios/crachas_quantitativos_mes_atual',
    {
      params: {
        data_inicial: dataInicial,
        data_final: dataFinal,
      },
    },
  );

  return response.data;
}
