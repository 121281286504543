import { useEffect, useState } from 'react';

import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQuery } from '@tanstack/react-query';

import {
  chronusApi,
  postRelatorio,
  RelatorioResponse,
} from '../../services/api';

const MAX_TRIES = 5;

type ButtonReportProps = IconButtonProps & {
  app: string;
  name: string;
  user: string;
  resource: string;
  params: object;
  fileName: string;
};

async function getReport(reportId: number) {
  return chronusApi.get(`reports/${reportId}.json`);
}

export default function ButtonReport({
  app,
  name,
  user,
  resource,
  params,
  fileName,
}: ButtonReportProps): JSX.Element {
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);

  const [tries, setTries] = useState(0);

  useQuery(
    ['report-contracheques', reportId],
    async () => {
      if (reportId != null) {
        setLoading(true);
        setTries(prev => prev + 1);

        const response = await getReport(reportId);

        const { data } = response as RelatorioResponse;

        return data;
      }

      return null;
    },
    {
      enabled: reportId != null && reportId !== 0 && tries < MAX_TRIES,
      refetchInterval: 3000,
      onSuccess: data => {
        if (data?.status === 'done') {
          setLoading(false);
          setFile(data.file);
          setReportId(null);
        } else if (data?.status === 'failed') {
          setLoading(false);
        }
      },
    },
  );

  const handleDownloadReport = async () => {
    const report = { app, name, user, resource, params };

    const idReport = await postRelatorio({ report });

    setTries(0);
    setReportId(idReport);
  };

  useEffect(() => {
    if (file !== '') {
      fetch(file, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));

          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', `${fileName}.pdf`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  }, [file, fileName]);

  return (
    <IconButton onClick={handleDownloadReport}>
      {loading ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        <FontAwesomeIcon icon="file-pdf" />
      )}
    </IconButton>
  );
}
