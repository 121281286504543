import {
  faArchway,
  faBuilding,
  faFolder,
  faGavel,
  faGraduationCap,
  faHandshakeSimple,
  faLink,
  faLocationDot,
  faUsersLine,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const gruposContatosMap = [
  {
    id: 1,
    nome: 'Defensoria Pública no Estado',
    descricao: 'Encontre a Defensoria mais próxima de você',
    icone: <FontAwesomeIcon icon={faLocationDot} size="2x" />,
  },
  {
    id: 2,
    nome: 'Diretorias Administrativas',
    descricao: 'Saiba os contatos administrativos da DPE',
    icone: <FontAwesomeIcon icon={faBuilding} size="2x" />,
  },
  {
    id: 3,
    nome: 'Núcleos Especializados',
    descricao: 'Encontre o contato de um núcleo da DPE',
    icone: <FontAwesomeIcon icon={faGavel} size="2x" />,
  },
  {
    id: 60,
    nome: 'Núcleos de Mediação e Conciliação',
    descricao: 'Encontre o contato de um núcleo de mediação e conciliação',
    icone: <FontAwesomeIcon icon={faHandshakeSimple} size="2x" />,
  },
  {
    id: 4,
    nome: 'Corregedoria',
    descricao: 'Contatos da Corregedoria Geral da Defensoria',
    icone: <FontAwesomeIcon icon={faFolder} size="2x" />,
  },
  {
    id: 5,
    nome: 'Gabinete',
    descricao: 'Saiba como entrar em contato com o gabinte',
    icone: <FontAwesomeIcon icon={faArchway} size="2x" />,
  },
  {
    id: 265,
    nome: 'Links Úteis',
    descricao: 'Saiba alguns contatos úteis',
    icone: <FontAwesomeIcon icon={faLink} size="2x" />,
  },
  {
    id: 277,
    nome: 'Conselho Superior',
    descricao: 'Saiba como entrar em contato com o Conselho Superior',
    icone: <FontAwesomeIcon icon={faUsersLine} size="2x" />,
  },
  {
    id: 278,
    nome: 'Esdep',
    descricao: 'Saiba como entrar em contato com a Esdep',
    icone: <FontAwesomeIcon icon={faGraduationCap} size="2x" />,
  },
];

export default gruposContatosMap;
