import {
  faFilePdf,
  faFileUpload,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { red } from '@mui/material/colors';

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { CrachaObservacoesRequerente } from '../../../../types';
import getFileFormat from '../../../../utils/getFileFormat';
import ObservacoesSegundaVia from '../ObservacoesSegundaVia';
import PhotoPreview from '../PhotoPreview';
import LoadingButton from '../../../../components/LoadingButton';

type FormularioProps = {
  nome: string;
  cargo: string;
  matricula: string;
  onSubmit: (data: FormData) => Promise<void>;
};

type FormInputs = Omit<FormularioProps, 'onSubmit'> & {
  foto: Blob;
  documento?: FileList;
  cordao: boolean;
  justificativa: CrachaObservacoesRequerente;
};

export default function FormularioSegundaVia({
  nome,
  cargo,
  matricula,
  onSubmit: onFormSubmit,
}: FormularioProps) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      nome,
      cargo,
      matricula,
    },
  });

  const watchJustificativa = watch('justificativa');
  const watchDocumento = watch('documento');

  const showUploadDocument =
    watchJustificativa === 'perda_extravio' ||
    watchJustificativa === 'roubo_furto' ||
    watchJustificativa === 'mau_estado_conservacao';

  const cropState = useState<Crop>();
  const [, setCrop] = cropState;

  const [imgSrc, setImgSrc] = useState('');

  const handleUploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);

      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const formData = new FormData();

    const fileName = data.nome
      .replace(/\s+/g, '_')
      .replace(/(\W)\1+/g, '$1')
      .toLowerCase();

    const fileFormat = getFileFormat(data.foto.type);

    formData.append('cracha[solicitacao]', 'segunda_via');
    formData.append('cracha[nome]', data.nome);
    formData.append('cracha[cargo]', data.cargo);
    formData.append('cracha[matricula]', data.matricula);
    formData.append('cracha[cordao]', data.cordao ? '1' : '0');
    formData.append('cracha[observacao_requerente]', data.justificativa);
    formData.append('cracha[foto]', data.foto, `${fileName}.${fileFormat}`);

    if (data.documento != null && data.documento.length > 0) {
      formData.append(
        'cracha[documento]',
        data.documento[0],
        data.documento[0].name,
      );
    }

    await onFormSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 3,
        }}
      >
        <TextField
          label="Nome que será impresso no crachá"
          fullWidth
          error={!!errors.nome}
          {...register('nome', {
            required: true,
          })}
        />

        <Controller
          control={control}
          name="foto"
          rules={{
            required: true,
          }}
          render={({ fieldState: { error } }) => (
            <Box>
              <Button
                component="label"
                startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                variant="contained"
                sx={{ mb: 1 }}
              >
                Escolher foto
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={handleUploadPhoto}
                />
              </Button>

              {error && (
                <Typography color="error.main" fontWeight="bold">
                  Por favor, faça o upload da foto do crachá
                </Typography>
              )}

              <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>
                Aceita somente as extensões JPG, JPEG ou PNG
              </Typography>
            </Box>
          )}
        />

        <PhotoPreview
          cropState={cropState}
          imgSrc={imgSrc}
          onCompletedCrop={canvasElement => {
            canvasElement.toBlob(blob => {
              if (blob != null) {
                setValue('foto', blob);
              }
            });
          }}
        />

        <Box sx={{ px: 1 }}>
          <FormControlLabel
            control={<Checkbox {...register('cordao')} />}
            label="Incluir cordão do crachá?"
          />
        </Box>

        <FormControl fullWidth error={!!errors.justificativa}>
          <InputLabel id="select-justificativa-label">Justificativa</InputLabel>

          <Select
            labelId="select-justificativa-label"
            id="select-justificativa"
            defaultValue=""
            label="Justificativa"
            {...register('justificativa', {
              required: true,
            })}
          >
            <MenuItem value="">Selecione uma opção</MenuItem>
            <MenuItem value="alteracao_dados">Alteração de dados</MenuItem>
            <MenuItem value="mau_estado_conservacao">
              Mau estado de conservação
            </MenuItem>
            <MenuItem value="desgaste_natural">Desgaste natural</MenuItem>
            <MenuItem value="perda_extravio">Perda ou extravio</MenuItem>
            <MenuItem value="roubo_furto">Roubo ou furto</MenuItem>
          </Select>
        </FormControl>

        {showUploadDocument && (
          <Box>
            <Controller
              control={control}
              name="documento"
              rules={{
                required: showUploadDocument,
              }}
              render={({ fieldState: { error } }) => (
                <Box>
                  <Button
                    component="label"
                    startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                    variant="contained"
                    sx={{ mb: 1 }}
                  >
                    Carregar comprovante
                    <input
                      hidden
                      type="file"
                      accept="application/pdf"
                      {...register('documento')}
                    />
                  </Button>

                  {watchDocumento != null && watchDocumento.length > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ mr: 1, color: red[500] }}>
                        <FontAwesomeIcon icon={faFilePdf} />
                      </Box>

                      <Typography variant="body2" fontWeight="bold">
                        {watchDocumento[0].name}
                      </Typography>
                    </Box>
                  )}

                  {error && (
                    <Typography key="1" color="error.main" fontWeight="bold">
                      Por favor, faça o upload do comprovante
                    </Typography>
                  )}

                  <Typography
                    key="2"
                    variant="subtitle2"
                    sx={{ color: 'grey.600' }}
                  >
                    Aceita somente arquivos PDF
                  </Typography>
                </Box>
              )}
            />
          </Box>
        )}

        <ObservacoesSegundaVia justificativa={watchJustificativa} />

        <Box sx={{ display: 'flex', gap: 1 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSave} />}
            isLoading={isSubmitting}
          >
            Enviar
          </LoadingButton>

          <Button component={RouterLink} to="/crachas/meus-crachas">
            Voltar
          </Button>
        </Box>
      </Box>
    </form>
  );
}
