import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthProvider';

import { createCracha, getNovoCracha } from '../../../services/portal';

import FormularioPrimeiraVia from '../components/FormularioPrimeiraVia';
import FormularioSegundaVia from '../components/FormularioSegundaVia';
import FormularioSomenteCordao from '../components/FormularioSomenteCordao';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel({ children, index, value }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function CrachasNew() {
  const navigate = useNavigate();
  const { servidor } = useAuth();

  const { data: cracha, isSuccess: isCrachaSuccess } = useQuery(
    ['cracha/new'],
    async () => {
      if (servidor != null) {
        const { data } = await getNovoCracha();
        return data;
      }

      return null;
    },
  );

  const primeiraVia = cracha?.solicitacao === 'primeira_via';

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const onFormSubmit = async (formData: FormData) => {
    const { data: crachaCreated } = await createCracha(formData);
    navigate(`/crachas/meus-crachas/${crachaCreated.id}`);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h5">Nova solicitação de crachá</Typography>

        <Button component={RouterLink} to="/crachas/meus-crachas">
          Ir para meus crachás
        </Button>
      </Box>

      {['estagiario', 'voluntario'].includes(servidor?.tipo || '') ? (
        <p>
          A solicitação do crachá deve ser feita diretamente na Diretoria
          Regional ou Núcleo Especializado.
        </p>
      ) : (
        <>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Box
                component="dl"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'max-content auto',
                  rowGap: 1,
                }}
              >
                <dt>
                  <strong>Nome:</strong>
                </dt>
                <dd>{servidor?.nome}</dd>

                <dt>
                  <strong>Cargo:</strong>
                </dt>
                <dd>{servidor?.cargo}</dd>

                <dt>
                  <strong>Matrícula:</strong>
                </dt>
                <dd>{servidor?.matricula}</dd>
              </Box>

              <Typography>
                <strong>Observação:</strong> Em caso de inconsistência nos
                dados, contate o setor de Recursos Humanos para atualização de
                seus dados.
              </Typography>
            </CardContent>
          </Card>
          {isCrachaSuccess && (
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={primeiraVia ? 'Primeira Via' : 'Segunda Via'}
                    id="tab-0"
                    aria-controls="tab-panel-0"
                  />

                  <Tab
                    label="Somente cordão"
                    id="tab-1"
                    aria-controls="tab-panel-1"
                  />

                  <Tab
                    label="Orientações gerais"
                    id="tab-2"
                    aria-controls="tab-panel-2"
                  />
                </Tabs>
              </Box>

              <TabPanel value={currentTab} index={0}>
                {primeiraVia ? (
                  <FormularioPrimeiraVia
                    nome={servidor?.nome || ''}
                    cargo={servidor?.cargo || ''}
                    matricula={servidor?.matricula || ''}
                    onSubmit={onFormSubmit}
                  />
                ) : (
                  <FormularioSegundaVia
                    nome={servidor?.nome || ''}
                    cargo={servidor?.cargo || ''}
                    matricula={servidor?.matricula || ''}
                    onSubmit={onFormSubmit}
                  />
                )}
              </TabPanel>

              <TabPanel value={currentTab} index={1}>
                <FormularioSomenteCordao
                  nome={servidor?.nome || ''}
                  cargo={servidor?.cargo || ''}
                  matricula={servidor?.matricula || ''}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={2}>
                <section>
                  <ul>
                    <li>
                      <Typography
                        component="h5"
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                      >
                        Solicitações de 2ª via:
                      </Typography>

                      <ul>
                        <li>
                          <strong>
                            Desgaste natural ou mau estado de conservação:
                          </strong>

                          <p>
                            É necessário encaminhar o crachá atual à
                            Coordenadoria de Recursos Humanos para análise
                            prévia.
                          </p>
                        </li>

                        <li>
                          <strong>Alteração de dados:</strong>

                          <p>
                            A solicitação é válida apenas em caso de alteração
                            no nome ou matrícula, ou, para servidor
                            comissionado, ao tomar posse no cargo de Assessor
                            Técnico de Defensor Público, e para servidor
                            efetivo, ao assumir cargo de chefia.
                          </p>
                        </li>

                        <li>
                          <strong>Perda ou extravio:</strong>

                          <p>
                            É necessário pagar uma taxa ou apresentar o Boletim
                            de Ocorrência.
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Typography
                        component="h5"
                        variant="h6"
                        fontWeight="bold"
                        sx={{ mb: 2 }}
                      >
                        Para todas as solicitações:
                      </Typography>

                      <ul>
                        <li>Utilizar apenas dois nomes.</li>
                        <li>
                          Inserir foto no estilo 3x4, com fundo branco, e o
                          rosto e corpo retos.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
              </TabPanel>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
