import { Box, Container, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';

import MainContent from '../../components/MainContent';
import Navbar from '../../components/Navbar';

import theme from '../../theme';

type UnauthenticatedLayoutProps = {
  children: React.ReactNode;
};

export default function UnauthenticatedLayout({
  children,
}: UnauthenticatedLayoutProps) {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />

      <Box bgcolor={grey[50]} height="calc(100vh - 73px)">
        <Container
          sx={{ bgcolor: 'white', display: 'flex', height: '100%' }}
          disableGutters
        >
          <MainContent>{children}</MainContent>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
