import axios, { AxiosResponse } from 'axios';
import { Noticia } from '../types';

export type PostifyAPIPaginatedResponseType = {
  current_page: number;
  limit_value: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
};

const postifyAPI = axios.create({
  baseURL: process.env.REACT_APP_POSTIFY_API_URL,
  headers: {
    Authorization: `Token ${process.env.REACT_APP_POSTIFY_API_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

type NoticiasResponse = PostifyAPIPaginatedResponseType & {
  noticias: Noticia[];
};

export const getNoticiasDestaques = async (pagina: number, bloco: string) => {
  const params = { limit: 6, bloco };
  const { data } = await postifyAPI.get<NoticiasResponse>(
    `/noticia?page=${pagina}`,
    {
      params,
    },
  );

  return data;
};

export const getNoticias = async <T = PostifyAPIPaginatedResponseType>({
  pagina,
  content,
}: {
  pagina: number | string;
  content?: string;
}): Promise<AxiosResponse<T>> => {
  const params = { page: pagina, content };
  return postifyAPI.get('/noticia', { params });
};

export const getMenuPrincipal = async <T>(): Promise<AxiosResponse<T>> => {
  const params = { destination: 'portal_conhecimento_menu' };
  return postifyAPI.get('/menu', { params });
};

export const getLembretes = async <T>(): Promise<AxiosResponse<T>> => {
  return postifyAPI.get('/lembrete');
};

export const getPagina = async <T>(id: string): Promise<AxiosResponse<T>> => {
  const er = /^[0-9]+$/;
  if (er.test(id)) {
    return postifyAPI.get(`/pagina/${id}`);
  }

  return postifyAPI.get(`/pagina/slug/${id}`);
};

export const getNoticia = async <T>(id: string): Promise<AxiosResponse<T>> => {
  const er = /^[0-9]+$/;
  if (er.test(id)) {
    return postifyAPI.get(`/noticia/${id}`);
  }
  return postifyAPI.get(`/noticia/slug/${id}`);
};

export const getNoticiaPagina = async <T>(
  pagina: string,
): Promise<AxiosResponse<T>> => {
  return postifyAPI.get(`/noticia?page=${pagina}`);
};

export const getBannerCarouselInferior = async <T>(): Promise<
  AxiosResponse<T>
> => {
  const params = { destination: 'portal_conhecimento_carousel_inferior' };
  return postifyAPI.get('banner', { params });
};

export const getGrupoContatos = async <T>(): Promise<AxiosResponse<T>> => {
  return postifyAPI.get('/contato');
};

export const getContato = async <T>(id: string): Promise<AxiosResponse<T>> => {
  const params = { destination: 362 };
  return postifyAPI.get(`/contato/${id}`, { params });
};

export const getContatos = async <T>(
  id: string | undefined,
): Promise<AxiosResponse<T>> => {
  const params = { grupos_contatos_id: id };
  return postifyAPI.get('/contato', { params });
};

export const adicionarComentario = async ({
  postId,
  comentario: text,
  autor: author,
  cpf,
}: {
  postId?: number | string;
  comentario: string;
  autor: string;
  cpf: string;
}) => {
  const payload = {
    comentario: {
      post_id: postId,
      text,
      author,
      cpf,
    },
    paper: {
      ip: '192.168.1.0',
      agent_id: cpf,
      app: 'Portal do Conhecimento',
    },
  };

  return postifyAPI.post('/comentario', payload);
};

export const deletarComentario = async (id: number, cpf: string) => {
  const params = {
    paper: {
      ip: '192.168.1.0',
      agent_id: cpf,
      app: 'Portal do Conhecimento',
    },
  };

  return postifyAPI.delete(`/comentario/${id}`, { data: params });
};
