import { Data } from '../types';

const concessoesList: Data[] = [
  {
    id: 1,
    title: 'Ausência para doação de sangue',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios da doação de sangue no referido dia',
      },
    ],
  },
  {
    id: 2,
    title: 'Ausência para se alistar como eleitor',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 3,
    title: 'Ausência para casamento',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de casamento',
      },
    ],
  },
  {
    id: 4,
    title: 'Ausência para nascimento ou adoção de filho (Pai)',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de nascimento ou registro geral (RG) da criança',
      },
    ],
  },
  {
    id: 5,
    title: 'Ausência pelo falecimento',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Certidão de óbito',
      },
    ],
  },
  {
    id: 6,
    title:
      'Ausência para finalização de trabalho objeto de curso de graduação, especialização, mestrado ou doutorado',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '12',
      },
      {
        id: 2,
        title:
          'Documentação da instituição de ensino que comprove estar cursando a matéria de trabalho de conclusão de curso',
      },
    ],
  },
];

export default concessoesList;
