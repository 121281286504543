import { Link } from 'react-router-dom';

import {
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';

import Header from '../../../components/Header';

import { Noticia } from '../../../types';

const Img = styled('img')({
  aspectRatio: '15 / 10',
  width: '100%',
  objectFit: 'cover',
});

const NoticiaTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
  lineHeight: 1.2,
});

type HomeNoticiasProps = BoxProps & {
  noticiasDestaque: Noticia[];
};

export default function HomeNoticias({
  noticiasDestaque,
  ...props
}: HomeNoticiasProps): JSX.Element {
  const [primeiraNoticia, segundaNoticia, ...listaNoticias] = noticiasDestaque;

  if (noticiasDestaque.length === 0) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress color="secondary" disableShrink />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Header title="Notícias" />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        mb={3}
        alignItems="stretch"
      >
        <Box
          component={Link}
          to={`noticia/${primeiraNoticia.slug}`}
          sx={{
            textDecoration: 'none',
            color: theme => theme.palette.primary.main,
            transition: 'color .3s',
            '&:hover': { color: '#00775a' },
            '& img': {
              transition: 'box-shadow .3s',
            },
            '&:hover img': {
              boxShadow: 2,
            },
          }}
          width="100%"
          height="100%"
        >
          <Img
            src={primeiraNoticia?.imagem_capa?.file?.url}
            srcSet={primeiraNoticia?.imagem_capa?.file?.url}
            alt={primeiraNoticia?.imagem_capa?.short_description}
            loading="lazy"
          />
          <NoticiaTitle pl={1}>{primeiraNoticia?.title}</NoticiaTitle>
        </Box>

        <Box
          component={Link}
          to={`noticia/${segundaNoticia.slug}`}
          sx={{
            textDecoration: 'none',
            color: theme => theme.palette.primary.main,
            transition: 'color .3s',
            '&:hover': { color: '#00775a' },
            '& img': {
              transition: 'box-shadow .3s',
            },
            '&:hover img': {
              boxShadow: 2,
            },
          }}
          width="100%"
          height="100%"
        >
          <Img
            src={segundaNoticia?.imagem_capa?.file?.url}
            srcSet={segundaNoticia?.imagem_capa?.file?.url}
            alt={segundaNoticia?.imagem_capa?.short_description}
            loading="lazy"
          />
          <NoticiaTitle>{segundaNoticia?.title}</NoticiaTitle>
        </Box>
      </Stack>

      <List
        sx={{
          color: grey[700],
          fontWeight: 'bold',
          marginTop: '-15px',
        }}
      >
        <Divider
          variant="inset"
          component="li"
          sx={{
            ml: 0,
          }}
        />
        {listaNoticias.map(noticia => (
          <div key={noticia.id}>
            <ListItem color={grey[500]} disablePadding>
              <ListItemButton
                component={Link}
                to={`noticia/${noticia.slug}`}
                disableGutters
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: 1,
                  py: 0.3,
                  '&:hover': {
                    bgcolor: theme => theme.palette.primary.main,
                    color: 'white',
                  },
                }}
              >
                <ListItemText
                  primary={noticia.title}
                  primaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                />
              </ListItemButton>
            </ListItem>

            <Divider variant="inset" component="li" sx={{ ml: 0 }} />
          </div>
        ))}
      </List>
      <Header title="" to="/noticias" titleButton="Mais Notícias" />
    </Box>
  );
}
