import { useEffect, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  getAllFichasFinanceiras,
  getFichasFinanceiras,
} from '../../../services/athenas';

import { Ano, FichaFinanceira, Folha } from '../../../types';

import Breadcrumbs from '../../../components/Breadcrumbs';
import ButtonReport from '../../../components/ButtonReport';
import HeaderPages from '../../../components/HeaderPages';
import TablePagination from '../../../components/TablePagination';
import { useAuth } from '../../../context/AuthProvider';

export default function FichasFinanceiras(): JSX.Element {
  const [ano, setAno] = useState<string>('');
  const [folha, setFolha] = useState<string>('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [rowsPerPage] = useState(10);

  const [fichasFinanceiras, setFichasFinanceiras] = useState<FichaFinanceira[]>(
    [],
  );
  const [optionsAnos, setOptionsAnos] = useState<Ano[]>([{ id: 0, label: '' }]);
  const [optionsFolhas, setOptionsFolhas] = useState<Folha[]>([
    { id: 0, label: '' },
  ]);

  const { usuario, servidor } = useAuth();
  const cpf = servidor?.cpf;

  useEffect(() => {
    const fetchContracheques = async () => {
      if (cpf != null) {
        setLoading(true);
        const {
          results,
          count: countFichas,
          anos,
          folhas,
        } = await getAllFichasFinanceiras(cpf);

        setFichasFinanceiras(results.slice(0, 10));
        setCount(countFichas);

        if (anos) {
          setOptionsAnos(anos);
        }
        if (folhas) {
          setOptionsFolhas(folhas);
        }
        setLoading(false);
      }
    };

    fetchContracheques();
  }, [cpf]);

  const fetchFichasFinanceiras = async (
    pageNumber = 0,
    anoNumber = '',
    folhaName = '',
  ) => {
    if (cpf != null) {
      setLoading(true);
      const { results, count: countFichas } = await getFichasFinanceiras(
        cpf,
        pageNumber,
        anoNumber,
        folhaName,
      );

      setFichasFinanceiras(results);
      setCount(countFichas);
      setLoading(false);
    }
  };

  const handleSearch = (anoNumber = '', folhaName = '') => {
    fetchFichasFinanceiras(0, anoNumber, folhaName);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    fetchFichasFinanceiras(newPage * 10, ano, folha);

    setPage(newPage);
  };

  return (
    <Box>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/servicos"
          color="inherit"
          underline="hover"
        >
          Serviços
        </Link>
        <Typography color="secondary" fontWeight="bold">
          Fichas Financeiras
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Fichas Financeiras" />

      <Stack
        component="section"
        direction={{ xs: 'column', sm: 'row', md: 'row' }}
        spacing={2}
        pb={2}
      >
        <Autocomplete
          id="ano"
          size="small"
          fullWidth
          options={optionsAnos}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => <TextField {...params} label="Ano" />}
          onChange={(event, value) => {
            if (value) {
              setAno(String(value.id));
              handleSearch(String(value.id), folha);
            } else {
              setAno('');
              handleSearch('', folha);
            }
          }}
        />

        <Autocomplete
          id="folha"
          size="small"
          fullWidth
          options={optionsFolhas}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              {...params}
              label="Folha"
              sx={{
                '& #folha': {
                  textTransform: 'capitalize',
                },
              }}
            />
          )}
          onChange={(event, value) => {
            if (value) {
              setFolha(String(value.id));
              handleSearch(ano, String(value.id));
            } else {
              setFolha('');
              handleSearch(ano, '');
            }
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ textTransform: 'capitalize' }}>
              {option.label}
            </li>
          )}
        />
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ano</TableCell>
              <TableCell>Folha</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress color="secondary" disableShrink />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              fichasFinanceiras.map(ficha => (
                <TableRow key={ficha.pk}>
                  <TableCell>
                    <Typography
                      component="span"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {ficha.folha.periodo.ano}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ textTransform: 'capitalize' }}>
                      {ficha.folha.tipo_folha.titulo}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <ButtonReport
                      app="athenas"
                      name="ficha_financeira"
                      user={usuario?.username || 'portal do servidor'}
                      resource="athenas/gfp"
                      fileName="ficha_financeira"
                      params={{
                        servidor: ficha.servidor.id,
                        folhatipo: ficha.folha.tipo_folha.id,
                        ano_inicial: ficha.folha.periodo.ano,
                        ano_final: ficha.folha.periodo.ano,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>

          {count > 10 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
