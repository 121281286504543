import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAsideDrawer } from '../../../context/AsideDrawerProvider';

import Header from '../../Header';

import servicos from './Servicos.constants';

export default function Servicos(): JSX.Element {
  const { openAside, toggleAside } = useAsideDrawer();

  const handleAsideDrawerClose = () => {
    if (openAside) {
      toggleAside();
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#f1f2f2',
        p: 2,
        borderRadius: 1,
      }}
    >
      <Header
        title="Serviços"
        to="/servicos"
        titleButton="Ver Todos"
        onClick={handleAsideDrawerClose}
      />

      <Box display="grid" gridTemplateColumns="repeat(2, 2fr)" gap="0.5rem">
        {servicos.map(servico => (
          <Button
            key={servico.id}
            variant="text"
            component={RouterLink}
            to={servico.href}
            onClick={handleAsideDrawerClose}
            sx={{
              textTransform: 'none',
              '&:hover': {
                color: theme => theme.palette.secondary.main,
                backgroundColor: 'none',
              },
            }}
          >
            <Box textAlign="center">
              <FontAwesomeIcon icon={servico.icon} size="lg" />
              <Typography>{servico.title}</Typography>
            </Box>
          </Button>
        ))}
      </Box>
    </Box>
  );
}
