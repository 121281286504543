import { faFileUpload, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

import { useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import getFileFormat from '../../../../utils/getFileFormat';

import LoadingButton from '../../../../components/LoadingButton';
import PhotoPreview from '../PhotoPreview';

type FormularioProps = {
  nome: string;
  cargo: string;
  matricula: string;
  onSubmit: (data: FormData) => Promise<void>;
};

type FormInputs = Omit<FormularioProps, 'onSubmit'> & {
  foto: Blob;
  cordao: boolean;
};

export default function FormularioPrimeiraVia({
  nome,
  cargo,
  matricula,
  onSubmit: onFormSubmit,
}: FormularioProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormInputs>({
    defaultValues: {
      nome,
      cargo,
      matricula,
    },
  });

  const cropState = useState<Crop>();
  const [, setCrop] = cropState;

  const [imgSrc, setImgSrc] = useState('');

  const handleUploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);

      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const formData = new FormData();

    const fileName = data.nome
      .replace(/\s+/g, '_')
      .replace(/(\W)\1+/g, '$1')
      .toLowerCase();

    const fileFormat = getFileFormat(data.foto.type);

    formData.append('cracha[solicitacao]', 'primeira_via');
    formData.append('cracha[nome]', data.nome);
    formData.append('cracha[cargo]', data.cargo);
    formData.append('cracha[matricula]', data.matricula);
    formData.append('cracha[cordao]', data.cordao ? '1' : '0');
    formData.append('cracha[foto]', data.foto, `${fileName}.${fileFormat}`);

    await onFormSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          label="Nome que será exibido no crachá"
          error={!!errors.nome}
          {...register('nome', {
            required: true,
          })}
        />

        <Controller
          control={control}
          name="foto"
          rules={{
            required: true,
          }}
          render={({ fieldState: { error } }) => (
            <Box>
              <Button
                component="label"
                startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                variant="contained"
                sx={{ mb: 1 }}
              >
                Escolher foto
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={handleUploadPhoto}
                />
              </Button>

              {error && (
                <Typography color="error.main" fontWeight="bold">
                  Por favor, faça o upload da foto do crachá
                </Typography>
              )}

              <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>
                Aceita somente as extensões JPG, JPEG ou PNG
              </Typography>
            </Box>
          )}
        />

        <PhotoPreview
          cropState={cropState}
          imgSrc={imgSrc}
          onCompletedCrop={canvasElement => {
            canvasElement.toBlob(blob => {
              if (blob != null) {
                setValue('foto', blob);
              }
            });
          }}
        />

        <Box>
          <FormControlLabel
            control={<Checkbox {...register('cordao')} />}
            label="Incluir cordão do crachá?"
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faSave} />}
            isLoading={isSubmitting}
          >
            Enviar
          </LoadingButton>

          <Button component={RouterLink} to="/crachas/meus-crachas">
            Voltar
          </Button>
        </Box>
      </Box>
    </form>
  );
}
