import { Link, Typography } from '@mui/material';
import UnauthenticatedLayout from '../../layout/UnauthenticatedLayout';

export default function UsuarioNotFound() {
  return (
    <UnauthenticatedLayout>
      <Typography variant="h5">Usuário não localizado</Typography>

      <p>Ocorreu algum problema na autenticação do seu usuário.</p>

      <p>
        Abra um chamado no sistema de{' '}
        <Link href="https://helpdesk.defensoria.to.def.br">Help Desk</Link>{' '}
        informando o ocorrido. Não esqueça de informar seu nome completo e CPF.
      </p>

      <p>
        Caso você não tenha acesso ao sistema de Help Desk peça para seu
        superior imediato abrir um chamado informando o problema.
      </p>
    </UnauthenticatedLayout>
  );
}
