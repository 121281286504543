import { faCheck, faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';

type ConfirmDialogProps = {
  open: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
};

const CustomBackdrop = styled(Backdrop)({
  backgroundColor: 'rgba(0, 0, 0, 0.55)',
});

export default function ConfirmDialog({
  open,
  title,
  onCancel,
  onConfirm,
  children,
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      BackdropComponent={CustomBackdrop}
      sx={{
        '& .MuiDialog-paper': {
          boxShadow: 'none',
        },
      }}
    >
      <DialogTitle>
        <Box display="inline-block" mr={1} color="warning.dark">
          <FontAwesomeIcon icon={faWarning} color="inherit" />
        </Box>
        {title}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          startIcon={<FontAwesomeIcon icon={faTimes} />}
          onClick={onCancel}
          sx={{ px: 2 }}
        >
          Cancelar
        </Button>

        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faCheck} />}
          onClick={onConfirm}
        >
          Prosseguir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
