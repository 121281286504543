import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Link as RouterLink, useParams } from 'react-router-dom';

import DateTimeFormatter from '../../../components/DateTimeFormatter';
import { useConfirmDialog } from '../../../context/ConfirmDialogProvider';
import { getCracha, updateCracha } from '../../../services/portal';
import CrachaPreview from '../components/Preview';

import {
  justificativasMap,
  solicitacoesMap,
  statusMap,
} from '../Crachas.constants';

const CRACHA_EDIT_DISABLED = ['analisado', 'impresso', 'recebido', 'cancelado'];

export default function CrachasShow() {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { showConfirmDialog } = useConfirmDialog();

  const {
    data: cracha,
    isFetching,
    isError,
  } = useQuery(
    ['cracha', id],
    async () => {
      if (id != null) {
        return getCracha(id);
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  const [isCrachaUpdatedSuccess, setIsCrachaUpdatedSuccess] = useState(false);

  const handleMarcarComoRecebido = async () => {
    if (id != null) {
      await updateCracha(id, { status: 'recebido' });
      queryClient.invalidateQueries(['cracha', id]);

      setIsCrachaUpdatedSuccess(true);
    }
  };

  const handleCancelar = () => {
    if (id != null) {
      showConfirmDialog({
        title: 'Cancelar solicitação de crachá',
        message: 'Você tem certeza que deseja cancelar a solicitação?',
        async onConfirm() {
          await updateCracha(id, { status: 'cancelado' });
          queryClient.invalidateQueries(['cracha', id]);

          setIsCrachaUpdatedSuccess(true);
        },
      });
    }
  };

  if (isFetching) {
    return <Box>Carregando...</Box>;
  }

  if (isError) {
    return (
      <Box>
        <Typography>Crachá não localizado</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" mb={1}>
        Detalhes da solicitação
      </Typography>

      {isCrachaUpdatedSuccess && (
        <Alert severity="success">Crachá foi atualizado com sucesso</Alert>
      )}

      <Table sx={{ mb: 3 }}>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Nome</TableCell>
            <TableCell>{cracha?.nome}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Status</TableCell>
            <TableCell>{statusMap[cracha?.status || '']}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Data da solicitação</TableCell>
            <TableCell>
              <DateTimeFormatter datetime={cracha?.created_at || ''}>
                {({ dateFormatted, timeFormatted }) => (
                  <Typography>
                    {dateFormatted} {timeFormatted}
                  </Typography>
                )}
              </DateTimeFormatter>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Última atualização</TableCell>
            <TableCell>
              <DateTimeFormatter datetime={cracha?.updated_at || ''}>
                {({ dateFormatted, timeFormatted }) => (
                  <Typography>
                    {dateFormatted} {timeFormatted}
                  </Typography>
                )}
              </DateTimeFormatter>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Cordão?</TableCell>
            <TableCell>{cracha?.cordao ? 'Sim' : 'Não'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Solicitação</TableCell>
            <TableCell>{solicitacoesMap[cracha?.solicitacao || '']}</TableCell>
          </TableRow>

          {['segunda_via', 'cordao'].includes(cracha?.solicitacao || '') && (
            <TableRow>
              <TableCell variant="head">Justificativa</TableCell>
              <TableCell>
                {justificativasMap[cracha?.observacao_requerente || '']}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {cracha?.observacao_avaliador != null && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <AlertTitle>Observações do avaliador</AlertTitle>
          <Typography>{cracha.observacao_avaliador}</Typography>
        </Alert>
      )}

      <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
        {cracha?.solicitacao !== 'cordao' && (
          <Button
            variant="contained"
            component={RouterLink}
            to={`/crachas/meus-crachas/${cracha?.id}/edit`}
            disabled={CRACHA_EDIT_DISABLED.includes(cracha?.status || '')}
          >
            Editar
          </Button>
        )}

        <Button
          variant="contained"
          onClick={handleMarcarComoRecebido}
          disabled={['analisar', 'recebido', 'cancelado'].includes(
            cracha?.status || '',
          )}
        >
          Marcar como recebido
        </Button>

        <Button
          variant="contained"
          component={RouterLink}
          to={`/crachas/${cracha?.id}/comprovante`}
        >
          Emitir comprovante
        </Button>

        {cracha?.status === 'analisar' && (
          <Button variant="contained" onClick={handleCancelar}>
            Cancelar
          </Button>
        )}

        <Button component={RouterLink} to="/crachas/meus-crachas">
          Voltar
        </Button>
      </Box>

      {cracha?.solicitacao !== 'cordao' && (
        <Box key="1" sx={{ maxWidth: 350, mx: 'auto' }}>
          <Typography mb={1} fontWeight="bold" textAlign="center">
            Pré-visualização do crachá
          </Typography>

          <CrachaPreview
            cargo={cracha?.cargo || ''}
            matricula={cracha?.matricula || ''}
            nome={cracha?.nome || ''}
            imgSrc={cracha?.url || ''}
            styles={{
              photoWidth: 125,
              nomeFontSize: '1.5rem',
              nomeCompletoMatriculaFontSize: '1.15rem',
              nomeCompletoMatriculaLineHeight: 1.2,
              cargoFontSize: '0.75rem',
            }}
          />
        </Box>
      )}

      {cracha?.solicitacao !== 'primeira_via' && (
        <Box>
          <Typography sx={{ mb: 1 }} fontWeight="bold">
            Documentos:
          </Typography>

          {cracha?.documento_file != null ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 1,
              }}
            >
              <Link
                key={cracha.documento_file.filename}
                target="_blank"
                href={cracha.documento_file.url}
                sx={{
                  textDecoration: 'none',
                  ':hover': { color: 'primary.dark' },
                }}
              >
                <Box component="span" sx={{ mr: 1 }}>
                  <FontAwesomeIcon icon={faFilePdf} />
                </Box>

                {cracha.documento_file.filename}
              </Link>
            </Box>
          ) : (
            <Typography>Nenhum documento anexado</Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
