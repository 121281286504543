import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

type Documento = {
  id: string;
  title: string;
  short_description: string;
  link: string;
};

type DocuemntosNoticiaProps = {
  documentos: Documento[];
};

export default function DocumentosNoticia({
  documentos,
}: DocuemntosNoticiaProps) {
  return (
    <Box component="section">
      <List>
        {documentos.map(documento => (
          <ListItem key={documento.id} sx={{ pl: 0, display: 'flex' }}>
            <ListItemText secondary={documento.short_description}>
              <Link
                href={documento.link}
                underline="hover"
                target="_blank"
                color="secondary.main"
              >
                <Typography component="span" sx={{ ml: 1 }}>
                  {documento.title}
                </Typography>
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
