import { useQuery } from '@tanstack/react-query';

import { getAniversariantes } from '../../../services/portal';
import { Aniversariante } from '../../../types';
import getDay from '../../../utils/getDay';

export default function useGetAniversariantesPorPeriodo(
  periodo: 'mes' | 'hoje',
) {
  const {
    data: aniversariantes = [],
    isLoading,
    isFetching,
  } = useQuery(['aniversariantes', periodo], async () => {
    const { data } = await getAniversariantes<Aniversariante[]>({
      periodo,
    });

    return data.map(aniversariante => ({
      ...aniversariante,
      dia_aniversario: getDay(aniversariante.data_nascimento),
    }));
  });

  return {
    aniversariantes,
    isLoading,
    isFetching,
  };
}
