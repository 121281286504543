import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import LoadingButton from '../../../components/LoadingButton';

import {
  getFolder,
  getFolders,
  uploadDocumento,
} from '../../../services/portal';
import { DocumentosResponse } from '../../../types';

type Folder = {
  id: number;
  name: string;
  parent_folder_id: number | null;
};

type Documento = {
  id?: number;
  name: string;
  description: string;
  folder_id: number;
  url: string;
  file?: File;
  filename?: string;
};

export default function FormDocumentoUpload() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data: folders } = useQuery(['folders'], async () => {
    const { data } = await getFolders<Folder[]>();
    return data;
  });

  const { data: folder } = useQuery(
    ['folder', id],
    async () => {
      if (id != null) {
        const { data } = await getFolder<DocumentosResponse>(id);
        return data;
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    setValue,
  } = useForm<Documento>({
    defaultValues: {
      name: '',
      description: '',
      folder_id: typeof id === 'string' ? parseInt(id, 10) : undefined,
      url: '',
    },
  });

  const [filename, setFilename] = useState('');

  const [uploadDocumentSuccess, setChangeDocumentSuccess] = useState(false);

  const handleSnackbarSuccessClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setChangeDocumentSuccess(false);
    navigate(`/documents/folder/${id}`);
  };

  const onSubmit: SubmitHandler<Documento> = async data => {
    const formData = new FormData();
    formData.append('document[name]', data.name);
    formData.append('document[description]', data.description);
    formData.append('document[folder_id]', data.folder_id.toString());

    if (data.file != null) {
      formData.append('document[file]', data.file);
    }

    await uploadDocumento(formData);

    setChangeDocumentSuccess(true);
  };

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];

      setFilename(file.name);

      setValue('url', file.name);
      setValue('file', file);
    }
  };

  return (
    <Box>
      <Snackbar
        open={uploadDocumentSuccess}
        autoHideDuration={6000}
        onClose={handleSnackbarSuccessClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleSnackbarSuccessClose}>
          Documento salvo com sucesso
        </Alert>
      </Snackbar>

      <Box mb={3}>
        <Breadcrumbs showHomeLink={false}>
          {folder?.folder.all_parents?.map(item => (
            <Link
              key={item.id}
              color="inherit"
              underline="hover"
              component={RouterLink}
              to={`/documents/folder/${item.id}`}
            >
              {item.name}
            </Link>
          ))}

          <Typography color="secondary" fontWeight="bold">
            {folder?.folder.name}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Typography component="h3" variant="h5" mb={1}>
        Carregar novo documento
      </Typography>

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} alignItems="start">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Nome"
                    id="documento-nome"
                    onChange={onChange}
                    value={value}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="folder_id"
              control={control}
              defaultValue={
                typeof id === 'string' ? parseInt(id, 10) : undefined
              }
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="documento-diretorio">Pasta</InputLabel>

                  <Select
                    labelId="documento-diretorio"
                    label="Pasta"
                    value={value}
                    onChange={onChange}
                  >
                    {folders
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Descrição breve"
                    id="documento-descricao"
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              )}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <label htmlFor="upload-file">
                <input
                  type="file"
                  id="upload-file"
                  onChange={onUploadFile}
                  style={{ display: 'none' }}
                />

                <Button variant="contained" component="span" sx={{ mb: 1 }}>
                  Carregar arquivo
                </Button>
              </label>

              <Typography component="span" variant="subtitle2">
                {filename}
              </Typography>
            </Box>

            <LoadingButton
              type="submit"
              startIcon={<FontAwesomeIcon icon={faSave} />}
              isLoading={isSubmitting}
            >
              Salvar
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}
