import { Box, Link } from '@mui/material';

import { useQuery } from '@tanstack/react-query';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { getBannerCarouselInferior } from '../../../services/postify';

type Imagem = {
  active: boolean;
  id: number;
  inicio_evento: string;
  fim_evento: string;
  title: string;
  file: {
    url: string;
  };
  link: string;
  src: string;
};

type BannerResponse = {
  galeria_imagens: Imagem[];
};

type GalleryImage = {
  original: string;
  thumbnail?: string;
  link?: string;
};

export default function HomeBanners() {
  const { data: images = [] } = useQuery(
    ['imagens_carouse_inferior'],
    async () => {
      const { data } = await getBannerCarouselInferior<BannerResponse[]>();
      return data[0].galeria_imagens;
    },
  );

  const transformedImages: GalleryImage[] = images.map(image => ({
    original: image.src,
    thumbnail: image.src,
    link: image.link || '#',
  }));

  if (transformedImages.length === 0) {
    return <Box />;
  }

  return (
    <Box component="section">
      <ImageGallery
        items={transformedImages}
        autoPlay
        showNav={false}
        slideInterval={5000}
        renderItem={({ original }) => {
          return (
            <Link
              href={transformedImages.find(i => i.original === original)?.link}
              target="_blank"
            >
              <Box
                component="img"
                src={original}
                alt="Item do Carousel"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                }}
              />
            </Link>
          );
        }}
      />
    </Box>
  );
}
