import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import PagesHeader from '../../../../components/HeaderPages';
import LoadingState from '../../../../components/LoadingState';
import { getUsuario } from '../../../../services/portal';

export default function UsuariosShow() {
  const { id } = useParams<{ id: string }>();

  const {
    data: usuario,
    isLoading,
    isError,
  } = useQuery(['usuarios', id], async () => {
    if (id != null) {
      const { data } = await getUsuario(id);
      return data;
    }

    return null;
  });

  return (
    <LoadingState
      isLoading={isLoading}
      isError={isError}
      title={
        <Box>
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Administração
            </Link>

            <Link
              component={RouterLink}
              to="/admin/usuarios"
              color="inherit"
              underline="hover"
            >
              Usuários
            </Link>

            <Typography color="secondary" fontWeight="bold">
              {usuario?.id}
            </Typography>
          </Breadcrumbs>

          <PagesHeader title="Usuário" />
        </Box>
      }
    >
      <Table sx={{ mb: 3 }}>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Id</TableCell>
            <TableCell>{usuario?.id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Username</TableCell>
            <TableCell>{usuario?.username}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Email</TableCell>
            <TableCell>{usuario?.email}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">CPF</TableCell>
            <TableCell>{usuario?.cpf}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Ativo</TableCell>
            <TableCell>{usuario?.active ? 'Sim' : 'Não'}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Servidor</TableCell>
            <TableCell>
              {usuario?.servidor != null ? (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Id</TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/admin/servidores/${usuario.servidor.id}`}
                          underline="hover"
                        >
                          {usuario.servidor.id}
                        </Link>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell variant="head">Nome</TableCell>
                      <TableCell>{usuario.servidor.nome}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Typography>Usuário não tem servidor vinculado</Typography>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/admin/usuarios/${usuario?.id}/edit`}
        >
          Editar
        </Button>

        <Button variant="text" component={RouterLink} to="/admin/usuarios">
          Voltar
        </Button>
      </Box>
    </LoadingState>
  );
}
