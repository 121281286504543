import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from '@mui/material';

type LoadingButtonProps = ButtonProps & {
  isLoading: boolean;
  loadingIcon?: React.ReactNode;
};

export default function LoadingButton({
  isLoading,
  loadingIcon,
  startIcon,
  children,
  ...props
}: LoadingButtonProps) {
  const loadingIconRendered = loadingIcon || (
    <FontAwesomeIcon icon={faCircleNotch} spin={isLoading} />
  );

  return (
    <Button
      {...props}
      startIcon={isLoading ? loadingIconRendered : startIcon}
      disabled={isLoading}
      sx={{
        textTransform: 'none',
      }}
    >
      {isLoading ? 'Carregando' : children}
    </Button>
  );
}
