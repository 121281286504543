import {
  Box,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useMemo } from 'react';

import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getQuantitativos } from '../../../services/portal';

type FormInputs = {
  data_inicial: Date;
  data_final: Date;
};

export default function CrachasQuantitativo() {
  const currentDate = useMemo(() => new Date(), []);

  const { control, watch } = useForm<FormInputs>({
    defaultValues: {
      data_inicial: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
        0,
        0,
        0,
      ),
      data_final: new Date(),
    },
  });

  const searchQuery = watch();

  const {
    data: quantitativos,
    isLoading: isQuantitativosLoading,
    isSuccess: isQuantitativosSuccess,
  } = useQuery({
    queryKey: ['relatorios', 'crachas', 'quantitativo', searchQuery],
    queryFn: () => {
      return getQuantitativos(
        searchQuery.data_inicial.toISOString(),
        searchQuery.data_final.toISOString(),
      );
    },
  });

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/admin"
          color="inherit"
          underline="hover"
        >
          Administração
        </Link>

        <Link
          component={RouterLink}
          to="/admin/crachas/analisar"
          color="inherit"
          underline="hover"
        >
          Crachás
        </Link>

        <Typography color="secondary" fontWeight="bold">
          Quantitativos
        </Typography>
      </Breadcrumbs>

      <Box
        component="section"
        sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 3 }}
      >
        <Controller
          control={control}
          name="data_inicial"
          render={({ field }) => (
            <DatePicker
              label="Data inicial"
              ref={field.ref}
              value={field.value}
              onChange={event => {
                field.onChange(event);
              }}
              slotProps={{
                textField: {
                  onBlur: field.onBlur,
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="data_final"
          render={({ field }) => (
            <DatePicker
              label="Data final"
              ref={field.ref}
              value={field.value}
              onChange={event => {
                field.onChange(event);
              }}
              slotProps={{
                textField: {
                  onBlur: field.onBlur,
                },
              }}
            />
          )}
        />
      </Box>

      <section>
        <header>
          <Typography>
            Quantitativos entre as datas{' '}
            <strong>
              {searchQuery.data_inicial.toLocaleDateString('pt-br')}
            </strong>{' '}
            à{' '}
            <strong>
              {searchQuery.data_final.toLocaleDateString('pt-br')}
            </strong>
          </Typography>
        </header>

        {isQuantitativosLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}

        {isQuantitativosSuccess && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Quantidade</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.entries(quantitativos).map(([status, quantidade]) => (
                <TableRow key={status}>
                  <TableCell component="th">{capitalize(status)}</TableCell>
                  <TableCell>{quantidade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </section>
    </>
  );
}
