import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Box,
  Button,
  FormControl,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { getFolder, newFolder } from '../../../services/portal';
import { DocumentosResponse } from '../../../types';

type Folder = {
  id?: number | string;
  name: string;
  parent_folder_id: number | string;
};

export default function FormNewFolder() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data: folder } = useQuery(
    ['folder', id],
    async () => {
      if (id != null) {
        const { data } = await getFolder<DocumentosResponse>(id);
        return data;
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  const { handleSubmit, control } = useForm<Folder>({
    defaultValues: {
      name: '',
      parent_folder_id: typeof id === 'string' ? parseInt(id, 10) : undefined,
    },
  });

  const [newFolderSuccess, setNewFolderSuccess] = useState(false);

  const handleSnackbarSuccessClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setNewFolderSuccess(false);
    navigate(`/documents/folder/${id}`);
  };

  const onSubmit: SubmitHandler<Folder> = async data => {
    const formData = new FormData();
    formData.append('folder[name]', data.name);
    formData.append(
      'folder[parent_folder_id]',
      typeof id === 'string' ? id : '',
    );

    await newFolder(formData);
    setNewFolderSuccess(true);
  };

  return (
    <Box>
      <Snackbar
        open={newFolderSuccess}
        autoHideDuration={6000}
        onClose={handleSnackbarSuccessClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleSnackbarSuccessClose}>
          Nova pasta criada com sucesso
        </Alert>
      </Snackbar>

      <Box mb={3}>
        <Breadcrumbs showHomeLink={false}>
          {folder?.folder.all_parents?.map(item => (
            <Link
              key={item.id}
              color="inherit"
              underline="hover"
              component={RouterLink}
              to={`/documents/folder/${item.id}`}
            >
              {item.name}
            </Link>
          ))}

          <Typography color="secondary" fontWeight="bold">
            {folder?.folder.name}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Typography component="h3" variant="h5" mb={1}>
        Criar nova pasta
      </Typography>

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} alignItems="start">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Nome"
                    id="folder-nome"
                    onChange={onChange}
                    error={error != null}
                    value={value}
                    color={error != null ? 'error' : undefined}
                    helperText={
                      error != null ? 'Este campo não pode ficar vazio' : null
                    }
                  />
                </FormControl>
              )}
            />

            <Button
              type="submit"
              startIcon={<FontAwesomeIcon icon={faSave} />}
              sx={{ textTransform: 'none' }}
            >
              Salvar
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}
