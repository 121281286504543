import { SyntheticEvent, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderPages from '../../../components/HeaderPages';

import AccordionServicos from '../AccordionServicos';

import licencasList from './Licencas.constants';

export default function Licencas(): JSX.Element {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/servicos"
          color="inherit"
          underline="hover"
        >
          Serviços
        </Link>
        <Typography color="secondary" fontWeight="bold">
          Licenças
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Licenças" />

      <Box component="section">
        <Typography component="p" mb={3}>
          As licenças são concedidas aos servidores conforme a Lei nº 1818/2007
          e aos Defensores Públicos conforme a Lei Complementar nº 55/2009:
        </Typography>

        {licencasList.map(licenca => (
          <AccordionServicos
            key={licenca.id}
            data={licenca}
            expanded={expanded}
            handleChange={handleChange}
          />
        ))}
      </Box>
    </Box>
  );
}
