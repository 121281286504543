import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';

import { isSameDay } from 'date-fns';

import { MouseEvent, useState } from 'react';

import {
  DateFormatter,
  DayClickEventHandler,
  DayPicker,
} from 'react-day-picker';

import { useQuery } from '@tanstack/react-query';
import 'react-day-picker/dist/style.css';

import { useAuth } from '../../../context/AuthProvider';
import { getRemainingMonthEvents } from '../../../services/portal';

import datefnsPtBR from '../../../utils/datefnsPtBR';
import ENVIRONMENT from '../../../utils/environment';
import months from '../../../utils/months';
import AgendaDayPopover from './DayPopover';

type EventCalendar = {
  event: string;
  date: string;
  date_obj: Date;
  local: string;
  organizador: string;
  participantes: Array<string>;
};

type EventsResponse = {
  zm_auth_token: string;
  url: string;
  events?: EventCalendar[];
};

const initialEventResponse: EventsResponse = {
  zm_auth_token: '',
  url: '',
  events: [],
};

const eventsCalendarStyles = { border: '1px solid #1bbc9b' };

const formatedTime = (date: Date) => {
  return `Às ${date.getHours()}h${date.getMinutes()}m`;
};

const formatCaption: DateFormatter = month => {
  return (
    <Box fontSize="1.05rem" color={theme => theme.palette.primary.main}>
      <span>{months[month.getMonth()]}</span> <span>{month.getFullYear()}</span>
    </Box>
  );
};

export default function Agenda(): JSX.Element {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [id, setId] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorElParticipantes, setAnchorElParticipantes] =
    useState<HTMLElement | null>(null);
  const [participantes, setParticipantes] = useState<Array<string>>(['']);
  const [dialogOpen, setDialogOpen] = useState(false);

  const auth = useAuth();

  const { data: eventsCalendar = initialEventResponse } = useQuery(
    ['events-calendar', auth.usuario?.username],
    async () => {
      if (auth.usuario != null && auth.usuario.username !== '') {
        const { data } = await getRemainingMonthEvents<EventsResponse>({
          username: auth.usuario.username,
          preauth_key: process.env.REACT_APP_ZIMBRA_PREAUTH_KEY,
        });

        return data;
      }

      return initialEventResponse;
    },
    {
      enabled: ENVIRONMENT === 'development' ? false : auth.usuario != null,
      select: data => {
        const transformedData: EventsResponse = {
          ...data,
          events: data.events?.map(item => ({
            ...item,
            date_obj: new Date(item.date),
          })),
        };

        return transformedData;
      },
    },
  );

  const handleDayClick: DayClickEventHandler = (day, _, event) => {
    if (eventsCalendar.events?.some(item => isSameDay(item.date_obj, day))) {
      setId(`popover-${day.toISOString()}`);
      setAnchorEl(event.target as HTMLElement);
    } else {
      setId('');
      setAnchorEl(null);
    }
  };

  const handlePopoverOpen =
    (organizador: string, listaDeParticipantes: Array<string>) =>
    (event: MouseEvent<HTMLElement>) => {
      if (listaDeParticipantes == null) {
        setParticipantes([organizador]);
      } else {
        setParticipantes([organizador, ...listaDeParticipantes]);
      }
      setAnchorElParticipantes(event.currentTarget);
    };

  const handlePopoverClose = () => {
    setAnchorElParticipantes(null);
  };

  const open = Boolean(anchorElParticipantes);

  const handleDialogOpen = () => {
    const { zm_auth_token: zmAuthToken, url } = eventsCalendar;
    const d = new Date();
    d.setTime(d.getTime() + 15 * 60 * 1000);

    document.cookie = `ZM_AUTH_TOKEN=${zmAuthToken}; Path=/; Expires=${d.toUTCString()}; Domain=.defensoria.to.def.br`;
    setAnchorEl(null);

    if (window.innerWidth > 900) {
      setDialogOpen(true);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);

    const d = new Date();
    d.setTime(d.getTime() + 0 * 60 * 1000);

    document.cookie = `ZM_AUTH_TOKEN=''; Path=/; Expires=${d.toUTCString()}; Domain=.defensoria.to.def.br`;
  };

  return (
    <Box
      sx={{
        border: '2px solid #f1f1f2',
        borderRadius: 1,
        '& .rdp': {
          margin: 0,
        },
        '& .rdp-caption': {
          bgcolor: '#f1f2f2',
          py: 0.5,
        },
        '& .rdp-day_outside': {
          color: theme => theme.palette.grey[500],
        },
        '--rdp-accent-color': 'primary.main',
        '--rdp-outline': '2px solid transparent',
        '& .rdp-nav_button:hover, .rdp-nav_button:focus:not([disabled])': {
          bgcolor: 'action.hover',
        },
        '.rdp-day_selected:not([disabled]), .rdp-day_selected:focus:not([disabled]), .rdp-day_selected:active:not([disabled]), .rdp-day_selected:hover:not([disabled])':
          {
            border: '2px solid #ececec',
            backgroundColor: 'action.selected',
            color: '#333',
          },
        '& .rdp-nav_button, & .rdp-nav_button:focus:not([disabled])': {
          color: 'primary.main',
        },
        '& .rdp-nav_button': {
          mr: 1,
        },
        '& .rdp-cell .rdp-button:hover:not([disabled])': {
          backgroundColor: 'action.hover',
        },
        '& .rdp-day_today:not(.rdp-day_outside):not(.rdp-day_selected)': {
          color: 'primary.main',
        },
      }}
    >
      <AgendaDayPopover
        id={id}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={anchorEl != null}
      >
        <Box p={1}>
          <List>
            {selectedDate != null &&
              eventsCalendar.events
                ?.filter(item => isSameDay(item.date_obj, selectedDate))
                .map(evento => (
                  <ListItem key={evento.event}>
                    <ListItemAvatar color="blue">
                      <Avatar>
                        <FontAwesomeIcon icon={faCalendarDay} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={evento.event}
                      secondary={
                        <>
                          {evento.local.match(/https?:\/\//) !== null ? (
                            <Link
                              sx={{ display: 'block' }}
                              component="a"
                              href={evento.local}
                              underline="hover"
                              variant="body2"
                            >
                              {evento.local}
                            </Link>
                          ) : (
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                              variant="body2"
                              color="primary"
                            >
                              {evento.local}
                            </Typography>
                          )}
                          <Typography component="span" variant="body2">
                            {formatedTime(evento.date_obj)}
                          </Typography>
                          <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            aria-owns={
                              open
                                ? evento.date.replace(/(-|:|\.|0)/g, '')
                                : undefined
                            }
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen(
                              evento.organizador,
                              evento.participantes,
                            )}
                            onMouseLeave={handlePopoverClose}
                          >
                            {`Participantes: ${
                              evento.participantes !== null
                                ? evento.participantes.length + 1
                                : 1
                            }`}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
          </List>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              size="small"
              endIcon={<FontAwesomeIcon icon="angle-right" />}
              title="Visualizar no Webmail"
              variant="text"
              onClick={handleDialogOpen}
              onTouchStart={handleDialogOpen}
            >
              Visualizar no Webmail
            </Button>
          </Box>
        </Box>
      </AgendaDayPopover>

      <Popover
        id="popover-event"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorElParticipantes}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 1 }}>
          <Typography component="span" color="grey.700" sx={{ mb: 2 }}>
            {`Participantes: ${participantes.length}`}
          </Typography>
          {participantes.map(participante => (
            <Typography key={participante} color="grey.600" variant="body2">
              {participante}
            </Typography>
          ))}
        </Box>
      </Popover>

      <Dialog
        fullWidth
        maxWidth={false}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          mx: { xl: 40, lg: 30, md: 20, sm: 10, sx: 0 },
          mt: 10,
        }}
      >
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            endIcon={<FontAwesomeIcon icon="xmark" />}
            sx={{ my: 0, mr: 2 }}
          />
        </DialogActions>
        <DialogContent dividers={false} sx={{ pt: 0 }}>
          <iframe
            id="iframe_email"
            width="100%"
            title="Email"
            height={window.screen.height}
            src={eventsCalendar?.url}
          />
        </DialogContent>
      </Dialog>

      <ClickAwayListener onClickAway={() => setSelectedDate(undefined)}>
        <Box>
          <DayPicker
            mode="single"
            locale={datefnsPtBR}
            selected={selectedDate}
            onSelect={setSelectedDate}
            modifiers={{
              eventsCalendar:
                eventsCalendar.events != null
                  ? eventsCalendar.events?.map(item => item.date_obj)
                  : [],
            }}
            modifiersStyles={{ eventsCalendar: eventsCalendarStyles }}
            showOutsideDays
            onDayClick={handleDayClick}
            formatters={{ formatCaption }}
          />
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
