import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '../../context/AuthProvider';
import { getToken, setAuthorizationToken } from '../../services/portal';
import { LocationType } from '../auth.constants';

export default function AuthEgide() {
  const { state } = useLocation() as LocationType;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { usuario, signin, status } = useAuth();

  const code = searchParams.get('code');

  useEffect(() => {
    if (status === 'idle' || status === 'expired') {
      if (code == null) {
        sessionStorage.setItem(
          '@conhecimento.pathname',
          `${state?.from?.pathname}`,
        );
        window.location.href = `${process.env.REACT_APP_PORTAL_API_URL}/auth/new`;
      } else if (usuario == null) {
        getToken(code).then(data => {
          if (data != null) {
            signin({
              token: data.token,
              usuario: data.usuario,
              servidor: data.servidor,
              callback() {
                setAuthorizationToken(data.token);
                navigate(
                  sessionStorage.getItem('@conhecimento.pathname') || '/',
                );
              },
            });
          }
        });
      }
    } else if (status === 'unauthenticated') {
      sessionStorage.removeItem('@conhecimento.pathname');
      window.location.href = `${process.env.REACT_APP_PORTAL_API_URL}/auth/destroy`;
    } else if (status === 'authenticated' && usuario == null) {
      navigate('/auth/not-found');
    }
  }, [code, navigate, signin, state?.from?.pathname, status, usuario]);

  return null;
}
