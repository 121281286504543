import { useAuth } from '../../../../../context/AuthProvider';
import { getServidores } from '../../../../../services/portal';
import { useQuery } from '@tanstack/react-query';

export function useServidorQuery(matricula: string) {
  const { servidor } = useAuth();

  return useQuery({
    queryKey: ['servidores', matricula],
    queryFn: async () => {
      const { data } = await getServidores({
        'q[matricula_eq]': matricula,
      });

      return data.results[0];
    },
    enabled: servidor != null,
  });
}
