const getFileFormat = (blobType: string) => {
  const lastIndex = blobType.lastIndexOf('/');

  if (lastIndex < 0) {
    return null;
  }

  return blobType.substring(lastIndex + 1);
};

export default getFileFormat;
