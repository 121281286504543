import { Box } from '@mui/material';

type PostifyHtmlContentProps = {
  content: string;
};

export default function PostifyHtmlContent({
  content,
}: PostifyHtmlContentProps) {
  return (
    <Box
      component="article"
      dangerouslySetInnerHTML={{ __html: content }}
      sx={{
        '& .fr-video iframe': {
          maxWidth: '100% !important',
        },
        '& a': {
          color: 'secondary.main',
          textDecoration: 'none',
          ':hover': {
            color: 'secondary.dark',
          },
        },
        '& p': {
          wordBreak: 'break-word',
        },
      }}
    />
  );
}
