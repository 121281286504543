import { MouseEvent, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../../context/AuthProvider';
import { getUnreadInbox } from '../../../services/portal';
import ENVIRONMENT from '../../../utils/environment';

type UnreadInboxEmail = {
  id: number | string;
  subject: string;
  url: string;
};

type UnreadInbox = {
  zm_auth_token: string;
  count: number;
  emails: UnreadInboxEmail[];
};

const initialUnreadInbox: UnreadInbox = {
  zm_auth_token: '',
  count: 0,
  emails: [],
};

export default function PopoverEmail(): JSX.Element {
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [zmAuthToken, setZmAuthToken] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [urlEmail, setUrlEmail] = useState<string>('');
  const isMenuOpen = Boolean(anchorEl);

  const handlePopoverEmailOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverEmailClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = (url: string | undefined) => () => {
    if (typeof url === 'string') {
      setUrlEmail(url);
    }

    const d = new Date();
    d.setTime(d.getTime() + 15 * 60 * 1000);

    document.cookie = `ZM_AUTH_TOKEN=${zmAuthToken}; Path=/; Expires=${d.toUTCString()}; Domain=.defensoria.to.def.br`;
    setAnchorEl(null);

    if (window.innerWidth > 900) {
      setDialogOpen(true);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);

    const d = new Date();
    d.setTime(d.getTime() + 0 * 60 * 1000);

    document.cookie = `ZM_AUTH_TOKEN=''; Path=/; Expires=${d.toUTCString()}; Domain=.defensoria.to.def.br`;
  };

  const { data: unreadInbox = initialUnreadInbox } = useQuery(
    ['unread-emails', auth.usuario?.username],
    async () => {
      if (auth.usuario != null && auth.usuario.username !== '') {
        const { data } = await getUnreadInbox<UnreadInbox>({
          username: auth.usuario.username,
          preauth_key: process.env.REACT_APP_ZIMBRA_PREAUTH_KEY,
        });

        setZmAuthToken(data.zm_auth_token);
        return data;
      }

      return {
        count: 0,
        emails: [],
      };
    },
    {
      enabled: ENVIRONMENT === 'development' ? false : auth.usuario != null,
    },
  );

  const menuId = 'primary-search-emails';

  const renderPopoverEmails = (
    <Popover
      id={menuId}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handlePopoverEmailClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {unreadInbox?.emails?.length > 0 ? (
        <List dense>
          {unreadInbox?.emails?.map(email => (
            <ListItem key={email.id}>
              <ListItemIcon sx={{ minWidth: 15 }}>
                <FontAwesomeIcon icon="circle-dot" fontSize={10} />
              </ListItemIcon>
              <ListItemButton onClick={handleDialogOpen(email.url)}>
                <ListItemText>
                  <Typography color="grey.700" maxWidth={500} noWrap>
                    {email.subject}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography p={2}>Nenhum email para exibir</Typography>
      )}
    </Popover>
  );

  return (
    <Box>
      {unreadInbox?.emails?.length > 0 ? (
        <Box color="grey.500">
          <IconButton
            color="inherit"
            size="medium"
            edge="end"
            aria-label="Conta do usuário atual"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handlePopoverEmailOpen}
            sx={{ mr: 1, width: 35, height: 35 }}
          >
            <Badge badgeContent={unreadInbox.count} color="info">
              <FontAwesomeIcon icon="envelope" />
            </Badge>
          </IconButton>
          {renderPopoverEmails}
        </Box>
      ) : (
        <Box color="grey.500">
          <IconButton
            color="inherit"
            size="medium"
            edge="end"
            aria-label="Conta do usuário atual"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleDialogOpen('https://mail.defensoria.to.def.br')}
            sx={{ mr: 1, width: 35, height: 35 }}
          >
            <Badge badgeContent={unreadInbox.count} color="info">
              <FontAwesomeIcon icon="envelope" />
            </Badge>
          </IconButton>
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth={false}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          mx: { xl: 40, lg: 30, md: 20, sm: 10, sx: 0 },
          mt: 10,
        }}
      >
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            endIcon={<FontAwesomeIcon icon="xmark" />}
            sx={{ my: 0, mr: 2 }}
          />
        </DialogActions>
        <DialogContent dividers={false}>
          <iframe
            id="iframe_email"
            width="100%"
            title="Email"
            height={window.screen.height}
            src={urlEmail}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
