import axios, { AxiosResponse } from 'axios';

type OdinAPIPaginatedResponseType = {
  current_page: number;
  limit_value: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
};

const odinAPI = axios.create({
  baseURL: process.env.REACT_APP_ODIN_URL,
});

const getDocumentosOdin = async <T = OdinAPIPaginatedResponseType>(
  origem: string,
  tipo: string,
  page: number,
  searchParams: object | undefined,
): Promise<AxiosResponse<T>> => {
  const params = {
    origem,
    tipo,
    ...searchParams,
    page,
    per_page: '10',
  };
  return odinAPI.get('/documentos', { params });
};

export default getDocumentosOdin;
