import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { faEdit, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import Breadcrumbs from '../../../components/Breadcrumbs';
import PagesHeader from '../../../components/HeaderPages';

import LoadingState from '../../../components/LoadingState';
import { Usuario } from '../../../context/AuthProvider';
import { getUsuarios } from '../../../services/portal';

type FilterInputs = Partial<Pick<Usuario, 'username' | 'role'>>;

export default function Usuarios(): JSX.Element {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const [filter, setFilter] = useState<FilterInputs>();

  const filterMemoized = useMemo<FilterInputs>(
    () => ({
      username: filter?.username,
      role: filter?.role,
    }),
    [filter?.role, filter?.username],
  );

  const { register, handleSubmit } = useForm<FilterInputs>();

  const {
    data: usuarios = [],
    isLoading,
    isError,
  } = useQuery(['usuarios', page, filterMemoized], async () => {
    const { data } = await getUsuarios({
      page,
      'q[username_cont]': filterMemoized.username,
      'q[role_eq]': filterMemoized.role,
    });

    setTotalPages(data.pagy.last);

    return data.results;
  });

  const onSubmit: SubmitHandler<FilterInputs> = data => {
    setPage(1);
    setFilter(data);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
        <CircularProgress color="inherit" size={24} />
      </Box>
    );
  }

  return (
    <LoadingState
      isLoading={isLoading}
      isError={isError}
      title={
        <Box>
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Administração
            </Link>

            <Typography color="secondary" fontWeight="bold">
              Usuários
            </Typography>
          </Breadcrumbs>

          <PagesHeader title="Usuários" />
        </Box>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 4 }}>
          <Box sx={{ width: '40%' }}>
            <Typography
              component="h2"
              fontWeight="bold"
              color="grey"
              sx={{ mb: 1 }}
            >
              Buscar por Username:
            </Typography>

            <Paper
              elevation={3}
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 50,
                height: '40px',
              }}
            >
              <InputBase
                fullWidth
                placeholder="Username"
                sx={{ p: 2 }}
                {...register('username')}
              />
            </Paper>
          </Box>

          <Box sx={{ width: '40%' }}>
            <Typography
              component="h2"
              fontWeight="bold"
              color="grey"
              sx={{ mb: 1 }}
            >
              Buscar por Tipo:
            </Typography>

            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 50,
                height: '40px',
              }}
            >
              <Select
                fullWidth
                labelId="select-tipos-usuario"
                id="select-tipos-usuario"
                sx={{
                  borderRadius: 50,
                  height: 40,
                }}
                defaultValue=""
                {...register('role')}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="comum">Comum</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="rh">RH</MenuItem>
                <MenuItem value="gestao_pessoas">Gestão de Pessoas</MenuItem>
                <MenuItem value="editor">Editor</MenuItem>
              </Select>
            </Paper>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{ alignSelf: 'end', mb: 0.25 }}
          >
            <FontAwesomeIcon icon={faSearch} />
            Buscar
          </Button>
        </Box>
      </form>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {usuarios.map(usuario => (
              <TableRow key={usuario.id}>
                <TableCell>
                  <Typography>{usuario.email}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{usuario.username}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{usuario.role}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{usuario.cpf}</Typography>
                </TableCell>

                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      component={RouterLink}
                      to={`/admin/usuarios/${usuario.id}`}
                      sx={{ width: 40, height: 40 }}
                      color="info"
                      edge="end"
                      aria-label="Exibir usuário"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </IconButton>

                    <IconButton
                      component={RouterLink}
                      to={`/admin/usuarios/${usuario.id}/edit`}
                      sx={{ width: 40, height: 40 }}
                      color="success"
                      edge="end"
                      aria-label="Editar usuário"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        page={page}
        count={totalPages}
        onChange={(event: React.ChangeEvent<unknown>, pageNumber: number) =>
          setPage(pageNumber)
        }
      />
    </LoadingState>
  );
}
