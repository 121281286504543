import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type AccordionProps = MuiAccordionProps & {
  title: string;
  children: JSX.Element;
};

export default function Accordion({
  title,
  children,
  ...props
}: AccordionProps): JSX.Element {
  return (
    <MuiAccordion {...props}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon="angle-right" color="white" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          bgcolor: theme => theme.palette.primary.main,
          color: 'white',
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-content': {
            marginLeft: theme => theme.spacing(1),
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            marginLeft: theme => theme.spacing(1),
          },
        }}
      >
        <Box>
          <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
}
