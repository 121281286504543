import { Box, Button, Divider, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Link as RouterLink, useParams } from 'react-router-dom';

import DateTimeFormatter from '../../../components/DateTimeFormatter';
import { useAuth } from '../../../context/AuthProvider';

import { getCracha } from '../../../services/portal';

const solicitacoesMap: Record<string, string> = {
  primeira_via: '1ª VIA',
  segunda_via: '2ª VIA',
  cordao: 'CORDÃO',
};

function CheckBox({ checked }: { checked: boolean }) {
  return checked ? (
    <Box component="span" sx={{ fontSize: '1.5rem' }}>
      &#9745;
    </Box>
  ) : (
    <Box component="span" sx={{ fontSize: '1.5rem' }}>
      &#9744;
    </Box>
  );
}

export default function CrachasComprovante() {
  const { id } = useParams<{ id: string }>();
  const { servidor } = useAuth();

  const {
    data: cracha,
    isFetching,
    isError,
  } = useQuery(
    ['cracha', id],
    async () => {
      if (id != null) {
        const response = await getCracha(id);

        if (response.matricula === servidor?.matricula) {
          return response;
        }
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  if (isFetching) {
    return <Box sx={{ p: 3, textAlign: 'center' }}>Carregando...</Box>;
  }

  if (isError) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>Crachá não localizado</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        color: '#2a2a2a',
        maxWidth: '85%',
        py: 3,
        mx: 'auto',
        '@media print': {
          maxWidth: 'none',
          p: 3,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #555',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            p: 2,
            flex: 3,
          }}
        >
          <Box
            component="img"
            src="/crachas/background-logo-2.png"
            width={300}
          />

          <Typography fontWeight="bold" variant="body2" textAlign="center">
            DIRETORIA DE GESTÃO DE PESSOAS E FOLHA DE PAGAMENTO
          </Typography>
        </Box>

        <Divider orientation="vertical" sx={{ borderColor: '#555' }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            flex: 2,
          }}
        >
          <Typography fontWeight="bold" variant="h6" textAlign="center">
            REQUERIMENTO DE {solicitacoesMap[cracha?.solicitacao || '']} DE
            CRACHÁ
          </Typography>

          {cracha?.status === 'cancelado' && (
            <Typography fontWeight="bold" variant="caption">
              (CANCELADO)
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ border: '1px solid #555', px: 2, py: 1 }}>
        <Typography fontWeight="bold" fontSize="1.2rem">
          Nome do(a) interessado(a)
        </Typography>

        <Typography>{servidor?.nome}</Typography>
      </Box>

      <Box sx={{ display: 'flex', border: '1px solid #555' }}>
        <Box sx={{ px: 2, py: 1, flex: 3 }}>
          <Typography fontWeight="bold" fontSize="1.2rem">
            Cargo
          </Typography>
          <Typography>{servidor?.cargo}</Typography>
        </Box>

        <Divider orientation="vertical" sx={{ borderColor: '#555' }} />

        <Box sx={{ px: 2, py: 1, flex: 1 }}>
          <Typography fontWeight="bold" fontSize="1.2rem">
            Matrícula
          </Typography>
          <Typography>{servidor?.matricula}</Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', border: '1px solid #555' }}>
        <Box sx={{ px: 2, py: 1, flex: 2 }}>
          <Typography fontWeight="bold" fontSize="1.2rem">
            Lotação
          </Typography>
          <Typography>{servidor?.lotacao_atual}</Typography>
        </Box>

        <Divider orientation="vertical" sx={{ borderColor: '#555' }} />

        <Box sx={{ px: 2, py: 1, flex: 1 }}>
          <Typography fontWeight="bold" fontSize="1.2rem">
            Comarca
          </Typography>
          <Typography>{servidor?.comarca}</Typography>
        </Box>
      </Box>

      <Box sx={{ px: 2, py: 1, border: '1px solid #555' }}>
        <Typography fontWeight="bold" fontSize="1.2rem">
          Cordão
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <CheckBox checked={cracha?.cordao || false} />
          <Typography>Sim</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <CheckBox checked={!cracha?.cordao || false} />
          <Typography>Não</Typography>
        </Box>
      </Box>

      <Box sx={{ px: 2, py: 1, border: '1px solid #555' }}>
        <Typography fontWeight="bold" mb={3} fontSize="1.2rem">
          CÓDIGO DE VALIDAÇÃO
        </Typography>

        <Typography textAlign="center" fontSize="1.5rem">
          {cracha?.validation_code_formatted}
        </Typography>
      </Box>

      <Box sx={{ p: 3 }}>
        <Typography textAlign="center" fontSize="1.15rem" color="#333">
          Requerimento criado em{' '}
          <DateTimeFormatter datetime={cracha?.created_at || ''}>
            {({ dateFormatted, timeFormatted }) => (
              <Box component="span" fontWeight="bold">
                {dateFormatted} às {timeFormatted}
              </Box>
            )}
          </DateTimeFormatter>
        </Typography>
      </Box>

      <Box
        sx={{
          displayPrint: 'none',
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <Button variant="contained" onClick={() => window.print()}>
          Imprimir
        </Button>

        <Button
          component={RouterLink}
          to={`/crachas/meus-crachas/${cracha?.id}`}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}
