import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, BoxProps, styled } from '@mui/material';

const Img = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  backgroundColor: 'transparent',
});

type LogoProps = BoxProps & {
  filesvg: string;
  filepng: string;
};

export default function Logo({
  filesvg,
  filepng,
  ...props
}: LogoProps): JSX.Element {
  const [logoUrl, setLogoUrl] = useState(filepng);

  return (
    <Box {...props} width="100%">
      <Box component={Link} to="/" width="100%" height="100%" bgcolor="white">
        <Img
          src={logoUrl}
          onLoad={() => {
            setLogoUrl(filesvg);
          }}
        />
      </Box>
    </Box>
  );
}
