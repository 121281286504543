type DateTimeFormatterProps = {
  datetime: string;
  children: (args: {
    timeFormatted: string;
    dateFormatted: string;
  }) => JSX.Element;
};

export default function DateTimeFormatter({
  datetime,
  children,
}: DateTimeFormatterProps) {
  if (datetime === '') {
    return null;
  }

  const date = new Date(datetime);

  const timeFormatted = new Intl.DateTimeFormat('pt-BR', {
    timeStyle: 'short',
  }).format(date);

  const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'short',
  }).format(date);

  return children({ dateFormatted, timeFormatted });
}
