import { Data } from '../types';

const afastamentosList: Data[] = [
  {
    id: 1,
    title: 'Para servir a outro órgão ou entidade',
    docs_download: [
      {
        id: 1,
        title:
          'Ofício do órgão solicitando ao Defensor(a) Público(a) Geral a cessão',
      },
    ],
  },
  {
    id: 2,
    title: 'Para exercer mandato eletivo',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 3,
    title: 'Para estudar no país ou no exterior',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 4,
    title: 'Para realizar missão oficial no exterior',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 5,
    title:
      'Para atender convocação da Justiça Eleitoral, durante o período eletivo',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
  {
    id: 6,
    title: 'Para servir no Tribunal do Júri',
    docs_download: [
      {
        id: 1,
        title: 'Requerimento preenchido por completo, datado e assinado',
        idFile: '410',
      },
      {
        id: 2,
        title: 'Documentos comprobatórios',
      },
    ],
  },
];

export default afastamentosList;
