import { Box, Button, Slider, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { getCracha, updateCracha } from '../../../services/portal';
import CrachaPreview from '../components/Preview';

export default function CrachasImprimir() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: cracha,
    isFetching,
    isError,
  } = useQuery(
    ['cracha', id],
    async () => {
      if (id != null) {
        return getCracha(id);
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  const [crachaStyles, setCrachaStyles] = useState({
    fontSizeNome: 15,
    fontSizeCargo: 9,
    lineHeightNomeCompletoMatricula: 1.2,
    fontSizeNomeCompletoMatricula: 10,
  });

  const marcarComoImpresso = useCallback(async () => {
    if (id != null) {
      await updateCracha(id, { status: 'impresso' });
      navigate(`/admin/crachas/analisar/${id}`);
    }
  }, [id, navigate]);

  useEffect(() => {
    window.addEventListener('afterprint', marcarComoImpresso);

    return () => {
      window.removeEventListener('afterprint', marcarComoImpresso);
    };
  }, [marcarComoImpresso]);

  if (isFetching) {
    return <Box>Carregando...</Box>;
  }

  if (isError) {
    return (
      <Box>
        <Typography>Crachá não localizado</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 3,
        p: 5,
        '@media print': { display: 'initial', p: 0, gap: 0 },
      }}
    >
      <Box
        sx={{
          maxWidth: 220,
          '@media print': { maxWidth: 'none', mx: 0 },
        }}
      >
        <CrachaPreview
          cargo={cracha?.cargo || ''}
          matricula={cracha?.matricula || ''}
          nome={cracha?.nome || ''}
          imgSrc={cracha?.url || ''}
          styles={{
            photoWidth: 75,
            nomeFontSize: crachaStyles.fontSizeNome,
            nomeCompletoMatriculaFontSize:
              crachaStyles.fontSizeNomeCompletoMatricula,
            nomeCompletoMatriculaLineHeight:
              crachaStyles.lineHeightNomeCompletoMatricula,
            cargoFontSize: crachaStyles.fontSizeCargo,
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          displayPrint: 'none',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography variant="h5" textAlign="center">
          Modificações
        </Typography>

        <Box>
          <Typography fontWeight="bold">Tamanho da fonte do nome:</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ minWidth: 40 }} component="span">
              {crachaStyles.fontSizeNome}
            </Box>

            <Slider
              aria-label="Font do nome"
              valueLabelDisplay="auto"
              step={0.5}
              marks
              min={10}
              max={30}
              value={crachaStyles.fontSizeNome}
              onChange={(event, value) =>
                setCrachaStyles(prev => ({
                  ...prev,
                  fontSizeNome: value as number,
                }))
              }
            />
          </Box>
        </Box>

        <Box>
          <Typography fontWeight="bold">Tamanho da fonte do cargo:</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ minWidth: 40 }} component="span">
              {crachaStyles.fontSizeCargo}
            </Box>

            <Slider
              aria-label="Font do nome"
              valueLabelDisplay="auto"
              step={0.5}
              marks
              min={5}
              max={30}
              value={crachaStyles.fontSizeCargo}
              onChange={(event, value) =>
                setCrachaStyles(prev => ({
                  ...prev,
                  fontSizeCargo: value as number,
                }))
              }
            />
          </Box>
        </Box>

        <Box>
          <Typography fontWeight="bold">
            Altura da linha do nome completo e matrícula:
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ minWidth: 40 }} component="span">
              {crachaStyles.lineHeightNomeCompletoMatricula}
            </Box>

            <Slider
              aria-label="Font do nome"
              valueLabelDisplay="auto"
              step={0.05}
              marks
              min={1}
              max={2}
              value={crachaStyles.lineHeightNomeCompletoMatricula}
              onChange={(event, value) =>
                setCrachaStyles(prev => ({
                  ...prev,
                  lineHeightNomeCompletoMatricula: value as number,
                }))
              }
            />
          </Box>
        </Box>

        <Box>
          <Typography fontWeight="bold">
            Tamanho da fonte do nome completo e matrícula:
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ minWidth: 40 }} component="span">
              {crachaStyles.fontSizeNomeCompletoMatricula}
            </Box>

            <Slider
              aria-label="Fonte do nome"
              valueLabelDisplay="auto"
              step={0.5}
              marks
              min={5}
              max={30}
              value={crachaStyles.fontSizeNomeCompletoMatricula}
              onChange={(event, value) =>
                setCrachaStyles(prev => ({
                  ...prev,
                  fontSizeNomeCompletoMatricula: value as number,
                }))
              }
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button onClick={() => window.print()}>Imprimir</Button>

          <Button component={RouterLink} to="/admin/crachas/analisar">
            Voltar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
