import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';

import DateTimeFormatter from '../../../components/DateTimeFormatter';
import { getCrachas } from '../../../services/portal';

import {
  justificativasMap,
  solicitacoesMap,
  statusMap,
} from '../Crachas.constants';

type FilterFields = {
  nome: string;
  matricula: string;
  status: string;
  solicitacao: string;
  justificativa: string;
};

export default function CrachasAnalisar() {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number>();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const [filter, setFilter] = useState<Partial<FilterFields>>({
    status: 'analisar',
  });

  const { data: crachas = [] } = useQuery(
    ['crachas', page, filter],
    async () => {
      const payload = {
        page,
        'q[nome_cont]': filter?.nome,
        'q[matricula_cont]': filter?.matricula,
        'q[status_eq]': filter?.status,
        'q[solicitacao_eq]': filter?.solicitacao,
        'q[observacao_requerente_eq]': filter?.justificativa,
      };

      const {
        data: {
          results,
          pagy: { last: pages },
        },
      } = await getCrachas(payload);
      setPageCount(pages);
      return results;
    },
  );

  const { register, handleSubmit } = useForm<FilterFields>();

  const onSubmit: SubmitHandler<FilterFields> = data => {
    const transformedData = Object.fromEntries(
      Object.entries(data).map(([k, v]) => [k, v !== '' ? v : undefined]),
    );

    setPage(1);
    setFilter(transformedData as FilterFields);
  };

  return (
    <>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/admin"
          color="inherit"
          underline="hover"
        >
          Administração
        </Link>

        <Link
          component={RouterLink}
          to="/admin/crachas/analisar"
          color="inherit"
          underline="hover"
        >
          Crachás
        </Link>
      </Breadcrumbs>

      <Typography sx={{ mb: 2 }} variant="h5">
        Analisar crachás
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 1 }}>
          <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
            <TextField label="Nome" fullWidth {...register('nome')} />

            <TextField label="Matrícula" fullWidth {...register('matricula')} />
          </Box>

          <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>

              <Select
                labelId="status-select-label"
                id="status-select"
                label="Status"
                defaultValue="analisar"
                {...register('status')}
              >
                <MenuItem value="">Todos</MenuItem>

                {Object.entries(statusMap).map(([id, nome]) => (
                  <MenuItem key={id} value={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="solicitacao-select-label">Solicitação</InputLabel>

              <Select
                labelId="solicitacao-select-label"
                id="solicitacao-select"
                label="Solicitação"
                defaultValue=""
                {...register('solicitacao')}
              >
                <MenuItem value="">Nenhum</MenuItem>

                {Object.entries(solicitacoesMap).map(([id, nome]) => (
                  <MenuItem key={id} value={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="justificativa-select-label">
                Justificativa
              </InputLabel>

              <Select
                labelId="justificativa-select-label"
                id="justificativa-select"
                label="Justificativa"
                defaultValue=""
                {...register('justificativa')}
              >
                <MenuItem value="">Nenhum</MenuItem>

                {Object.entries(justificativasMap).map(([id, nome]) => (
                  <MenuItem key={id} value={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="submit" variant="contained">
              Pesquisar
            </Button>
          </Box>
        </Box>
      </form>

      <Box
        sx={{
          bgcolor: 'grey.100',
          p: 2,
          borderRadius: 1,
          display: 'flex',
          gap: 1,
        }}
      >
        <Link
          component={RouterLink}
          to="/admin/crachas/relatorios"
          underline="hover"
        >
          Outros filtros
        </Link>

        <Link
          component={RouterLink}
          to="/admin/crachas/relatorios/quantitativo"
          underline="hover"
        >
          Relatórios
        </Link>
      </Box>

      <Table sx={{ mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Solicitação</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {crachas.map(cracha => (
            <TableRow key={cracha.id}>
              <TableCell>
                <Typography>{cracha.nome}</Typography>
                <Typography variant="subtitle2" color="grey.700">
                  Matrícula: {cracha.matricula}
                </Typography>
              </TableCell>

              <TableCell>{solicitacoesMap[cracha.solicitacao || '']}</TableCell>

              <TableCell>
                <DateTimeFormatter datetime={cracha.created_at}>
                  {({ dateFormatted, timeFormatted }) => (
                    <>
                      <Typography>{dateFormatted}</Typography>
                      <Typography>{timeFormatted}</Typography>
                    </>
                  )}
                </DateTimeFormatter>
              </TableCell>

              <TableCell>{statusMap[cracha.status || '']}</TableCell>

              <TableCell>
                <Button
                  component={RouterLink}
                  to={`/admin/crachas/analisar/${cracha.id}`}
                >
                  Exibir
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
    </>
  );
}
