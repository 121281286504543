import { useMemo, useState } from 'react';

import { Box, BoxProps, Tab, Tabs } from '@mui/material';

import Header from '../../../components/Header';

import { Aniversariante } from '../../../types';
import months from '../../../utils/months';
import AniversariantesDia from './AniversariantesDia';
import AniversariantesMes from './AniversariantesMes';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type AniversariantesProps = BoxProps & {
  aniversariantesDia: Aniversariante[];
  aniversariantesMes: Aniversariante[];
};

export default function Aniversariantes({
  aniversariantesDia,
  aniversariantesMes,
  ...props
}: AniversariantesProps): JSX.Element {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const today = useMemo(() => new Date(), []);

  const labelAniversariantesMes = `Aniversariantes de ${
    months[today.getMonth()]
  }`;

  return (
    <Box {...props}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="Tabs Aniversariantes"
        >
          <Tab
            label="Aniversariantes do Dia"
            {...a11yProps(0)}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
          />

          <Tab
            label={labelAniversariantesMes}
            {...a11yProps(1)}
            sx={{ textTransform: 'none', fontSize: '1rem' }}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <AniversariantesDia aniversariantes={aniversariantesDia} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AniversariantesMes aniversariantes={aniversariantesMes} />

        <Box mt={1}>
          <Header title="" to="/aniversariantes" titleButton="Ver Todos" />
        </Box>
      </TabPanel>
    </Box>
  );
}
