import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cancel, CheckCircle } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';

import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { useAuth } from '../../context/AuthProvider';
import { sendEmail } from '../../services/portal';

const listOptionsEmails = [
  { id: 1, email: 'publicidade.dpe@gmail.com' },
  { id: 2, email: 'jornalismo@defensoria.to.def.br' },
  { id: 3, email: 'dicom@defensoria.to.def.br' },
];

const validationSchema = yup.object().shape({
  user: yup.string().required('Campo obrigatório'),
  from: yup.string().required('Campo obrigatório'),
  to: yup.string().required('Campo obrigatório'),
  subject: yup.string().required('Campo obrigatório'),
  attachments: yup
    .mixed()
    .test('type', 'Somente arquivos png ou jpg', value => {
      return (
        Array.isArray(value) &&
        ['image/jpeg', 'image/jpg', 'image/png'].includes(value[0].type)
      );
    }),
  message: yup.string().required('Campo obrigatório'),
});

type Email = yup.InferType<typeof validationSchema>;

export default function FormComunicacao(): JSX.Element {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm<Email>({
    resolver: yupResolver(validationSchema),
  });
  const [age] = useState('');
  const [anexos, setAnexos] = useState<string[]>([]);
  const { servidor } = useAuth();

  const onSubmit: SubmitHandler<Email> = async data => {
    const formData = new FormData();
    formData.append('sendmail[user]', data.user);
    formData.append('sendmail[from]', data?.from);
    formData.append('sendmail[to]', data.to);
    formData.append('sendmail[subject]', data.subject);
    formData.append('sendmail[message]', data.message);
    if (data.attachments != null) {
      Object.values(data.attachments).forEach(attachment => {
        if (typeof attachment === 'string') {
          formData.append('sendmail[files][]', attachment);
        }
      });
    }

    await sendEmail(formData);
  };

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const attachments: FileList = event.target.files;
      const names = Object.values(attachments).map(
        attachment => attachment.name,
      );

      setAnexos(names);
      setValue('attachments', attachments);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ subject: '', message: '', to: '', attachments: undefined });
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <Box>
      <Box component="header" mb={2} bgcolor="#f1f2f2" py={2} px={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to="/" color="inherit" underline="hover">
            Início
          </Link>
          <Typography color="secondary" fontWeight="bold">
            Sugestão de Matéria
          </Typography>
        </Breadcrumbs>
      </Box>
      <Typography sx={{ px: 2, py: 1, textAlign: 'justify' }}>
        {`Esse é um espaço para você entrar em contato, por e-mail,
          com a comunicação da DPE-TO para sugerir algum conteúdo,
          tirar alguma dúvida ou enviar alguma outra mensagem.
          Responderemos em breve.`}
      </Typography>
      <Typography sx={{ px: 2, pt: 2, pb: 0, textAlign: 'justify' }}>
        {`Se a sua demanda for por arte gráfica, te orientamos a
          usar o SEI e seguir os seguintes fluxogramas.`}
      </Typography>
      <List style={{ listStyleType: 'disc' }} sx={{ mx: 5, my: 0, pt: 0 }}>
        <ListItem key={1} style={{ display: 'list-item' }} sx={{ py: 0 }}>
          Sem desembolso financeiro, siga esse
          <Link
            component="a"
            href="https://box.defensoria.to.def.br/medias/1031/download"
            sx={{ ml: 1 }}
          >
            Fluxograma
          </Link>
          .
        </ListItem>
        <ListItem key={2} style={{ display: 'list-item' }} sx={{ py: 0 }}>
          Com desembolso financeiro, siga esse
          <Link
            component="a"
            href="https://box.defensoria.to.def.br/medias/1032/download"
            sx={{ ml: 1 }}
          >
            Fluxograma
          </Link>
          .
        </ListItem>
      </List>
      <Typography sx={{ px: 2, pt: 2, pb: 0, textAlign: 'justify' }}>
        {`Você também pode nos ligar ou mandar uma mensagem no WhatsApp
          nos telefones abaixo:`}
      </Typography>
      <List style={{ listStyleType: 'disc' }} sx={{ mx: 5, my: 0, pt: 0 }}>
        <ListItem key={1} style={{ display: 'list-item' }} sx={{ py: 0 }}>
          Chefia de Comunicação - Cléo Oliveira: (63) 9 9961-2185
        </ListItem>
        <ListItem key={2} style={{ display: 'list-item' }} sx={{ py: 0 }}>
          Coordenadora de Publicidade - Patrícia Ströher: (63) 9 9984-8374
        </ListItem>
        <ListItem key={3} style={{ display: 'list-item' }} sx={{ py: 0 }}>
          Coordenadoria de Jornalismo - Gisele França: (63) 9 9248-0266
        </ListItem>
      </List>
      <Typography sx={{ px: 2, py: 2, textAlign: 'justify' }}>
        {`Se você quiser receber as informações da Gestão como notícias,
          campanhas, eventos, avisos e outros, adicione o contato do
          Expresso DPE-TO e envie uma mensagem. As postagens acontecem,
          normalmente, em horário comercial: (63) 9 9928-2356.`}
      </Typography>
      <Paper elevation={3}>
        <Box
          component="form"
          sx={{
            mt: 2,
            p: 2,
            '& > :not(style)': { m: 1, pr: 2 },
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box pb={1}>
            <Controller
              name="user"
              control={control}
              defaultValue={servidor?.nome.toLowerCase()}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  fullWidth
                  id="outlined-basic"
                  label="De"
                  variant="outlined"
                  sx={{ p: 0, mr: 2, textTransform: 'capitalize' }}
                  onChange={onChange}
                  size="small"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !errors.user && (
                          <CheckCircle color="primary" />
                        )}
                        {!!errors.user && <Cancel color="error" />}
                      </InputAdornment>
                    ),
                  }}
                  focused
                />
              )}
            />
          </Box>
          <Box pb={1}>
            <Controller
              name="from"
              control={control}
              defaultValue={servidor?.email || ''}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  fullWidth
                  id="filled-basic"
                  label="E-mail"
                  variant="outlined"
                  sx={{ p: 0 }}
                  onChange={onChange}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !errors.from && (
                          <CheckCircle color="primary" />
                        )}
                        {!!errors.from && <Cancel color="error" />}
                      </InputAdornment>
                    ),
                  }}
                  focused
                  size="small"
                />
              )}
            />
          </Box>
          <Box pb={1}>
            <Controller
              name="to"
              control={control}
              defaultValue={age}
              render={({ field: { onChange, value } }) => (
                <FormControl error={Boolean(errors.to)} fullWidth size="small">
                  <InputLabel>Para</InputLabel>
                  <Select value={value} label="Para" onChange={onChange}>
                    {listOptionsEmails.map(optionEmail => (
                      <MenuItem key={optionEmail.id} value={optionEmail.email}>
                        {optionEmail.email}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.to?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Box>
          <Box display="flex" pb={1}>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  error={Boolean(errors.subject)}
                  helperText={errors.subject?.message}
                  fullWidth
                  id="outlined-basic"
                  label="Assunto"
                  variant="outlined"
                  sx={{ p: 0 }}
                  onChange={onChange}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !errors.subject && (
                          <CheckCircle color="primary" />
                        )}
                        {!!errors.subject && <Cancel color="error" />}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box pb={1}>
            <label htmlFor="button-file">
              <input
                id="button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={onUploadFile}
              />
              <Button
                variant="text"
                component="span"
                startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                endIcon={
                  <InputAdornment position="end">
                    {(anexos.length === 0 && !errors.attachments && (
                      <CheckCircle color="primary" />
                    )) ||
                      (anexos.length > 0 && !errors.attachments && (
                        <CheckCircle color="primary" />
                      ))}
                    {anexos.length > 0 && !!errors.attachments && (
                      <Cancel color="error" />
                    )}
                  </InputAdornment>
                }
                sx={{
                  textTransform: 'none',
                  // color: 'white',
                }}
              >
                Anexar Foto
              </Button>
            </label>
            <FormHelperText
              error={Boolean(errors.attachments) && anexos.length > 0}
            >
              Somente arquivos png ou jpg
            </FormHelperText>
          </Box>
          {anexos.length > 0 && (
            <List sx={{ mx: 3, my: 0, py: 0, pl: 2 }}>
              {anexos.map(anexo => (
                <ListItemText
                  key={anexo.replace(/ /g, '')}
                  secondary={anexo}
                  sx={{ py: 0, fontStyle: 'italic' }}
                />
              ))}
            </List>
          )}
          <Box>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  error={Boolean(errors.message)}
                  helperText={errors.message?.message}
                  fullWidth
                  id="standard-multilinFe-flexible"
                  label="Mensagem"
                  multiline
                  maxRows={5}
                  minRows={3}
                  variant="outlined"
                  onChange={onChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {value && !errors.message && (
                          <CheckCircle color="primary" />
                        )}
                        {!!errors.message && <Cancel color="error" />}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/* <p>{errors.message?.message}</p> */}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              endIcon={<SendIcon />}
              sx={{
                textTransform: 'none',
                color: 'white',
              }}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
