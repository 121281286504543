import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import ConfirmDialog from '../components/ConfirmDialog';

type ShowConfirmDialogProps = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

type ConfirmDialogContext = {
  showConfirmDialog: ({
    title,
    message,
    onConfirm,
    onCancel,
  }: ShowConfirmDialogProps) => void;
};

const DEFAULT_STATE: ConfirmDialogContext = {
  showConfirmDialog: () => null,
};

const ConfirmDialogContext = createContext<ConfirmDialogContext>(DEFAULT_STATE);

type ConfirmDialogProviderProps = {
  children: React.ReactNode;
};

export function ConfirmDialogProvider({
  children,
}: ConfirmDialogProviderProps) {
  const [show, setShow] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  const confirmFn = useRef<() => void>();
  const cancelFn = useRef<() => void>();

  const handleCancel = () => {
    if (cancelFn.current != null) {
      cancelFn.current();
    }

    setShow(false);
  };

  const handleConfirm = () => {
    if (confirmFn.current != null) {
      confirmFn.current();
    }

    setShow(false);
  };

  const showConfirmDialog = ({
    title,
    message,
    onConfirm,
    onCancel,
  }: ShowConfirmDialogProps) => {
    setDialogTitle(title);
    setDialogMessage(message);
    confirmFn.current = onConfirm;
    cancelFn.current = onCancel;

    setShow(true);
  };

  const value = useMemo(() => ({ showConfirmDialog }), []);

  return (
    <ConfirmDialogContext.Provider value={value}>
      <ConfirmDialog
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        open={show}
        title={dialogTitle}
      >
        {dialogMessage}
      </ConfirmDialog>

      {children}
    </ConfirmDialogContext.Provider>
  );
}

export function useConfirmDialog() {
  return useContext(ConfirmDialogContext);
}
