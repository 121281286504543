import { SyntheticEvent, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderPages from '../../../components/HeaderPages';

import AccordionServicos from '../AccordionServicos';
import folgasList from './Folgas.constants';

export default function Folgas(): JSX.Element {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/servicos"
          color="inherit"
          underline="hover"
        >
          Serviços
        </Link>

        <Typography color="secondary" fontWeight="bold">
          Folgas
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Folgas" />

      <Box component="section">
        <Typography component="p" mb={3}>
          As folgas deverão ser solicitadas com no mínimo 3 (três) dias de
          antecedência da data de fruição.
        </Typography>

        {folgasList.map(folga => (
          <AccordionServicos
            key={folga.id}
            data={folga}
            expanded={expanded}
            handleChange={handleChange}
          />
        ))}
      </Box>
    </Box>
  );
}
