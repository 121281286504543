import { Box, BoxProps, Divider, Drawer } from '@mui/material';

import Aside from '../Aside';
import Logo from '../Logo';

import { useAsideDrawer } from '../../context/AsideDrawerProvider';

export default function AsideDrawer(props: BoxProps): JSX.Element {
  const { openAside, toggleAside } = useAsideDrawer();

  return (
    <Box component="aside" {...props}>
      <Drawer
        variant="temporary"
        anchor="right"
        open={openAside}
        onClose={toggleAside}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
      >
        <Logo
          filepng="/logo-invertida.png"
          filesvg="/logo-invertida.svg"
          maxWidth="20rem"
          height={82}
        />

        <Divider />

        <Box sx={{ overflow: 'scroll' }}>
          <Aside />
        </Box>
      </Drawer>
    </Box>
  );
}
