import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey } from '@mui/material/colors';

type HeaderProps = {
  title: string;
  titleButton?: string;
  to?: string;
  onClick?: () => void;
};

export default function Header({
  title,
  titleButton,
  to,
  onClick,
}: HeaderProps): JSX.Element {
  return (
    <Box
      component="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <Typography color={grey[600]} fontWeight="bold">
        {title}
      </Typography>

      {to && (
        <Button
          size="small"
          to={to}
          component={RouterLink}
          onClick={onClick}
          sx={{
            textTransform: 'none',
            color: grey[600],
          }}
          endIcon={<FontAwesomeIcon icon="angle-right" />}
          title={titleButton}
        >
          {titleButton}
        </Button>
      )}
    </Box>
  );
}
