import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import AuthenticatedLayout from '../layout/AuthenticatedLayout';

import { useAuth } from '../context/AuthProvider';

import AuthEgide from '../auth/AuthEgide';

import UsuarioNotFound from '../auth/UsuarioNotFound';
import PageNotFound from '../pages/404';
import Administracao from '../pages/Administracao';
import Servidores from '../pages/Administracao/Servidores';
import ServidoresEdit from '../pages/Administracao/Servidores/Edit';
import ServidoresShow from '../pages/Administracao/Servidores/Show';
import Usuarios from '../pages/Administracao/Usuarios';
import UsuariosEdit from '../pages/Administracao/Usuarios/Edit';
import UsuariosShow from '../pages/Administracao/Usuarios/Show';
import Aniversariantes from '../pages/Aniversariantes';
import Contatos from '../pages/Contatos';
import CrachasAnalisar from '../pages/Crachas/Analisar';
import CrachasAnalise from '../pages/Crachas/Analise';
import CrachasAnaliseEdit from '../pages/Crachas/AnaliseEdit';
import CrachasComprovante from '../pages/Crachas/Comprovante';
import CrachasEdit from '../pages/Crachas/Edit';
import CrachasImprimir from '../pages/Crachas/Imprimir';
import CrachasMeusCrachas from '../pages/Crachas/MeusCrachas';
import CrachasNew from '../pages/Crachas/New';
import CrachasQuantitativo from '../pages/Crachas/Quantitativo';
import CrachasRelatorios from '../pages/Crachas/Relatorios';
import CrachasShow from '../pages/Crachas/Show';
import CrachaTest from '../pages/Crachas/components/Test';
import Document from '../pages/Document';
import Documentos from '../pages/Documentos';
import FormComunicacao from '../pages/FormComunicacao';
import GrupoContatos from '../pages/GrupoContatos';
import Home from '../pages/Home';
import Noticia from '../pages/Noticia';
import Noticias from '../pages/Noticias';
import Pagina from '../pages/Pagina';
import Pasta from '../pages/Pasta';
import FormDocumentoUpdate from '../pages/Pasta/FormDocumentoUpdate';
import FormDocumentoUpload from '../pages/Pasta/FormDocumentoUpload';
import FormNewFolder from '../pages/Pasta/FormNewFolder';
import Servicos from '../pages/Servicos';
import Afastamentos from '../pages/Servicos/Afastamentos';
import Auxilios from '../pages/Servicos/Auxilios';
import ComprovantesRendimentos from '../pages/Servicos/ComprovantesRendimentos';
import Concessoes from '../pages/Servicos/Concessoes';
import Contracheques from '../pages/Servicos/Contracheques';
import EnvolucaoFuncional from '../pages/Servicos/EvolucaoFuncional';
import FichasFinanceiras from '../pages/Servicos/FichasFinanceiras';
import Folgas from '../pages/Servicos/Folgas';
import Licencas from '../pages/Servicos/Licencas';
import Sistemas from '../pages/Sistemas';
import UsuarioEditarFoto from '../pages/Usuario/EditarFoto';

function RequireAuth({
  children,
  disableLayout = false,
}: {
  children: JSX.Element;
  disableLayout?: boolean;
}) {
  const { usuario } = useAuth();
  const location = useLocation();

  if (usuario == null) {
    return <Navigate to="/auth/egide" state={{ from: location }} replace />;
  }

  return !disableLayout ? (
    <AuthenticatedLayout>{children}</AuthenticatedLayout>
  ) : (
    children
  );
}

export default function AppRouter(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/egide" element={<AuthEgide />} />

        <Route path="/auth/not-found" element={<UsuarioNotFound />} />

        <Route
          path="/noticia/:id"
          element={
            <RequireAuth>
              <Noticia />
            </RequireAuth>
          }
        />

        <Route
          path="/pagina/:id"
          element={
            <RequireAuth>
              <Pagina />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/contracheque"
          element={
            <RequireAuth>
              <Contracheques />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/ficha-financeira"
          element={
            <RequireAuth>
              <FichasFinanceiras />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/comprovante-rendimento"
          element={
            <RequireAuth>
              <ComprovantesRendimentos />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/afastamento"
          element={
            <RequireAuth>
              <Afastamentos />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/auxilio"
          element={
            <RequireAuth>
              <Auxilios />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/concessao"
          element={
            <RequireAuth>
              <Concessoes />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/licenca"
          element={
            <RequireAuth>
              <Licencas />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/folga"
          element={
            <RequireAuth>
              <Folgas />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos/evolucao-funcional"
          element={
            <RequireAuth>
              <EnvolucaoFuncional />
            </RequireAuth>
          }
        />

        <Route
          path="/servicos"
          element={
            <RequireAuth>
              <Servicos />
            </RequireAuth>
          }
        />

        <Route
          path="/sistemas"
          element={
            <RequireAuth>
              <Sistemas />
            </RequireAuth>
          }
        />

        <Route
          path="/noticias"
          element={
            <RequireAuth>
              <Noticias />
            </RequireAuth>
          }
        />

        <Route
          path="/documents/folder/:id"
          element={
            <RequireAuth>
              <Pasta />
            </RequireAuth>
          }
        />

        <Route
          path="/documents/folder/:id/new"
          element={
            <RequireAuth>
              <FormNewFolder />
            </RequireAuth>
          }
        />

        <Route
          path="/documents/:id/edit"
          element={
            <RequireAuth>
              <FormDocumentoUpdate />
            </RequireAuth>
          }
        />

        <Route
          path="/documents/:id"
          element={
            <RequireAuth disableLayout>
              <Document />
            </RequireAuth>
          }
        />

        <Route
          path="/documents/folder/:id/upload"
          element={
            <RequireAuth>
              <FormDocumentoUpload />
            </RequireAuth>
          }
        />

        <Route
          path="/documentos/:origem/:tipo"
          element={
            <RequireAuth>
              <Documentos />
            </RequireAuth>
          }
        />

        <Route
          path="/contatos"
          element={
            <RequireAuth>
              <GrupoContatos />
            </RequireAuth>
          }
        />

        <Route
          path="/contatos/:id"
          element={
            <RequireAuth>
              <Contatos />
            </RequireAuth>
          }
        />

        <Route
          path="/comunicacao/formulario"
          element={
            <RequireAuth>
              <FormComunicacao />
            </RequireAuth>
          }
        />

        <Route
          path="/aniversariantes"
          element={
            <RequireAuth>
              <Aniversariantes />
            </RequireAuth>
          }
        />

        <Route
          path="/crachas/nova-solicitacao"
          element={
            <RequireAuth>
              <CrachasNew />
            </RequireAuth>
          }
        />

        <Route path="/crachas/test" element={<CrachaTest />} />

        <Route
          path="/crachas/meus-crachas"
          element={
            <RequireAuth>
              <CrachasMeusCrachas />
            </RequireAuth>
          }
        />

        <Route
          path="/crachas/meus-crachas/:id"
          element={
            <RequireAuth>
              <CrachasShow />
            </RequireAuth>
          }
        />

        <Route
          path="/crachas/meus-crachas/:id/edit"
          element={
            <RequireAuth>
              <CrachasEdit />
            </RequireAuth>
          }
        />

        <Route
          path="/crachas/:id/comprovante"
          element={
            <RequireAuth>
              <CrachasComprovante />
            </RequireAuth>
          }
        />

        <Route
          path="/usuarios/editar-foto"
          element={
            <RequireAuth>
              <UsuarioEditarFoto />
            </RequireAuth>
          }
        />

        <Route
          path="/admin"
          element={
            <RequireAuth>
              <Administracao />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/usuarios"
          element={
            <RequireAuth>
              <Usuarios />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/usuarios/:id"
          element={
            <RequireAuth>
              <UsuariosShow />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/usuarios/:id/edit"
          element={
            <RequireAuth>
              <UsuariosEdit />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/servidores"
          element={
            <RequireAuth>
              <Servidores />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/servidores/:id"
          element={
            <RequireAuth>
              <ServidoresShow />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/servidores/:id/edit"
          element={
            <RequireAuth>
              <ServidoresEdit />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/crachas/analisar"
          element={
            <RequireAuth>
              <CrachasAnalisar />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/crachas/relatorios"
          element={
            <RequireAuth>
              <CrachasRelatorios />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/crachas/relatorios/quantitativo"
          element={
            <RequireAuth>
              <CrachasQuantitativo />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/crachas/analisar/:id"
          element={
            <RequireAuth>
              <CrachasAnalise />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/crachas/analisar/:id/edit"
          element={
            <RequireAuth>
              <CrachasAnaliseEdit />
            </RequireAuth>
          }
        />

        <Route
          path="/admin/crachas/:id/imprimir"
          element={
            <RequireAuth disableLayout>
              <CrachasImprimir />
            </RequireAuth>
          }
        />

        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />

        <Route
          path="*"
          element={
            <RequireAuth>
              <PageNotFound />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
