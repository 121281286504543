import { ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbsProps = {
  children: ReactNode;
  showHomeLink?: boolean;
};

export default function Breadcrumbs({
  children,
  showHomeLink = true,
}: BreadcrumbsProps): JSX.Element {
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        mb: 3,
        fontSize: 'body2.fontSize',
        bgcolor: 'grey.200',
        px: { xs: 1, md: 3 },
        py: 1.5,
      }}
    >
      {showHomeLink && (
        <Link component={RouterLink} to="/" color="inherit" underline="hover">
          <FontAwesomeIcon icon="home" /> Início
        </Link>
      )}

      {children}
    </MuiBreadcrumbs>
  );
}
