import { Alert, Box, CircularProgress } from '@mui/material';

type LoadingStateProps = {
  isLoading: boolean;
  isError: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
};

export default function LoadingState({
  isLoading,
  isError,
  title,
  children,
}: LoadingStateProps) {
  return (
    <Box>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <CircularProgress color="inherit" size={24} />
        </Box>
      )}

      {isError && (
        <Box>
          {title}

          <Alert severity="warning">
            Você não está autorizado a acessar esta área
          </Alert>
        </Box>
      )}

      {!isLoading && !isError && (
        <Box>
          {title}
          {children}
        </Box>
      )}
    </Box>
  );
}
