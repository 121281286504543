import { ChangeEvent, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { IconButton, InputBase } from '@mui/material';

import { alpha, styled } from '@mui/material/styles';

import { grey } from '@mui/material/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { useSearchModal } from '../../../context/SearchModalProvider';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '&:focus-within': {
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 0, 1, 2),
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
  },
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: grey[700],
}));

export default function SearchBar(): JSX.Element {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const navigate = useNavigate();

  const { toggleModal } = useSearchModal();

  const [searchText, setSearchText] = useState('');

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const navigateToNoticias = () => {
    if (searchText.trim() !== '') {
      navigate(`/noticias?content=${searchText}`);

      if (isMobile) {
        toggleModal();
      }
    }
  };

  const handleSearchEnterInput = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const { key, code } = event;
    if (key.toLowerCase() === 'enter' || code.toLowerCase() === 'enter') {
      navigateToNoticias();
    }
  };

  return (
    <Search>
      <StyledInputBase
        placeholder="Pesquisar em notícias do Portal..."
        onInput={handleSearchInput}
        onKeyDown={handleSearchEnterInput}
      />
      <SearchIconWrapper>
        <IconButton aria-label="Pesquisar" onClick={() => navigateToNoticias()}>
          <FontAwesomeIcon icon="search" fontSize="1.25rem" />
        </IconButton>
      </SearchIconWrapper>
    </Search>
  );
}
