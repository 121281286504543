import { Box, Typography } from '@mui/material';
import { forwardRef, useRef } from 'react';
import { useServidorQuery } from './hooks/useServidorQuery';

type CrachaPreviewStyles = {
  photoWidth?: number | string;
  nomeFontSize?: number | string;
  nomeCompletoMatriculaFontSize?: number | string;
  nomeCompletoMatriculaLineHeight?: number | string;
  cargoFontSize?: number | string;
};

type CrachaPreviewProps = {
  nome: string;
  cargo: string;
  matricula: string;
  imgSrc?: string;
  styles?: CrachaPreviewStyles;
};

const CrachaPreview = forwardRef<HTMLCanvasElement, CrachaPreviewProps>(
  (props, ref) => {
    const photoWidth = props.styles?.photoWidth || 80;
    const nomeFontSize = props.styles?.nomeFontSize || '0.75rem';

    const nomeCompletoMatriculaFontSize =
      props.styles?.nomeCompletoMatriculaFontSize || '0.65rem';
    const cargoFontSize = props.styles?.cargoFontSize || '0.65rem';

    const nomeCompletoMatriculaLineHeight =
      props.styles?.nomeCompletoMatriculaLineHeight || 1.2;

    const nomeRef = useRef<HTMLParagraphElement>(null);

    const { data: servidor } = useServidorQuery(props.matricula);

    return (
      <Box
        sx={{
          px: 1,
          pt: 4.5,
          pb: 1,
          backgroundImage: 'url("/crachas/background-art-2.png")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundPositionY: '-3px',
          height: '85mm',
          width: '54mm',
          outline: '1px solid #aeaeae',
          '@media print': {
            width: '100%',
            height: 'auto',
            outline: 0,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          mb={1.25}
        >
          <Box
            component="img"
            alt="Logo do crachá"
            src="/crachas/background-logo-2.png"
            sx={{
              width: '61%',
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          mb={1}
        >
          {props.imgSrc != null ? (
            <Box
              component="img"
              src={props.imgSrc}
              alt="Foto de perfil"
              sx={{
                backgroundColor: 'white',
                objectFit: 'cover',
                width: photoWidth,
                aspectRatio: '3 / 4',
                border: '3px solid white',
                borderRadius: 0.25,
              }}
            />
          ) : (
            <Box>
              <canvas
                ref={ref}
                style={{
                  border: '1px solid #dededf',
                  objectFit: 'cover',
                  width: 300 / 1.85,
                  height: 400 / 1.85,
                  maxWidth: '100%',
                }}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          mb={0.3}
        >
          <Typography
            component="p"
            textAlign="center"
            fontWeight={900}
            color="#097840"
            fontSize={nomeFontSize}
            textTransform="uppercase"
            lineHeight={1.075}
            style={{
              textRendering: 'geometricPrecision',
            }}
          >
            {props.nome}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 1.25,
          }}
        >
          <Box
            sx={{
              color: '#097840',
              fontSize: cargoFontSize,
              maxHeight: '35px',
              minHeight: '25px',
              border: '2px solid #097840',
              borderRadius: '20px',
              py: '4px',
              px: '12px',
              fontWeight: 'bold',
              lineHeight: 1.15,
              textAlign: 'center',
              textTransform: 'uppercase',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {props.cargo}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            color: '#097840',
          }}
        >
          <Typography
            fontSize={nomeCompletoMatriculaFontSize}
            lineHeight={nomeCompletoMatriculaLineHeight}
            textTransform="uppercase"
            ref={nomeRef}
            sx={{
              fontWeight: 400,
              textRendering: 'geometricPrecision',
              textAlign: 'center',
            }}
          >
            {servidor?.nome_social || servidor?.nome}
          </Typography>

          <Typography mb={0.4} color="#097840" fontSize={7} fontWeight={900}>
            NOME
          </Typography>

          <Typography
            color="#097840"
            fontSize={nomeCompletoMatriculaFontSize}
            lineHeight={nomeCompletoMatriculaLineHeight}
            textTransform="uppercase"
            sx={{ fontWeight: 400, textRendering: 'geometricPrecision' }}
          >
            {props.matricula}
          </Typography>

          <Typography color="#097840" fontSize={7} fontWeight={900}>
            MATRÍCULA
          </Typography>
        </Box>
      </Box>
    );
  },
);

CrachaPreview.displayName = 'CrachaPreview';

export default CrachaPreview;
