import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from '@mui/material';

import { SyntheticEvent, useState } from 'react';
import Accordion from '../../components/Accordion';
import FileDownloadButton from '../../components/FileDownloadButton';
import PostifyHtmlContent from '../../components/PostifyHtmlContent';

import { Data } from './types';

type AccordionServicosProps = {
  data: Data;
  expanded: number | false;
  handleChange: (
    panel: number,
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
};

export default function AccordionServicos({
  data,
  expanded,
  handleChange,
}: AccordionServicosProps): JSX.Element {
  const [errorDownloadingFile, setErrorDownloadingFile] = useState(false);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorDownloadingFile(false);
  };

  return (
    <>
      <Snackbar
        open={errorDownloadingFile}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" severity="error" onClose={handleSnackbarClose}>
          Erro ao baixar arquivo
        </Alert>
      </Snackbar>

      <Accordion
        expanded={expanded === data.id}
        onChange={handleChange(data.id)}
        title={data.title}
      >
        <Box color="grey.700">
          {data.detail && (
            <>
              <Typography component="h3" fontWeight={700}>
                Definição:
              </Typography>

              <PostifyHtmlContent content={data.detail || ''} />
            </>
          )}

          {data.general && (
            <>
              <Typography component="h3" fontWeight={700}>
                Informações gerais:
              </Typography>

              <PostifyHtmlContent content={data.general || ''} />
            </>
          )}

          {data.docs_download && (
            <>
              <Typography component="h3" fontWeight={700}>
                Documentação necessária:
              </Typography>

              <List>
                {data.docs_download.map(doc => (
                  <ListItem
                    key={doc.id}
                    sx={{ bgcolor: doc.id % 2 === 0 ? '' : 'grey.200' }}
                  >
                    <ListItemText primary={doc.title} />

                    {doc.idFile && (
                      <ListItemIcon sx={{ justifyContent: 'end' }}>
                        <FileDownloadButton fileId={doc.idFile} />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {data.docs_info && (
            <>
              <Typography component="h3" fontWeight={700}>
                Documentos informativos:
              </Typography>

              <List>
                {data.docs_info.map(doc => (
                  <ListItem
                    key={doc.id}
                    sx={{ bgcolor: doc.id % 2 === 0 ? '' : 'grey.200' }}
                  >
                    <ListItemText primary={doc.title} />

                    {doc.idFile && (
                      <ListItemIcon sx={{ justifyContent: 'end' }}>
                        <FileDownloadButton fileId={doc.idFile} />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </Accordion>
    </>
  );
}
