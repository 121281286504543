import { DependencyList, useEffect } from 'react';

export default function useDebounceEffect(
  fn: (args: unknown) => void,
  waitTime: number,
  deps?: DependencyList,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn(deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, [deps, fn, waitTime]);
}
