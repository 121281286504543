import { Link as RouterLink, useParams } from 'react-router-dom';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ImageList,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { grey } from '@mui/material/colors';

import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from '../../components/Breadcrumbs';
import ImageGallery from '../../components/ImageGallery';
import PostifyHtmlContent from '../../components/PostifyHtmlContent';

import { useConfirmDialog } from '../../context/ConfirmDialogProvider';

import {
  adicionarComentario,
  deletarComentario,
  getNoticia,
} from '../../services/postify';

import useScrollToTop from '../../hooks/useScrollToTop';

import { useAuth } from '../../context/AuthProvider';
import DocumentosNoticia from './DocumentosNoticia';
import HeaderNoticia from './HeaderNoticia';

type ParamsProps = {
  id: string;
};

type Imagem = {
  id: number;
  title?: string;
  text?: string;
  src: string;
  file: {
    url: string;
    v900x600: {
      url: string | null;
    };
  };
  author: string;
  short_description: string;
};

type Noticia = {
  id: number | string;
  author: string;
  title: string;
  text: string;
  created_at: string;
  updated_at: string;
  imagem_capa: {
    id: number;
    file: {
      url: string;
      title: string;
    };
    author: string;
    short_description: string;
  };
  comentarios: [
    {
      id: number;
      author: string;
      text: string;
      cpf: string;
      updated_at: string;
      avatar: string | null;
    },
  ];
  galeria_imagens: Imagem[];
  documentos: [
    {
      id: string;
      title: string;
      short_description: string;
      link: string;
    },
  ];
};

type ComentarioForm = {
  postId?: number | string;
  comentario: string;
  autor: string;
  cpf: string;
};

function stringAvatar(name: string) {
  return {
    children: name
      .split(' ')
      .filter(item => item.length > 3)
      .map(item => item[0])
      .slice(0, 1)
      .join(''),
  };
}

export default function Noticia(): JSX.Element {
  const { id } = useParams<ParamsProps>();
  const { usuario, servidor } = useAuth();
  const isSmallDevice = useMediaQuery('(max-width: 600px)');
  useScrollToTop();

  const confirmDialog = useConfirmDialog();

  const queryClient = useQueryClient();

  const { control, handleSubmit, reset, setValue } = useForm<ComentarioForm>({
    defaultValues: {
      autor: servidor?.nome,
      comentario: '',
      cpf: servidor?.cpf,
    },
  });

  const handleAdicionarComentario: SubmitHandler<
    ComentarioForm
  > = async data => {
    await adicionarComentario(data);
    reset();
    queryClient.invalidateQueries(['noticia', id]);
  };

  const handleDeletarComentario = async (commentId: number, cpf: string) => {
    confirmDialog.showConfirmDialog({
      title: 'Confirmação',
      message: 'Confirmar exclusão do comentário?',
      onConfirm: async () => {
        await deletarComentario(commentId, cpf);
        queryClient.invalidateQueries(['noticia', id]);
      },
    });
  };

  const { data: noticia, isFetching } = useQuery(
    ['noticia', id],
    async () => {
      if (typeof id === 'string' && id.trim() !== '') {
        const { data } = await getNoticia<Noticia>(id);
        const dataFormatted = {
          ...data,
          created_at: data.created_at.replace(/-/g, '/'),
        };

        return dataFormatted;
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id.trim() !== '',
      onSuccess: data => {
        setValue('postId', data?.id);
      },
    },
  );

  return (
    <Box component="section">
      <Breadcrumbs>
        <Typography color="secondary" fontWeight="bold">
          Notícia
        </Typography>
      </Breadcrumbs>

      {isFetching ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      ) : (
        <Box component="section" px={{ xs: 1, md: 3 }}>
          {/* TODO: Remover a regra 'overflow' quando 'flow-root' for bastante suportado  */}
          <Box sx={{ display: 'flow-root', overflow: 'auto' }}>
            {noticia?.title && (
              <HeaderNoticia
                title={noticia.title}
                createdAt={noticia.created_at}
                author={noticia.author}
              />
            )}

            <Box
              component="section"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                float: {
                  md: 'right',
                },
                padding: {
                  md: '0 0 1rem 1rem',
                },
              }}
            >
              {noticia != null && (
                <ImageGallery
                  images={[
                    {
                      id: noticia.imagem_capa.id,
                      author: noticia.imagem_capa.author,
                      short_description: noticia.imagem_capa.short_description,
                      url: noticia.imagem_capa.file.url,
                      imagem_capa: true,
                    },
                  ]}
                />
              )}
            </Box>

            <PostifyHtmlContent content={noticia?.text || ''} />
          </Box>

          <Box display="flex" alignItems="end" justifyContent="end" mb={3}>
            <Button
              size="small"
              to="/noticias"
              component={RouterLink}
              sx={{
                textTransform: 'none',
                color: grey[600],
              }}
              endIcon={<FontAwesomeIcon icon="angle-right" />}
              title="Mais Notícias"
            >
              Mais Notícias
            </Button>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {noticia?.galeria_imagens && noticia.galeria_imagens.length > 0 && (
            <ImageList
              sx={{ width: '100%', py: 1 }}
              variant="quilted"
              cols={isSmallDevice ? 1 : 3}
              gap={8}
            >
              <ImageGallery
                images={noticia.galeria_imagens.map(imagem => ({
                  id: imagem.id,
                  author: imagem.author,
                  short_description: imagem.short_description,
                  url: imagem.file.v900x600.url || imagem.file.url,
                  imagem_capa: false,
                }))}
              />
            </ImageList>
          )}

          {noticia?.documentos && noticia.documentos.length > 0 && (
            <DocumentosNoticia documentos={noticia.documentos} />
          )}

          <Box component="section">
            <Typography
              color={grey[600]}
              fontWeight="bold"
              paddingLeft="10px"
              paddingTop="10px"
            >
              {noticia?.comentarios.length} comentário(s)
            </Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper', mb: 4 }}>
              {noticia?.comentarios.map(comentario => (
                <Box key={comentario.id}>
                  <ListItem
                    sx={{ width: '95%' }}
                    alignItems="flex-start"
                    secondaryAction={
                      usuario?.role === 'admin' ||
                      usuario?.cpf === comentario?.cpf ? (
                        <Box>
                          <IconButton
                            // color="error"
                            onClick={() =>
                              handleDeletarComentario(
                                comentario?.id,
                                usuario?.cpf,
                              )
                            }
                            edge="end"
                            aria-label="delete"
                            sx={{ mr: 0 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ) : null
                    }
                  >
                    <ListItemAvatar>
                      {comentario.avatar ? (
                        <Avatar src={comentario.avatar} />
                      ) : (
                        <Avatar {...stringAvatar(comentario.author)} />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        textTransform: 'capitalize',
                      }}
                      primary={comentario.author.toLowerCase()}
                      secondary={
                        <Typography
                          sx={{ display: 'inline', textTransform: 'none' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {comentario.text}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </Box>
              ))}
            </List>

            <form onSubmit={handleSubmit(handleAdicionarComentario)}>
              <Paper
                elevation={3}
                sx={{
                  P: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  mb: 5,
                  ml: 0.5,
                }}
              >
                <Controller
                  control={control}
                  name="comentario"
                  render={({ field }) => (
                    <InputBase
                      {...field}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Adicionar comentário"
                      aria-label="Adicionar comentário"
                    />
                  )}
                />

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                <IconButton
                  color="primary"
                  sx={{ p: '10px' }}
                  aria-label="directions"
                  component="button"
                  type="submit"
                >
                  <SendIcon />
                </IconButton>
              </Paper>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
}
