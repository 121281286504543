import { useEffect, useState } from 'react';

import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import {
  Box,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Link,
  List,
  Pagination,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { grey } from '@mui/material/colors';

import {
  getNoticias,
  PostifyAPIPaginatedResponseType,
} from '../../services/api';

import Breadcrumbs from '../../components/Breadcrumbs';
import HeaderPages from '../../components/HeaderPages';
import useScrollToTop from '../../hooks/useScrollToTop';

type Noticia = {
  id: number;
  title: string;
  slug: string;
  imagem_capa: {
    file: {
      url: string;
      title: string;
    };
    short_description: string;
  };
  text: string;
  created_at: string;
};

type NoticiasResponse = PostifyAPIPaginatedResponseType & {
  noticias: Noticia[];
};

const MAX_LENGTH = 350;

const Img = styled('img')({
  objectFit: 'inherit',
  width: '300px',
});

export default function Noticias(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [noticias, setNoticias] = useState<Noticia[]>([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [loading, setLoading] = useState(false);

  useScrollToTop();

  useEffect(() => {
    const fetchNoticias = async () => {
      setLoading(true);

      const { data } = await getNoticias<NoticiasResponse>({
        pagina: searchParams.get('page') || '1',
        content: searchParams.get('content') || undefined,
      });

      const noticiasFormatted = data.noticias.map(noticia => ({
        ...noticia,
        created_at: noticia.created_at.replace(/-/g, '/'),
      }));

      setNoticias(noticiasFormatted);
      setNumberOfPages(data.total_pages);
      setLoading(false);
    };

    fetchNoticias();
  }, [searchParams]);

  return (
    <Box>
      <Breadcrumbs>
        <Typography color="secondary" fontWeight="bold">
          Notícias
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Notícias" />

      {loading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      ) : (
        <List>
          {noticias.map(noticia => (
            <Box key={noticia.id}>
              <Paper
                elevation={0}
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'flex',
                    md: 'flex',
                    lg: 'flex',
                  },
                  marginBottom: '15px',
                }}
              >
                <CardMedia>
                  <Img
                    src={noticia?.imagem_capa?.file?.url}
                    srcSet={noticia?.imagem_capa?.file?.url}
                    alt={noticia?.imagem_capa?.short_description}
                    loading="lazy"
                    sx={{
                      objectFit: 'cover',
                      aspectRatio: '15 / 10',
                    }}
                  />
                </CardMedia>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Link
                      component={RouterLink}
                      to={`/noticia/${noticia.slug}`}
                      underline="none"
                    >
                      <Typography
                        color={grey[700]}
                        fontWeight="bold"
                        sx={{
                          '&:hover': {
                            color: theme => theme.palette.primary.main,
                          },
                        }}
                      >
                        {noticia?.title}
                      </Typography>
                    </Link>
                    <Typography
                      variant="body2"
                      color={grey[700]}
                      component="div"
                    >
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html:
                            `${noticia?.text
                              .substring(0, MAX_LENGTH)
                              .replace(/(\r\n|\n|\r|<br>)/gm, '')
                              .trim()}... ` || '',
                        }}
                      />
                    </Typography>

                    <Typography variant="body2" color={grey[500]}>
                      Publicada em {noticia.created_at}
                    </Typography>
                  </CardContent>
                </Box>
              </Paper>
              <Divider
                variant="inset"
                component="li"
                sx={{ ml: 0, mb: 2, mt: 0 }}
              />
            </Box>
          ))}
        </List>
      )}

      {numberOfPages > 0 && (
        <Box justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Pagination
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              page={parseInt(searchParams.get('page') || '1', 10)}
              count={numberOfPages}
              onChange={(event, value) => {
                const newPage = value.toString();
                const content = searchParams.get('content');

                if (content != null && content.trim() !== '') {
                  setSearchParams({
                    page: newPage,
                    content,
                  });
                } else {
                  setSearchParams({
                    page: newPage,
                  });
                }
              }}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
