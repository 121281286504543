import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import LoadingButton from '../../../components/LoadingButton';

import {
  getDocumento,
  getFolder,
  getFolders,
  updateDocumento,
} from '../../../services/portal';
import { DocumentosResponse } from '../../../types';

type Folder = {
  id: number;
  name: string;
  parent_folder_id: number | null;
};

type Documento = {
  id?: number;
  name: string;
  description: string;
  folder_id: number;
  url: string;
  file?: File;
  filename?: string;
};

export default function FormDocumentoUpdate() {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data: folders } = useQuery(['folders'], async () => {
    const { data } = await getFolders<Folder[]>();
    return data;
  });

  const { data: documento, isFetching } = useQuery(
    ['document', id],
    async () => {
      if (typeof id === 'string' && id !== '') {
        const { data } = await getDocumento<Documento>(id);
        return data;
      }

      return null;
    },
    {
      enabled: typeof id === 'string' && id !== '',
    },
  );

  const { data: folder } = useQuery(
    ['folder', documento?.folder_id],
    async () => {
      if (documento?.folder_id != null) {
        const { data } = await getFolder<DocumentosResponse>(
          documento?.folder_id,
        );
        return data;
      }

      return null;
    },
    {
      enabled: documento?.folder_id != null,
    },
  );

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    setValue,
  } = useForm<Documento>({
    defaultValues: {
      id: typeof id === 'string' ? parseInt(id, 10) : undefined,
      name: documento?.name,
      description: documento?.description,
      folder_id: documento?.folder_id,
      url: documento?.url,
    },
  });

  const [changeDocumentSuccess, setChangeDocumentSuccess] = useState(false);

  const handleSnackbarSuccessClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setChangeDocumentSuccess(false);
    navigate(`/documents/folder/${documento?.folder_id}`);
  };

  const onSubmit: SubmitHandler<Documento> = async data => {
    if (documento?.id != null) {
      const formData = new FormData();
      formData.append('document[name]', data.name);
      formData.append('document[description]', data.description);
      formData.append('document[folder_id]', data.folder_id.toString());

      if (data.file != null) {
        formData.append('document[file]', data.file);
      }

      await updateDocumento(documento.id, formData);
      setChangeDocumentSuccess(true);
    }
  };

  const [filename, setFilename] = useState('');

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      setFilename(file.name);

      setValue('url', file.name);
      setValue('file', file);
    }
  };

  if (isFetching) {
    return <div>Carregando...</div>;
  }

  return (
    <Box>
      <Snackbar
        open={changeDocumentSuccess}
        autoHideDuration={6000}
        onClose={handleSnackbarSuccessClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={handleSnackbarSuccessClose}>
          Documento alterado com sucesso
        </Alert>
      </Snackbar>

      <Box mb={3}>
        <Breadcrumbs showHomeLink={false}>
          {folder?.folder.all_parents?.map(item => (
            <Link
              key={item.id}
              color="inherit"
              underline="hover"
              component={RouterLink}
              to={`/documents/folder/${item.id}`}
            >
              {item.name}
            </Link>
          ))}

          <Typography color="secondary" fontWeight="bold">
            {folder?.folder.name}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Typography component="h3" variant="h5" mb={1}>
        Alterar documento
      </Typography>

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} alignItems="start">
            <Controller
              name="name"
              control={control}
              defaultValue={documento?.name}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Nome"
                    id="documento-nome"
                    onChange={onChange}
                    value={value}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="folder_id"
              control={control}
              defaultValue={documento?.folder_id}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="documento-diretorio">Pasta</InputLabel>

                  <Select
                    labelId="documento-diretorio"
                    label="Pasta"
                    value={value}
                    onChange={onChange}
                  >
                    {folders
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="description"
              defaultValue={documento?.description}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Descrição breve"
                    id="documento-descricao"
                    value={value}
                    onChange={onChange}
                  />
                </FormControl>
              )}
            />

            <Box>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <label htmlFor="upload-file">
                  <input
                    type="file"
                    id="upload-file"
                    onChange={onUploadFile}
                    style={{ display: 'none' }}
                  />

                  <Button variant="contained" component="span" sx={{ mb: 1 }}>
                    Carregar arquivo
                  </Button>
                </label>

                <Typography component="span" variant="subtitle2">
                  {filename}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <Typography variant="body2" fontWeight="bold">
                  Arquivo atual:
                </Typography>

                <Typography variant="body2" fontStyle="italic">
                  {documento?.filename}
                </Typography>
              </Box>
            </Box>

            <LoadingButton
              type="submit"
              startIcon={<FontAwesomeIcon icon={faSave} />}
              isLoading={isSubmitting}
              sx={{ textTransform: 'none' }}
            >
              Salvar
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}
