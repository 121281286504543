import { SyntheticEvent, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderPages from '../../../components/HeaderPages';

import AccordionServicos from '../AccordionServicos';

import concessoesList from './Concessoes.constants';

export default function Concessoes(): JSX.Element {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box>
      <Breadcrumbs>
        <Link
          component={RouterLink}
          to="/servicos"
          color="inherit"
          underline="hover"
        >
          Serviços
        </Link>
        <Typography color="secondary" fontWeight="bold">
          Concessões
        </Typography>
      </Breadcrumbs>

      <HeaderPages title="Concessões" />

      <Box component="section">
        <Typography component="p" mb={3}>
          As concessões são concedidas, sem qualquer prejuízo, ausentar-se do
          serviço, aos servidores conforme a Lei nº 1818/2007 e aos Defensores
          Públicos conforme a Lei Complementar nº 55/2009:
        </Typography>

        {concessoesList.map(concessao => (
          <AccordionServicos
            key={concessao.id}
            data={concessao}
            expanded={expanded}
            handleChange={handleChange}
          />
        ))}
      </Box>
    </Box>
  );
}
