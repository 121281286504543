import { Box, BoxProps, Drawer } from '@mui/material';

import Logo from '../Logo';
import Sidebar from '../Sidebar';

import { useSidebarDrawer } from '../../context/SidebarDrawerProvider';

export default function SidebarDrawer(props: BoxProps): JSX.Element {
  const { openSidebar, toggleSidebar } = useSidebarDrawer();

  return (
    <Box component="nav" {...props}>
      <Drawer
        variant="temporary"
        anchor="left"
        open={openSidebar}
        onClose={toggleSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '18rem',
          },
        }}
      >
        <Logo
          filepng="/logo.png"
          filesvg="/logo.svg"
          maxWidth="18rem"
          height={72}
        />

        <Box
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            display: 'flex',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <Sidebar />
        </Box>
      </Drawer>
    </Box>
  );
}
