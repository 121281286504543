import { Box, Typography } from '@mui/material';

import { CrachaObservacoesRequerente } from '../../../../types';

type ObservacoesSegundaViaProps = {
  justificativa: CrachaObservacoesRequerente;
};

export default function ObservacoesSegundaVia({
  justificativa,
}: ObservacoesSegundaViaProps) {
  switch (justificativa) {
    case 'alteracao_dados':
      return null;
    case 'desgaste_natural':
      return (
        <Typography>
          <strong>Observação:</strong> Informa-se que conforme Ato n° 118/2018,
          publicado no Diário Oficial n° 5.113 de 13 de maio de 2018, em razão
          de desgaste natural o crachá deverá ser devolvido ao RH para avaliação
          e impressão do novo crachá.
        </Typography>
      );
    case 'mau_estado_conservacao':
      return (
        <Box>
          <Typography sx={{ mb: 2 }}>
            <strong>Observação:</strong> Informa-se que conforme Ato n°
            118/2018, publicado no Diário Oficial n° 5.113 de 13 de maio de
            2018, em razão de mau estado o crachá deverá ser devolvido ao RH e
            será cobrado o valor para emissão da segunda via de{' '}
            <strong>R$ 2,08 (dois reais e oito centavos)</strong> apenas para
            impressão do crachá e de{' '}
            <strong>R$ 8,10 (oito reais e dez centavos)</strong> apenas para
            novo cordão ou o valor de{' '}
            <strong>R$ 10,18 (dez reais e dezoito centavos)</strong> para
            emissão do crachá com o cordão, que deverá ser depositado na Conta
            da Defensoria Pública - Banco do Brasil - Agência: 3615-3, Conta :
            82075-X, CNPJ 07.248.660/0001-35 ou na chave PIX:{' '}
            <strong>contabilidade@defensoria.to.def.br</strong>.
          </Typography>

          <Typography>
            <strong>Orientação do setor financeiro:</strong> Remenda-se aos
            servidores que a transferência e/ou depósito seja efetuado até o dia
            25 de cada mês, em razão dos procedimentos de conciliação bancária.
          </Typography>
        </Box>
      );
    case 'perda_extravio':
      return (
        <Box>
          <Typography sx={{ mb: 2 }}>
            <strong>Observação:</strong> Informa-se que conforme Ato n°
            118/2018, publicado no Diário Oficial n° 5.113 de 13 de maio de
            2018, em razão da perda ou extravio deverá ser anexado a cópia do
            boletim de ocorrência, condição esta essencial para o fornecimento
            de novo crachá de identificação funcional, sem ônus, caso não haja o
            boletim será cobrado o valor para emissão da segunda via de{' '}
            <strong>R$ 2,08 (dois reais e oito centavos)</strong> apenas para
            impressão do crachá, o valor de{' '}
            <strong>R$ 8,10 (oito reais e dez centavos)</strong> apenas para
            novo cordão ou o valor de{' '}
            <strong>R$ 10,18 (dez reais e dezoito centavos)</strong> para
            emissão do crachá com o cordão, que deverá ser depositado na Conta
            da Defensoria Pública - Banco do Brasil - Agência: 3615-3, Conta :
            82075-X, CNPJ 07.248.660/0001-35 ou na chave PIX:{' '}
            <strong>contabilidade@defensoria.to.def.br</strong>.
          </Typography>

          <Typography>
            <strong>Orientação do setor financeiro:</strong> Remenda-se aos
            servidores que a transferência e/ou depósito seja efetuado até o dia
            25 de cada mês, em razão dos procedimentos de conciliação bancária
          </Typography>
        </Box>
      );
    case 'roubo_furto':
      return (
        <Typography>
          <strong>Observação:</strong> Informa-se que conforme Ato n° 118/2018,
          publicado no Diário Oficial n° 5.113 de 13 de maio de 2018, em razão
          de roubo ou furto deverá ser anexado a cópia do boletim de ocorrência,
          condição esta essencial para o fornecimento de novo crachá de
          identificação funcional, sem ônus.
        </Typography>
      );

    default:
      return null;
  }
}
