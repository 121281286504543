import { useRef, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  BoxProps,
  ClickAwayListener,
  Collapse,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
} from '@mui/material';

import { faHome, faSliders } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';

import { getMenuPrincipal } from '../../services/api';

import { useAuth } from '../../context/AuthProvider';
import { useSidebarDrawer } from '../../context/SidebarDrawerProvider';
import menuIconsMap from './Sidebar.constants';

type Menu = {
  id: number;
  title: string;
  url?: string;
  icon: IconProp;
  submenus: Menu[];
};

type AppMenuItemProps = {
  title: string;
  icon: JSX.Element;
  children: ({ open }: { open: boolean }) => JSX.Element | JSX.Element[];
};

function AppMenuItem({ title, icon, children }: AppMenuItemProps) {
  const element = useRef<HTMLElement | null>(null);

  const [open, setOpen] = useState(false);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (element.current != null) {
      if (!element.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }
  };

  return (
    <Box ref={element}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            onClick={() => setOpen(prev => !prev)}
            sx={{
              '&:hover': {
                backgroundColor: '#17a588',
              },
            }}
          >
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                minWidth: 40,
                color: 'white',
              }}
            >
              {icon}
            </ListItemIcon>

            <ListItemText primary={title} />

            {open ? (
              <FontAwesomeIcon icon="angle-down" />
            ) : (
              <FontAwesomeIcon icon="angle-right" />
            )}
          </ListItemButton>
        </ListItem>
      </ClickAwayListener>

      {children({ open })}
    </Box>
  );
}

export default function Sidebar(props: BoxProps) {
  const { usuario } = useAuth();

  const { openSidebar, toggleSidebar } = useSidebarDrawer();

  const handleSidebarDrawerClose = () => {
    if (openSidebar) {
      toggleSidebar();
    }
  };

  const { data: menu = [], isLoading } = useQuery(
    ['menu-principal'],
    async () => {
      const { data } = await getMenuPrincipal<Menu[]>();
      return data;
    },
  );

  const menuTransformed = menu.map(item => {
    return {
      ...item,
      icon: menuIconsMap[item.id],
      submenus: item.submenus?.map(sub => ({
        ...sub,
        icon: menuIconsMap[sub.id],
        submenus: sub.submenus?.map(sub1 => ({
          ...sub1,
          icon: menuIconsMap[sub1.id],
        })),
      })),
    };
  });

  return (
    <Box
      component="nav"
      {...props}
      flexShrink={0}
      boxShadow={1}
      width="100%"
      maxWidth="18rem"
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          color: 'white',
          backgroundColor: theme => theme.palette.primary.main,
        }}
      >
        {!isLoading ? (
          <List>
            <ListItemButton
              key="inicio"
              component={RouterLink}
              to="/"
              onClick={handleSidebarDrawerClose}
              sx={{
                '&:hover': {
                  backgroundColor: '#17a588',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  minWidth: 40,
                  color: 'white',
                }}
              >
                <FontAwesomeIcon icon={faHome} />
              </ListItemIcon>
              <ListItemText primary="Início" />
            </ListItemButton>

            {menuTransformed.map(item =>
              Array.isArray(item.submenus) && item.submenus.length > 0 ? (
                <AppMenuItem key={item.id} title={item.title} icon={item.icon}>
                  {({ open }) => (
                    <Collapse in={open}>
                      {item.submenus?.map(subitem => (
                        <MenuList key={subitem.id} disablePadding>
                          {Array.isArray(subitem.submenus) &&
                          subitem.submenus.length > 0 ? (
                            <Box sx={{ pl: 4 }}>
                              <AppMenuItem
                                key={subitem.id}
                                title={subitem.title}
                                icon={subitem.icon}
                              >
                                {({ open: subMenuOpen }) => (
                                  <Collapse in={subMenuOpen}>
                                    {subitem.submenus?.map(submenu => (
                                      <MenuList key={submenu.id} disablePadding>
                                        <ListItemButton
                                          component={RouterLink}
                                          to={submenu.url || '/'}
                                          onClick={handleSidebarDrawerClose}
                                          disableGutters
                                          sx={{
                                            '&:hover': {
                                              backgroundColor: '#17a588',
                                            },
                                            pl: 6,
                                          }}
                                        >
                                          <ListItemIcon
                                            sx={{
                                              justifyContent: 'center',
                                              minWidth: 40,
                                              color: 'white',
                                            }}
                                          >
                                            {submenu.icon}
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={submenu.title}
                                          />
                                        </ListItemButton>
                                      </MenuList>
                                    ))}
                                  </Collapse>
                                )}
                              </AppMenuItem>
                            </Box>
                          ) : (
                            <Box>
                              {subitem.url?.match(
                                /(defensoria\.to\.def\.br\/|drive\.google\.com\/)/,
                              ) != null ? (
                                <ListItemButton
                                  component={Link}
                                  href={subitem.url}
                                  onClick={handleSidebarDrawerClose}
                                  disableGutters
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: '#17a588',
                                    },
                                    pl: 6,
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      justifyContent: 'center',
                                      minWidth: 40,
                                      color: 'white',
                                    }}
                                  >
                                    {subitem.icon}
                                  </ListItemIcon>
                                  <ListItemText primary={subitem.title} />
                                </ListItemButton>
                              ) : (
                                <ListItemButton
                                  component={RouterLink}
                                  to={subitem.url || '/'}
                                  onClick={handleSidebarDrawerClose}
                                  disableGutters
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: '#17a588',
                                    },
                                    pl: 6,
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      justifyContent: 'center',
                                      minWidth: 40,
                                      color: 'white',
                                    }}
                                  >
                                    {subitem.icon}
                                  </ListItemIcon>
                                  <ListItemText primary={subitem.title} />
                                </ListItemButton>
                              )}
                            </Box>
                          )}
                        </MenuList>
                      ))}
                    </Collapse>
                  )}
                </AppMenuItem>
              ) : (
                <ListItem key={item.id} disablePadding>
                  {item.url?.match(
                    /(defensoria\.to\.def\.br\/|trello\.com\/)/,
                  ) != null ? (
                    <ListItemButton
                      component={Link}
                      href={item.url}
                      target="_blank"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#17a588',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          justifyContent: 'center',
                          minWidth: 40,
                          color: 'white',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      component={RouterLink}
                      to={item.url || '/'}
                      onClick={handleSidebarDrawerClose}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#17a588',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          justifyContent: 'center',
                          minWidth: 40,
                          color: 'white',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  )}
                </ListItem>
              ),
            )}

            {['admin', 'rh'].includes(usuario?.role || '') && (
              <ListItemButton
                key="admin"
                component={RouterLink}
                to="/admin"
                sx={{
                  '&:hover': {
                    backgroundColor: '#17a588',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    justifyContent: 'center',
                    minWidth: 40,
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon icon={faSliders} />
                </ListItemIcon>
                <ListItemText primary="Administração" />
              </ListItemButton>
            )}
          </List>
        ) : (
          <Box />
        )}
      </Paper>
    </Box>
  );
}
