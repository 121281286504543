import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import LoadingButton from '../../../../components/LoadingButton';
import { Cracha } from '../../../../types';
import { solicitacoesMap, statusMap } from '../../Crachas.constants';

import PhotoPreview from '../PhotoPreview';

type FormInputs = {
  nome: string;
  cargo: string;
  foto: FileList | undefined;
  fotoBlob: Blob;
  cordao: boolean;
  justificativa: string;
  observacoes: string;
  status: string;
  solicitacao: string;
};

type FormularioEdicaoProps = {
  cracha: Cracha;
  onSubmit: SubmitHandler<FormInputs>;
  isAdmin?: boolean;
};

export default function FormularioEdicao({
  cracha,
  onSubmit,
  isAdmin = false,
}: FormularioEdicaoProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm<FormInputs>();

  const watchFoto = watch('foto');

  const cropState = useState<Crop>();
  const [, setCrop] = cropState;

  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (watchFoto == null) {
      return;
    }

    if (watchFoto.length > 0) {
      setCrop(undefined);

      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
      });

      reader.readAsDataURL(watchFoto[0]);
    }
  }, [setCrop, watchFoto]);

  const primeiraVia = cracha.solicitacao === 'primeira_via';

  const showJustificativaControl = cracha != null && !primeiraVia;

  useEffect(() => {
    if (cracha != null) {
      setImgSrc(cracha.url || '');
    }
  }, [cracha]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField
          label="Nome que será exibido no crachá"
          error={!!errors.nome}
          defaultValue={cracha.nome}
          {...register('nome', {
            required: true,
          })}
        />

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={cracha.cordao}
                {...register('cordao')}
              />
            }
            label="Incluir cordão do crachá?"
          />
        </Box>

        {showJustificativaControl && (
          <FormControl fullWidth>
            <InputLabel id="select-justificativa-label">
              Justificativa
            </InputLabel>

            <Select
              labelId="select-justificativa-label"
              id="select-justificativa"
              defaultValue={cracha.observacao_requerente}
              label="Justificativa"
              {...register('justificativa')}
            >
              <MenuItem value="alteracao_dados">Alteração de dados</MenuItem>
              <MenuItem value="mau_estado_conservacao">
                Mau estado de conservação
              </MenuItem>
              <MenuItem value="desgaste_natural">Desgaste natural</MenuItem>
              <MenuItem value="perda_extravio">Perda ou extravio</MenuItem>
              <MenuItem value="roubo_furto">Roubo ou furto</MenuItem>
            </Select>
          </FormControl>
        )}

        {isAdmin && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl fullWidth>
              <TextField
                label="Cargo"
                defaultValue={cracha.cargo}
                {...register('cargo')}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Observações"
                multiline
                {...register('observacoes')}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-status-label">Status</InputLabel>

              <Select
                labelId="select-status-label"
                id="select-status"
                defaultValue={cracha.status}
                label="Status"
                {...register('status')}
              >
                {Object.entries(statusMap).map(([id, nome]) => (
                  <MenuItem key={id} value={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="select-solicitacao-label">Solicitação</InputLabel>

              <Select
                labelId="select-solicitacao-label"
                id="select-solicitacao"
                defaultValue={cracha.solicitacao}
                label="Solicitação"
                {...register('solicitacao')}
              >
                {Object.entries(solicitacoesMap).map(([id, nome]) => (
                  <MenuItem key={id} value={id}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Box>
          <Button
            component="label"
            startIcon={<FontAwesomeIcon icon={faFileUpload} />}
            variant="contained"
            sx={{ mb: 1 }}
          >
            Escolher foto
            <input type="file" hidden {...register('foto')} />
          </Button>

          {!!errors.foto && (
            <Typography color="error.main" fontWeight="bold">
              Por favor, faça o upload da foto do crachá
            </Typography>
          )}

          <Typography variant="subtitle2" sx={{ color: 'grey.600' }}>
            Aceita somente as extensões JPG, JPEG ou PNG
          </Typography>
        </Box>

        <PhotoPreview
          cropState={cropState}
          imgSrc={imgSrc}
          onCompletedCrop={canvasElement => {
            canvasElement.toBlob(blob => {
              if (blob != null) {
                setValue('fotoBlob', blob);
              }
            });
          }}
        />

        <Box>
          <LoadingButton
            type="submit"
            variant="contained"
            isLoading={isSubmitting}
          >
            Enviar
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
}
