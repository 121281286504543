import React from 'react';
import ReactDOM from 'react-dom/client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import * as Sentry from '@sentry/react';

import { BrowserTracing } from '@sentry/tracing';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR } from 'date-fns/locale';

import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import App from './App';

import { AsideDrawerProvider } from './context/AsideDrawerProvider';
import { AuthProvider } from './context/AuthProvider';
import { ConfirmDialogProvider } from './context/ConfirmDialogProvider';
import { SearchModalProvider } from './context/SearchModalProvider';
import { SidebarDrawerProvider } from './context/SidebarDrawerProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';

const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;

if (SENTRY_URL != null && SENTRY_URL.trim() !== '') {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new BrowserTracing()],

    tracesSampleRate: 0.3,

    environment: process.env.REACT_APP_ENV,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <CssBaseline />

    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <ConfirmDialogProvider>
        <AuthProvider>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            <AsideDrawerProvider>
              <SidebarDrawerProvider>
                <SearchModalProvider>
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </SearchModalProvider>
              </SidebarDrawerProvider>
            </AsideDrawerProvider>
          </LocalizationProvider>
        </AuthProvider>
      </ConfirmDialogProvider>
    </SnackbarProvider>
  </React.StrictMode>,
);
