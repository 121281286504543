import { createContext, useContext, useMemo, useState } from 'react';

type SearchModalContext = {
  openModal: boolean;
  toggleModal: () => void;
};

const DEFAULT_STATE: SearchModalContext = {
  openModal: false,
  toggleModal: () => null,
};

const SearchModalContext = createContext<SearchModalContext>(DEFAULT_STATE);

type SearchModalProviderProps = {
  children: JSX.Element;
};

export function SearchModalProvider({ children }: SearchModalProviderProps) {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(prevOpenModal => !prevOpenModal);
  };

  const value = useMemo(() => ({ openModal, toggleModal }), [openModal]);

  return (
    <SearchModalContext.Provider value={value}>
      {children}
    </SearchModalContext.Provider>
  );
}

export function useSearchModal() {
  return useContext(SearchModalContext);
}
