import { Servidor } from '../../../context/AuthProvider';

export const SERVIDOR_TIPOS_MAP: Record<Servidor['tipo'], string> = {
  defensor: 'Defensor(a)',
  estagiario: 'Estagiário(a)',
  jovem_trabalhador: 'Jovem Trabalhador(a)',
  servidor: 'Servidor(a)',
  terceirizado: 'Terceirizado(a)',
  voluntario: 'Voluntário(a)',
};

export default {};
