export default function getIconeSistema(name: string) {
  switch (name.toLowerCase()) {
    case 'sisref':
      return 'land-mine-on';
    case 'odin':
      return 'certificate';
    case 'cms':
      return 'keyboard';
    case 'serviços':
      return 'group-arrows-rotate';
    case 'diário':
      return 'file-lines';
    case 'saed':
      return 'users-rectangle';
    case 'hermes':
      return 'people-roof';
    case 'box':
      return 'file-export';
    case 'defcloud':
      return 'cloud';
    case 'luna':
      return 'mobile-screen-button';
    case 'plantão':
      return 'hand-holding-hand';
    case 'sei':
      return 'desktop';
    case 'econsig':
      return 'money-bill';
    case 'athenas':
      return 'user-gear';
    case 'agenda':
      return 'calendar-check';
    case 'irrf':
      return 'brazilian-real-sign';
    case 'postify':
      return 'paste';
    case 'eventos':
      return 'microphone';
    case 'webmail':
      return 'envelope-circle-check';
    case 'solar':
      return 'sun';
    default:
      return 'circle-question';
  }
}
