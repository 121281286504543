import React, { useMemo, useState } from 'react';

import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { groupBy, sortBy } from 'lodash';

import Breadcrumbs from '../../components/Breadcrumbs';
import HeaderPages from '../../components/HeaderPages';

import CardAniversariante from '../../components/CardAniversariante';
import { getAniversariantes } from '../../services/portal';
import { Aniversariante } from '../../types';
import getDay from '../../utils/getDay';
import months from '../../utils/months';
import AniversariantesMes from '../Home/Aniversariantes/AniversariantesMes';

type DisplayModeValues = 'dia' | 'comarca' | 'lotacao_atual';

export default function Aniversariantes(): JSX.Element {
  const today = useMemo(() => {
    return new Date();
  }, []);

  const currentMonth = months[today.getMonth()];

  const { data: aniversariantesMes = [], isLoading } = useQuery(
    ['aniversariantes', currentMonth],
    async () => {
      const { data: aniversariantes = [] } = await getAniversariantes<
        Aniversariante[]
      >({
        periodo: 'mes',
      });

      const aniversariantesTransformed = aniversariantes
        .map(aniversariante => ({
          ...aniversariante,
          nome: aniversariante.nome.toLowerCase(),
          lotacao_atual: aniversariante.lotacao_atual?.toLowerCase() || '',
          comarca: aniversariante.comarca?.toLowerCase() || '',
          dia_aniversario: getDay(aniversariante.data_nascimento),
          letra_avatar: aniversariante.nome.substring(0, 1),
        }))
        .sort((a, b) => {
          return Number(a.dia_aniversario) - Number(b.dia_aniversario);
        });

      return aniversariantesTransformed;
    },
  );

  const [displayMode, setDisplayMode] = useState<DisplayModeValues>('dia');

  const aniversariantesGrouped = groupBy(
    sortBy(aniversariantesMes, displayMode),
    displayMode,
  );

  const label = `Aniversariantes de ${months[today.getMonth()]}`;

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      ) : (
        <>
          <Breadcrumbs>
            <Typography color="secondary" fontWeight="bold">
              Aniversariantes
            </Typography>
          </Breadcrumbs>

          <HeaderPages title={label} />

          <FormControl sx={{ mb: 3 }}>
            <FormLabel id="radio-buttons-display-mode-label">
              Visualizar por
            </FormLabel>

            <RadioGroup
              row
              aria-labelledby="radio-buttons-display-mode-label"
              name="row-radio-buttons-group"
              defaultValue="dia"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setDisplayMode(event.target.value as DisplayModeValues)
              }
            >
              <FormControlLabel value="dia" control={<Radio />} label="Dia" />

              <FormControlLabel
                value="comarca"
                control={<Radio />}
                label="Comarca"
              />

              <FormControlLabel
                value="lotacao_atual"
                control={<Radio />}
                label="Lotação"
              />
            </RadioGroup>
          </FormControl>

          {displayMode === 'dia' ? (
            <AniversariantesMes aniversariantes={aniversariantesMes} />
          ) : (
            Object.entries(aniversariantesGrouped).map(
              ([lotacao, aniversariantes]) => (
                <Box key={lotacao} sx={{ mb: 4 }}>
                  <Typography
                    textTransform="capitalize"
                    component="h3"
                    fontWeight="bold"
                    fontSize="1.15rem"
                    sx={{ mb: 1.5 }}
                    id={lotacao}
                  >
                    {lotacao}
                  </Typography>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                      columnGap: 1,
                      rowGap: 2,
                    }}
                  >
                    {aniversariantes.map(aniversariante => (
                      <CardAniversariante
                        key={aniversariante.id}
                        aniversariante={aniversariante}
                      />
                    ))}
                  </Box>
                </Box>
              ),
            )
          )}
        </>
      )}
    </Box>
  );
}
