export default function pluralTitles(tipo: string) {
  switch (tipo) {
    case 'ato':
      return 'Atos';
    case 'portaria':
      return 'Portarias';
    case 'edital':
      return 'Editais';
    case 'resolucao':
      return 'Resoluções';
    default:
      return 'Desconhecidos';
  }
}
