import axios from 'axios';

export const chronusApi = axios.create({
  baseURL: process.env.REACT_APP_CHRONUS_API_URL,
  headers: { 'content-type': 'application/json' },
  auth: {
    username: `${process.env.REACT_APP_CHRONUS_USER}`,
    password: `${process.env.REACT_APP_CHRONUS_PASS}`,
  },
});

export type RelatorioResponse = {
  data: {
    id: number;
    status: 'done' | 'pending' | 'failed';
    file: string;
  };
  status: number;
};

export type ReportParams = {
  report: {
    app: string;
    name: string;
    user: string;
    resource: string;
    params: object;
  };
};

export const postRelatorio = async ({
  report,
}: ReportParams): Promise<number> => {
  const paramsPost = JSON.stringify({
    app: report.app,
    user: report.user,
    name: report.name,
    resource: report.resource,
    params: JSON.stringify(report.params),
  });

  const response = await chronusApi.post('reports.json', paramsPost);

  if (response.status === 201) {
    const { data } = response as RelatorioResponse;

    return data.id;
  }

  return 0;
};
