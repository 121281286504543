import {
  faCancel,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import PagesHeader from '../../../../components/HeaderPages';
import LoadingState from '../../../../components/LoadingState';
import { Usuario } from '../../../../context/AuthProvider';
import { useConfirmDialog } from '../../../../context/ConfirmDialogProvider';
import {
  deleteUsuario,
  getUsuario,
  updateUsuario,
} from '../../../../services/portal';

type FormInputs = Usuario & {
  active_str: string;
};

export default function UsuariosEdit(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: usuario,
    isLoading,
    isError,
  } = useQuery(['usuarios', id], async () => {
    if (id != null) {
      const { data } = await getUsuario(id);
      return data;
    }

    return null;
  });

  const [tiposUsuario] = useState([
    'comum',
    'admin',
    'rh',
    'gestao_pessoas',
    'editor',
  ]);

  const { showConfirmDialog } = useConfirmDialog();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleDeleteUsuario = () => {
    showConfirmDialog({
      title: 'Excluir usuário',
      message: 'Tem certeza que deseja excluir o usuário?',
      async onConfirm() {
        if (usuario != null) {
          await deleteUsuario(usuario.id);
          queryClient.invalidateQueries(['usuarios', usuario.id]);
          navigate('/admin/usuarios');
        }
      },
    });
  };

  const { register, control, handleSubmit, reset } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    const payload = { ...data, active: data.active_str, active_str: undefined };

    if (id != null) {
      try {
        await updateUsuario(id, payload);
        enqueueSnackbar('Usuário atualizado com sucesso', {
          variant: 'success',
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          enqueueSnackbar(`Erro ao atualizar usuário: ${err.message}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Erro ao atualizar usuário', {
            variant: 'error',
          });
        }
      }
    }
  };

  return (
    <LoadingState
      isLoading={isLoading}
      isError={isError}
      title={
        <Box>
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to="/admin"
              color="inherit"
              underline="hover"
            >
              Administração
            </Link>

            <Link
              component={RouterLink}
              to="/admin/usuarios"
              color="inherit"
              underline="hover"
            >
              Usuários
            </Link>

            <Typography color="secondary" fontWeight="bold">
              Editar Usuário
            </Typography>
          </Breadcrumbs>

          <PagesHeader title="Editar Usuário" />
        </Box>
      }
    >
      <Paper sx={{ p: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography color="primary" fontWeight="bold" fontSize={25} mb={3}>
            Usuário
          </Typography>

          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap={3}
            mb={3}
          >
            <FormControl fullWidth>
              <InputLabel id="select-tipo-usuario-label">Tipo</InputLabel>

              <Select
                labelId="select-tipo-usuario-label"
                id="select-tipo-usuario"
                label="Tipo"
                defaultValue={usuario?.role}
                {...register('role')}
              >
                {tiposUsuario.map(tipo => (
                  <MenuItem key={tipo} value={tipo}>
                    {tipo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="text-field-username"
                label="Username"
                variant="outlined"
                defaultValue={usuario?.username}
                {...register('username')}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="text-field-email"
                label="E-mail"
                variant="outlined"
                defaultValue={usuario?.email}
                {...register('email')}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="text-field-cpf"
                label="CPF"
                variant="outlined"
                defaultValue={usuario?.cpf}
                {...register('cpf')}
              />
            </FormControl>

            <Controller
              control={control}
              name="active_str"
              defaultValue={usuario?.active ? '1' : '0'}
              render={({ field }) => (
                <FormControl>
                  <FormLabel id="radio-ativo-label">Ativo</FormLabel>

                  <RadioGroup
                    row
                    aria-labelledby="radio-ativo-label"
                    name="radio-ativo"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              endIcon={<FontAwesomeIcon icon={faCancel} />}
              sx={{
                textTransform: 'none',
                color: 'white',
              }}
              onClick={() => reset()}
            >
              Cancelar
            </Button>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faTrash} />}
                color="error"
                onClick={handleDeleteUsuario}
              >
                Excluir
              </Button>

              <Button
                type="submit"
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                sx={{
                  textTransform: 'none',
                  color: 'white',
                }}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </LoadingState>
  );
}
